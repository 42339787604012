import React from "react";
import { NavLink, Link } from "react-router-dom";
import { version } from "../../package.json";
import { ApiConsumer } from "../contexts/ApiContext";
// import UnreadFeedback from "../components/UnreadFeedback";
import Icon from "../components/Icon";
import { Trans } from "@lingui/macro";
// import icon from "../assets/icon.png";
// import Notice from "./HeaderNotice";

const Header = ({ toggleMenu }) => (
    <ApiConsumer>
        {({ logout, user, setLanguage, language }) => (
            <nav className="main-nav z-20">
                <div className="mb-8">
                    <div
                        onClick={toggleMenu}
                        className="m-2 cursor-pointer text-teal rounded overflow-hidden fixed pin-t pin-l">
                        <Icon icon="thyme-logo" size={32} />
                    </div>
                    <div className="ml-10 flex items-center">
                        <h2
                            style={{ fontVariant: "small-caps" }}
                            className="text-white uppercase text-base font-hairline text-white truncate">
                            <Trans>Thyme</Trans>
                        </h2>
                        <span className="ml-2 text-grey-darker uppercase inline-block p-1 text-xs border border-grey-darker rounded">
                            beta
                        </span>
                    </div>
                </div>

                <div className="overflow-y-auto custom-scroll mb-8">
                    {user ? (
                        <div className="">
                            <div className="nav-group">
                                <label className="nav-label">
                                    <Trans>Basics</Trans>
                                </label>
                                <NavLink
                                    onClick={toggleMenu}
                                    exact={true}
                                    className="nav-item border-red"
                                    to="/">
                                    <Icon icon="calendar" />
                                    <Trans>Timesheet</Trans>
                                </NavLink>
                                <NavLink
                                    onClick={toggleMenu}
                                    exact={true}
                                    className="nav-item border-indigo"
                                    to="/reports">
                                    <Icon icon="graph" />
                                    <Trans>Reports</Trans>
                                </NavLink>
                                <NavLink
                                    onClick={toggleMenu}
                                    exact={true}
                                    className="nav-item border-blue-light"
                                    to="/expenses">
                                    <Icon icon="cash" />
                                    <Trans>Expenses</Trans>
                                </NavLink>
                                <NavLink
                                    onClick={toggleMenu}
                                    className="nav-item border-orange"
                                    to="/invoices">
                                    <Icon icon="document" />
                                    <Trans>Invoices</Trans>
                                </NavLink>
                                <NavLink
                                    onClick={toggleMenu}
                                    exact={true}
                                    className="nav-item border-purple"
                                    to="/currency-rates">
                                    <Icon icon="graph" />
                                    <Trans>Currency Rates</Trans>
                                </NavLink>
                            </div>

                            <div className="nav-group">
                                <label className="nav-label">
                                    <Trans>Manage</Trans>
                                </label>

                                <NavLink
                                    onClick={toggleMenu}
                                    className="nav-item border-teal"
                                    to="/clients">
                                    <Icon icon="client" />
                                    <Trans>Clients</Trans>
                                </NavLink>
                                <NavLink
                                    onClick={toggleMenu}
                                    className="nav-item border-blue"
                                    to="/projects">
                                    <Icon icon="projects" />
                                    <Trans>Projects</Trans>
                                </NavLink>
                                <NavLink
                                    onClick={toggleMenu}
                                    exact={true}
                                    className="nav-item border-purple"
                                    to="/expense-categories">
                                    <Icon icon="coffee" />
                                    <Trans>Expense Categories</Trans>
                                </NavLink>
                                <NavLink
                                    onClick={toggleMenu}
                                    exact={true}
                                    className="nav-item border-orange"
                                    to="/company-details">
                                    <Icon icon="wrench" />
                                    <Trans>Company Details</Trans>
                                </NavLink>
                            </div>

                            <div className="nav-group">
                                <label className="nav-label">
                                    <Trans>Personal</Trans>
                                </label>

                                <NavLink
                                    onClick={toggleMenu}
                                    exact={true}
                                    className="nav-item border-pink"
                                    to="/profile">
                                    <Icon icon="user" />
                                    <Trans>My Profile</Trans>
                                </NavLink>
                                <NavLink
                                    onClick={toggleMenu}
                                    exact={true}
                                    className="nav-item border-blue-light"
                                    to="/change-password">
                                    <Icon icon="lock-open" />
                                    <Trans>Change Password</Trans>
                                </NavLink>
                            </div>
                        </div>
                    ) : null}
                </div>

                <div className="mt-auto">
                    {!user ? (
                        <div className="mb-4">
                            <NavLink onClick={toggleMenu} className="nav-item" to="/login">
                                <Trans>Login</Trans>
                            </NavLink>
                        </div>
                    ) : (
                        <div className="mb-4">
                            <span
                                onClick={() => {
                                    toggleMenu();
                                    logout();
                                }}
                                className="nav-item">
                                <Trans>Logout</Trans>
                            </span>
                        </div>
                    )}
                    <div className="">
                        <div className="flex mb-4 lang-switcher">
                            <button
                                className={`nav-item ${language === "en" ? "active" : ""}`}
                                onClick={() => setLanguage("en")}>
                                EN
                            </button>
                            <button
                                className={`nav-item ${language === "zh" ? "active" : ""}`}
                                onClick={() => setLanguage("zh")}>
                                中文
                            </button>
                        </div>

                        <div className="text-right">
                            <Link className="text-xxs text-white no-underline" to="/release-notes">
                                <Trans>Version</Trans> {version}
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        )}
    </ApiConsumer>
);

export default Header;
