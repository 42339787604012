import React from "react";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import { Trans } from "@lingui/macro";
import PropTypes from "prop-types";

const Graph = ({ title, type, data, precision }) => {
    if (type === "bar") {
        return (
            <div className="" style={{ flexBasis: 600 }}>
                <Bar
                    data={data}
                    options={{
                        responsive: true,
                        maintainAspectRatio: true,
                        title: {
                            display: title ? true : false,
                            text: title
                        },
                        legend: {
                            display: false
                        },
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }
                            ]
                        }
                    }}
                />
            </div>
        );
    }

    if (type === "stackedBar") {
        return (
            <div style={{ flexBasis: 600 }}>
                <Bar
                    data={data}
                    options={{
                        responsive: true,
                        maintainAspectRatio: true,
                        title: {
                            display: title ? true : false,
                            text: title
                        },
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [
                                {
                                    ticks: {
                                        autoSkip: false
                                    },
                                    stacked: true
                                }
                            ],
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true
                                    },
                                    stacked: true
                                }
                            ]
                        }
                    }}
                />
            </div>
        );
    }

    if (type === "line") {
        return (
            <div className="" style={{ flexBasis: 600 }}>
                <Line
                    data={data}
                    options={{
                        responsive: true,
                        maintainAspectRatio: true,
                        title: {
                            display: title ? true : false,
                            text: title
                        },
                        legend: {
                            display: false
                        },
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: false,
                                        precision: precision ? precision : 0
                                    }
                                }
                            ]
                        }
                    }}
                />
            </div>
        );
    }

    if (type === "doughnut") {
        return (
            <div>
                <Doughnut
                    data={data}
                    height={58}
                    width={58}
                    options={{
                        title: {
                            display: title ? true : false,
                            text: title
                        },
                        legend: {
                            display: false
                        }
                    }}
                />
            </div>
        );
    }

    return (
        <div>
            <Trans>Invalid graph type.</Trans>
        </div>
    );
};

Graph.propType = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired
};

export default Graph;
