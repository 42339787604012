import React, { Component } from "react";
import { toast } from "react-toastify";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../App";
import moment from "moment";
import LoadingWrapper from "../components/LoadingWrapper";
import history from "../components/history";
import Icon from "../components/Icon";
import { createResource, fetchResources, updateResource } from "../api";
import { ApiContext } from "../contexts/ApiContext";

class InvoiceCreate extends Component {
    static contextType = ApiContext;
    state = {
        client_id: this.props.match.params.client_id || "",
        currency_id: "",
        subject: "",
        notes: "",
        billed_at: moment().format("YYYY-MM-DD"),
        items: [{ description: "", qty: 0, unit_price: 0 }],
        clients: [],
        currencies: [],
        isFetchingClients: false,
        isFetchingCurrencies: false,
        isSubmitting: false,
        isLoading: false
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchClients();
        this.fetchCurrencies();
        const { id } = this.props.match.params;
        if (id) {
            this.fetchInvoice();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    fetchInvoice = () => {
        const { id } = this.props.match.params;
        this.safeSetState({ isLoading: true });
        this.context
            .callApi(() => fetchResources(`invoices/${id}`))
            .then(({ data }) => {
                this.safeSetState({
                    client_id: data.client_id,
                    currency_id: data.currency_id,
                    subject: data.subject || "",
                    notes: data.notes || "",
                    billed_at: moment.unix(data.billed_at).format("YYYY-MM-DD"),
                    items: data.items,
                    isLoading: false
                });
            })
            .catch(error => {
                this.safeSetState({
                    isLoading: false,
                    error,
                    message: error.status === 403 ? <Trans>Not allowed.</Trans> : ""
                });
                toast.error(<Trans>Failed to fetch invoice.</Trans>);
            });
    };

    generateInvoice = () => {
        this.setState({
            client_id: 1,
            currency_id: 32,
            subject: "TEST SUBJECT",
            notes: "TEST NOTES",
            items: [{ description: "TEST ITEM", qty: 10, unit_price: 250 }]
        });
    };

    fetchClients = () => {
        this.safeSetState({ isFetchingClients: true });
        this.context
            .callApi(() => fetchResources("clients?simple"))
            .then(({ data }) => {
                this.safeSetState({
                    clients: data,
                    isFetchingClients: false
                });
            })
            .catch(error => {
                this.safeSetState({
                    isFetchingClients: false,
                    error,
                    message: error.status === 403 ? <Trans>Not allowed.</Trans> : ""
                });
                toast.error(<Trans>Failed to fetch clients.</Trans>);
            });
    };

    fetchCurrencies = () => {
        this.safeSetState({ isFetchingCurrencies: true });
        this.context
            .callApi(() => fetchResources("currencies"))
            .then(({ data }) => {
                this.safeSetState({
                    currencies: data,
                    isFetchingCurrencies: false
                });
            })
            .catch(error => {
                this.safeSetState({
                    isFetchingCurrencies: false,
                    error,
                    message: error.status === 403 ? <Trans>Not allowed.</Trans> : ""
                });
                toast.error(<Trans>Failed to fetch currencies.</Trans>);
            });
    };

    handleLineItemUpdate = (indexToBeUpdated, key, data) => {
        this.setState(prevState => ({
            items: prevState.items.map((item, index) => {
                if (index === indexToBeUpdated) {
                    return {
                        ...item,
                        [key]: this.parseValue(key, data)
                    };
                }
                return item;
            })
        }));
    };

    parseValue(key, val) {
        if (key === "description") {
            return val;
        }

        if (val.endsWith(".")) {
            return parseFloat(val) + ".";
        }

        return parseFloat(val) ? parseFloat(val) : 0;
    }

    removeLineItem = index => {
        if (this.state.items.length === 1) {
            return;
        }

        this.setState(prevState => ({
            items: prevState.items.filter((item, i) => i !== index)
        }));
    };

    handleCreate = e => {
        e.preventDefault();
        const { currency_id, client_id, notes, items, subject, billed_at } = this.state;
        const payload = {
            currency_id,
            client_id,
            notes,
            items,
            subject,
            billed_at
        };
        const { id } = this.props.match.params;
        this.safeSetState({ isSubmitting: true });
        this.context
            .callApi(() =>
                id ? updateResource(`invoices/${id}`, payload) : createResource("invoices", payload)
            )
            .then(({ data }) => {
                this.safeSetState({
                    isSubmitting: false
                });
                history.push(`/invoices/${data.id}`);
                toast.success(<Trans>Saved!</Trans>);
            })
            .catch(error => {
                this.safeSetState({
                    isSubmitting: false,
                    error: error.errors,
                    message: error.status === 403 ? <Trans>Not allowed.</Trans> : ""
                });
                const msg = id ? (
                    <Trans>Failed to update invoice.</Trans>
                ) : (
                    <Trans>Failed to create invoice.</Trans>
                );
                toast.error(msg);
            });
    };

    render() {
        const {
            clients,
            subject,
            currencies,
            client_id,
            notes,
            billed_at,
            currency_id,
            isFetchingCurrencies,
            isFetchingClients,
            isSubmitting,
            isLoading,
            items,
            error
        } = this.state;

        const selected_currency = currencies.filter(
            c => parseInt(c.id, 10) === parseInt(currency_id, 10)
        )[0];

        return (
            <div className="main-page-container">
                <div className="p-2 md:p-8">
                    <h1 className="page-header">
                        <Trans>Invoices</Trans>
                    </h1>
                    <LoadingWrapper
                        isLoading={isFetchingClients || isFetchingCurrencies || isLoading}>
                        <div className="form-container">
                            <form method="POST" onSubmit={this.handleCreate}>
                                <div className="form-section-header bg-grey-lighter">
                                    <Trans>Basic Info</Trans>
                                </div>

                                <div className="form-input-group">
                                    <label className="form-label">Client</label>
                                    <div className="w-full">
                                        <select
                                            value={client_id}
                                            disabled={isSubmitting}
                                            className="form-input w-full"
                                            onChange={e =>
                                                this.setState({ client_id: e.target.value })
                                            }>
                                            <option>-- {i18n._(t`Select Client`)} --</option>
                                            {clients.map(client => (
                                                <option key={client.id} value={client.id}>
                                                    {client.name}
                                                </option>
                                            ))}
                                        </select>
                                        {error && error.client_id && (
                                            <span className="text-red text-xs">
                                                {error.client_id}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="form-input-group">
                                    <label className="form-label">Currency</label>
                                    <div className="w-full">
                                        <select
                                            value={currency_id}
                                            disabled={isSubmitting}
                                            className="form-input w-full"
                                            onChange={e =>
                                                this.setState({ currency_id: e.target.value })
                                            }>
                                            <option>-- {i18n._(t`Select Currency`)} --</option>
                                            {currencies.map(currency => (
                                                <option key={currency.id} value={currency.id}>
                                                    {currency.name}
                                                </option>
                                            ))}
                                        </select>
                                        {error && error.currency_id && (
                                            <span className="text-red text-xs">
                                                {error.currency_id}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="form-input-group">
                                    <label className="form-label">
                                        <Trans>Subject</Trans>
                                    </label>
                                    <div className="w-full">
                                        <input
                                            className="form-input w-full"
                                            type="text"
                                            value={subject}
                                            disabled={isSubmitting}
                                            onChange={e =>
                                                this.safeSetState({
                                                    subject: e.target.value
                                                })
                                            }
                                        />
                                        {error && error.subject && (
                                            <span className="text-red text-xs">
                                                {error.subject}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="form-input-group">
                                    <label className="form-label">
                                        <Trans>Billed At</Trans>
                                    </label>
                                    <div className="w-full">
                                        <input
                                            className="form-input w-full"
                                            type="date"
                                            value={billed_at}
                                            disabled={isSubmitting}
                                            onChange={e =>
                                                this.safeSetState({ billed_at: e.target.value })
                                            }
                                        />
                                        {error && error.billed_at && (
                                            <span className="text-red text-xs">
                                                {error.billed_at}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="form-input-group">
                                    <label className="form-label leading-normal">
                                        <Trans>Notes</Trans>
                                    </label>
                                    <div className="w-full">
                                        <textarea
                                            value={notes}
                                            rows={5}
                                            disabled={isSubmitting}
                                            className="form-input w-full"
                                            onChange={e =>
                                                this.safeSetState({
                                                    notes: e.target.value
                                                })
                                            }
                                        />
                                        <span className="text-grey-dark text-xs">
                                            <Trans>Shown on the invoice. (Optional)</Trans>
                                        </span>
                                    </div>
                                </div>

                                <div className="form-section-header bg-grey-lighter">
                                    <Trans>Line Items</Trans>
                                </div>
                                <div className="form-input-group">
                                    <table className="w-full rounded overflow-hidden">
                                        <thead>
                                            <tr className="bg-grey-lighter border-b">
                                                <th className="font-medium whitespace-no-wrap text-xs p-2 text-left">
                                                    <Trans>Description</Trans>
                                                </th>
                                                <th className="font-medium whitespace-no-wrap text-xs p-2 text-left">
                                                    <Trans>Qty/Hrs</Trans>
                                                </th>
                                                <th className="font-medium whitespace-no-wrap text-xs p-2 text-left">
                                                    <Trans>Unit Price</Trans>
                                                </th>
                                                <th className="font-medium whitespace-no-wrap text-xs p-2 text-right">
                                                    <Trans>Amount</Trans>
                                                </th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr className="bg-white">
                                                <td className="p-2 text-sm font-medium text-left">
                                                    <button
                                                        type="button"
                                                        className="btn"
                                                        onClick={() =>
                                                            this.setState(prevState => ({
                                                                items: prevState.items.concat({
                                                                    description: "",
                                                                    qty: 0,
                                                                    unit_price: 0
                                                                })
                                                            }))
                                                        }>
                                                        <Trans>Add Row</Trans>
                                                    </button>
                                                </td>
                                                <td
                                                    colSpan={2}
                                                    className="p-2 text-sm font-medium text-right">
                                                    <Trans>Amount Due</Trans>
                                                </td>
                                                <td className="p-2 whitespace-no-wrap text-sm font-medium text-right">
                                                    {items
                                                        .reduce((acc, item) => {
                                                            return acc + item.qty * item.unit_price;
                                                        }, 0)
                                                        .toFixed(
                                                            selected_currency
                                                                ? selected_currency.precision
                                                                : 0
                                                        )}{" "}
                                                    {currency_id && selected_currency
                                                        ? selected_currency.code
                                                        : null}
                                                </td>
                                                <td>&nbsp;</td>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            {items.map((item, index) => (
                                                <LineItem
                                                    handleLineItemUpdate={this.handleLineItemUpdate}
                                                    key={index}
                                                    errors={error}
                                                    currency={selected_currency}
                                                    index={index}
                                                    item={item}
                                                    isSubmitting={isSubmitting}
                                                    removeLineItem={this.removeLineItem}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="form-actions">
                                    <button
                                        type="button"
                                        disabled={isSubmitting}
                                        className="btn mr-2"
                                        onClick={() => history.push("/invoices")}>
                                        <Trans>Cancel</Trans>
                                    </button>
                                    {process.env.NODE_ENV === "development" ? (
                                        <button
                                            type="button"
                                            onClick={this.generateInvoice}
                                            className="btn mr-2">
                                            Generate
                                        </button>
                                    ) : null}
                                    <button className="btn-primary btn">
                                        {isSubmitting ? (
                                            <Trans>Saving...</Trans>
                                        ) : (
                                            <Trans>Save</Trans>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </LoadingWrapper>
                </div>
            </div>
        );
    }
}

class LineItem extends Component {
    render() {
        const {
            index,
            currency,
            item: { description, qty, unit_price },
            handleLineItemUpdate,
            removeLineItem,
            isSubmitting,
            errors
        } = this.props;

        return (
            <tr className="bg-white border-b">
                <td className="p-2 text-sm text-left">
                    <input
                        disabled={isSubmitting}
                        type="text"
                        value={description}
                        onChange={e => handleLineItemUpdate(index, "description", e.target.value)}
                        className="form-input w-full"
                    />
                    {errors && errors[`items.${index}.description`] && (
                        <span className="text-red text-xs">
                            {errors[`items.${index}.description`]}
                        </span>
                    )}
                </td>
                <td className="p-2 text-sm text-left" style={{ maxWidth: 75 }}>
                    <input
                        disabled={isSubmitting}
                        type="text"
                        value={qty}
                        onChange={e => handleLineItemUpdate(index, "qty", e.target.value)}
                        className="form-input w-full"
                    />
                    {errors && errors[`items.${index}.description`] && (
                        <span className="text-red text-xs">&nbsp;</span>
                    )}
                </td>
                <td className="p-2 text-sm text-left" style={{ maxWidth: 75 }}>
                    <input
                        disabled={isSubmitting}
                        type="text"
                        value={unit_price}
                        onChange={e => handleLineItemUpdate(index, "unit_price", e.target.value)}
                        className="form-input w-full"
                    />
                    {errors && errors[`items.${index}.description`] && (
                        <span className="text-red text-xs">&nbsp;</span>
                    )}
                </td>
                <td className="p-2 text-sm text-right">
                    {(qty * unit_price).toFixed(currency ? currency.precision : 0)}{" "}
                    {currency ? currency.code : null}
                    {errors && errors[`items.${index}.description`] && (
                        <span className="text-red block text-xs">&nbsp;</span>
                    )}
                </td>
                <td className="text-red p-2" style={{ maxWidth: 23 }}>
                    {index > 0 ? (
                        <span
                            className="cursor-pointer hover:text-red-dark"
                            onClick={() => removeLineItem(index)}>
                            <Icon icon="delete" />
                        </span>
                    ) : null}
                    {errors && errors[`items.${index}.description`] && (
                        <span className="text-red block text-xs">&nbsp;</span>
                    )}
                </td>
            </tr>
        );
    }
}

export default InvoiceCreate;
