/* eslint-disable import/first */
import React, { Component } from "react";
import { Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { I18nProvider } from "@lingui/react";
import { setupI18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import history from "./components/history";
import Header from "./components/Header";
import { ApiContext } from "./contexts/ApiContext";
import RoutesContainer from "./components/RoutesContainer";
import TopNav from "./components/TopNav";
import { verifyJwt, me } from "./api";
import catalogZh from "./locales/zh/messages.js";
import catalogEn from "./locales/en/messages.js";
import Spinner from "react-spinkit";
import ScrollToTop from "./components/ScrollToTop";
import bg from "./assets/login-bg.jpeg";
import "./index.css";

export const i18n = setupI18n();
const catalogs = { zh: catalogZh, en: catalogEn };

function storageAvailable(type) {
    try {
        var storage = window[type],
            x = "__storage_test__";
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return (
            e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === "QuotaExceededError" ||
                // Firefox
                e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage.length !== 0
        );
    }
}

class App extends Component {
    static contextType = ApiContext;

    state = {
        booted: false,
        showMenu: false
    };

    componentDidMount() {
        if (!storageAvailable("localStorage")) {
            this.context.setApiState("cookiesDisabled", true);
        } else {
            this.checkLanguage();
            this.checkJwt();
        }
    }

    toggleMenu = () => {
        this.setState(prevState => ({
            showMenu: !prevState.showMenu
        }));
    };

    checkJwt = () => {
        const jwt = localStorage.getItem("THYME_JWT");
        const { setApiState, callApi, setLanguage } = this.context;

        if (jwt) {
            return callApi(() => verifyJwt(jwt))
                .then(res => {
                    localStorage.setItem("THYME_JWT", res.data.token);

                    callApi(me)
                        .then(res => {
                            setApiState("user", res.data);
                            setLanguage(res.data.locale ? res.data.locale : "en");
                            this.setState({ booted: true });
                        })
                        .catch(e => {
                            this.setState({ booted: true });
                            return toast.error(e);
                        });
                })
                .catch(e => {
                    toast.error(<Trans>Invalid token. Please login.</Trans>);
                    history.push("/login");
                    this.setState({ booted: true });
                });
        }
        this.setState({ booted: true });
    };

    checkLanguage = () => {
        const { setLanguage } = this.context;
        const language = localStorage.getItem("THYME_LANG");
        setLanguage(language ? language : "en");
    };

    render() {
        const { booted, showMenu } = this.state;
        const { language, user, setLanguage, cookiesDisabled } = this.context;

        return (
            <I18nProvider i18n={i18n} language={language} catalogs={catalogs}>
                {cookiesDisabled ? (
                    <CookiesDisabled setLanguage={setLanguage} language={language} />
                ) : booted ? (
                    <Router history={history}>
                        <div className={`min-h-screen ${showMenu ? "show-menu" : ""}`}>
                            <Header toggleMenu={this.toggleMenu} />
                            {user && <TopNav />}
                            <div className="main-container">
                                <ScrollToTop>
                                    <RoutesContainer user={user} />
                                </ScrollToTop>
                            </div>
                            <div
                                className="pin fixed bg-center bg-cover z-0"
                                style={{ backgroundImage: `url(${bg})` }}
                            />
                        </div>
                    </Router>
                ) : (
                    <BootingScreen />
                )}
                <ToastContainer />
            </I18nProvider>
        );
    }
}

const CookiesDisabled = ({ setLanguage, language }) => (
    <div className="min-h-screen bg-grey-darkest text-white flex justify-center items-center p-4 flex-col">
        <div style={{ maxWidth: "600px" }}>
            <h1 className="text-orange mb-2 text-4xl">
                <Trans>Cookies Disabled</Trans>
            </h1>
            <p className="leading-normal mb-2">
                <Trans>
                    It looks like you have disabled cookies in your browser. This website uses
                    cookies to store non-personal data and is required for the site to function
                    properly.
                </Trans>
            </p>
            <p className="leading-normal">
                <Trans>
                    Please enable cookies for this domain and refresh. If you are not sure how to
                    enable cookies, please click{" "}
                    <a
                        href="https://www.whatismybrowser.com/guides/how-to-enable-cookies/auto"
                        target="_blank"
                        className="no-external underline text-white hover:text-orange"
                        rel="noopener noreferrer">
                        here
                    </a>
                    .
                </Trans>
            </p>
        </div>
        <div className="fixed pin-b pin-l">
            <div className="flex m-4">
                <button
                    className={`nav-item ${language === "en" ? "active" : ""}`}
                    onClick={() => setLanguage("en")}>
                    EN
                </button>
                <button
                    className={`nav-item ${language === "zh" ? "active" : ""}`}
                    onClick={() => setLanguage("zh")}>
                    中文
                </button>
            </div>
        </div>
    </div>
);

const BootingScreen = () => (
    <div className="min-h-screen bg-grey-darkest text-white text-xl flex flex-col items-center justify-center">
        <Spinner name="ball-beat" color="#039966" />
        <p className="mt-4">
            <Trans>Booting up, please wait...</Trans>
        </p>
    </div>
);

export default App;
