import React, { Component } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Trans, t } from "@lingui/macro";
// import moment from "moment";
import history from "../components/history";
import DeleteButton from "../components/DeleteButton";
import LoadingWrapper from "../components/LoadingWrapper";
import { ApiContext } from "../contexts/ApiContext";
import { createResource, fetchResources, updateResource } from "../api";
import { i18n } from "../App";

class ContactCreate extends Component {
    static contextType = ApiContext;
    state = {
        given_names: "",
        family_name: "",
        email: "",
        job_title: "",
        phone_number: "",
        clients: [],
        client_id: "",
        isLoading: false,
        isSubmitting: false,
        isFetchingClients: false,
        error: null
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchClients();
        const {
            match: {
                params: { id }
            }
        } = this.props;
        if (id) {
            this.fetchClient();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    fetchClients = () => {
        this.safeSetState({ isFetchingClients: true });
        this.context
            .callApi(() => fetchResources("clients?simple"))
            .then(({ data }) => {
                this.safeSetState({
                    clients: data,
                    client_id: this.props.match.params.client_id || this.state.client_id,
                    isFetchingClients: false
                });
            })
            .catch(error => {
                this.safeSetState({
                    isFetchingClients: false,
                    error,
                    message: error.status === 403 ? <Trans>Not allowed.</Trans> : ""
                });
                toast.error(<Trans>Failed to fetch clients.</Trans>);
            });
    };

    fetchClient = () => {
        this.safeSetState({ isLoading: true });
        this.context
            .callApi(() => fetchResources("contacts/" + this.props.match.params.id))
            .then(({ data }) => {
                this.safeSetState({
                    given_names: data.given_names,
                    family_name: data.family_name,
                    email: data.email,
                    job_title: data.job_title || "",
                    phone_number: data.phone_number || "",
                    client_id: data.client_id,
                    isLoading: false
                });
            })
            .catch(error => {
                this.safeSetState({
                    isLoading: false,
                    error,
                    message: error.status === 403 ? <Trans>Not allowed.</Trans> : ""
                });
                toast.error(<Trans>Failed to fetch clients.</Trans>);
            });
    };

    handleDelete = () => {
        history.push("/clients");
        toast.success(<Trans>Delete success!</Trans>);
    };

    handleSubmit = e => {
        e.preventDefault();
        const {
            match: {
                params: { id }
            }
        } = this.props;
        if (!id) {
            return this.handleCreate();
        }
        const { callApi } = this.context;
        const { given_names, family_name, email, job_title, phone_number, client_id } = this.state;
        this.safeSetState({ isSubmitting: true, error: null });
        callApi(() =>
            updateResource(`/contacts/${id}`, {
                given_names,
                family_name,
                email,
                job_title,
                phone_number,
                client_id
            })
        )
            .then(({ data }) => {
                this.safeSetState({
                    given_names: data.given_names || "",
                    family_name: data.family_name || "",
                    email: data.email || "",
                    job_title: data.job_title || "",
                    phone_number: data.phone_number || "",
                    client_id: data.client_id,
                    isLoading: false
                });
                toast.success(<Trans>Saved!</Trans>);
                this.safeSetState({ isSubmitting: false });
            })
            .catch(error => {
                this.safeSetState({ isSubmitting: false, error: error.errors });
                toast.error(<Trans>Failed to update.</Trans>);
            });
    };

    handleCreate = _ => {
        const { callApi } = this.context;
        const { given_names, family_name, email, job_title, phone_number, client_id } = this.state;
        this.safeSetState({ isSubmitting: true, error: null });
        callApi(() =>
            createResource(`/contacts`, {
                given_names,
                family_name,
                email,
                job_title,
                phone_number,
                client_id
            })
        )
            .then(res => {
                this.safeSetState({ isSubmitting: false });
                history.push(`/clients`);
                toast.success(<Trans>Saved!</Trans>);
            })
            .catch(error => {
                console.warn("%cerror", "color: #f6993f; font-weight: bold", error);
                this.safeSetState({ isSubmitting: false, error: error.errors });
                let msg =
                    typeof error.message === "object" ? "Failed to create contact." : error.message;
                toast.error(msg);
            });
    };

    generateClient = () => {
        this.safeSetState({
            given_names: "Manor",
            family_name: "Morris",
            email: "manor@abc.com",
            job_title: "Boss",
            phone_number: 123456789
        });
    };

    render() {
        const {
            given_names,
            family_name,
            email,
            job_title,
            phone_number,
            clients,
            client_id,
            error,
            isSubmitting,
            isLoading,
            isFetchingClients
        } = this.state;
        const {
            match: {
                params: { id }
            }
        } = this.props;
        // const {
        //     user: { locale },
        //     user
        // } = this.context;

        return (
            <div className="main-page-container">
                <div className="p-2 md:p-8">
                    <div>
                        <h1 className="page-header">
                            {id ? <Trans>Edit Contact</Trans> : <Trans>Create Contact</Trans>}
                        </h1>
                        <LoadingWrapper isLoading={isLoading || isFetchingClients}>
                            <div className="form-container">
                                <form method="POST" onSubmit={this.handleSubmit}>
                                    <div className="form-section-header bg-grey-lighter">
                                        <Trans>Basic Info</Trans>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Client</Trans>
                                        </label>
                                        <div className="w-full">
                                            <select
                                                value={client_id}
                                                disabled={isSubmitting}
                                                className="form-input w-full"
                                                onChange={e =>
                                                    this.safeSetState({
                                                        client_id: e.target.value
                                                    })
                                                }>
                                                <option value="">
                                                    -- {i18n._(t`Select Client`)} --
                                                </option>
                                                {clients.map(client => (
                                                    <option key={client.id} value={client.id}>
                                                        {client.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {error && error.client_id && (
                                                <span className="text-red text-xs">
                                                    {error.client_id}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label leading-normal">
                                            <Trans>Given Names</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                value={given_names}
                                                type="text"
                                                disabled={isSubmitting}
                                                className="form-input w-full"
                                                onChange={e =>
                                                    this.safeSetState({
                                                        given_names: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.given_names && (
                                                <span className="text-red text-xs">
                                                    {error.given_names}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Family Name</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="text"
                                                value={family_name}
                                                disabled={isSubmitting}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        family_name: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.family_name && (
                                                <span className="text-red text-xs">
                                                    {error.family_name}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Email</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="email"
                                                value={email}
                                                disabled={isSubmitting}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        email: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.email && (
                                                <span className="text-red text-xs">
                                                    {error.email}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-section-header bg-grey-lighter">
                                        <Trans>Additional Info</Trans>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Job Title</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="text"
                                                value={job_title}
                                                disabled={isSubmitting}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        job_title: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.job_title && (
                                                <span className="text-red text-xs">
                                                    {error.job_title}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Phone Number</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="text"
                                                value={phone_number}
                                                disabled={isSubmitting}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        phone_number: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.phone_number && (
                                                <span className="text-red text-xs">
                                                    {error.phone_number}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-actions">
                                        {id ? (
                                            <DeleteButton
                                                url={`contacts/${id}`}
                                                onDelete={this.handleDelete}>
                                                <button type="button" className="btn btn-solid-red">
                                                    <Trans>Delete</Trans>
                                                </button>
                                            </DeleteButton>
                                        ) : (
                                            <Link className="btn btn-solid-red" to={`/clients`}>
                                                <Trans>Cancel</Trans>
                                            </Link>
                                        )}
                                        {process.env.NODE_ENV === "development" && (
                                            <button
                                                type="button"
                                                className="btn"
                                                onClick={this.generateClient}>
                                                Generate
                                            </button>
                                        )}
                                        <button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="btn btn-solid-blue">
                                            {isSubmitting ? (
                                                <Trans>Saving...</Trans>
                                            ) : (
                                                <Trans>Save</Trans>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </LoadingWrapper>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactCreate;
