import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import { Trans } from "@lingui/macro";
import { ApiContext } from "../contexts/ApiContext";

class SentryErrorBoundary extends Component {
    static contextType = ApiContext;

    state = { error: null };

    componentDidCatch(error, errorInfo) {
        this.setState({ error });

        if (process.env.NODE_ENV !== "development") {
            if (this.context && this.context.user && this.context.user.email) {
                Sentry.configureScope(scope => {
                    scope.setUser({ email: this.context.user.email });
                });
            }

            Sentry.withScope(scope => {
                Object.keys(errorInfo).forEach(key => {
                    scope.setExtra(key, errorInfo[key]);
                });
                Sentry.captureException(error);
            });
        }
    }

    render() {
        if (this.state.error) {
            return (
                <div className="flex items-center justify-center h-screen bg-gradient">
                    <div className="container-really-narrow">
                        <div className="form-container rounded p-4 md:p-8">
                            <h2 className="text-orange text-lg mb-4">
                                <Trans>Oops!</Trans>
                            </h2>

                            <div className="mb-4">
                                <p className="text-sm text-grey-dark leading-normal">
                                    <Trans>
                                        We're really sorry, it looks like the app crashed.
                                    </Trans>
                                </p>

                                {process.env.NODE_ENV !== "development" && (
                                    <p className="text-sm text-grey-dark leading-normal">
                                        <Trans>
                                            If you have a moment, please{" "}
                                            <span
                                                className="underline text-blue hover:text-blue-dark cursor-pointer"
                                                onClick={() => Sentry.showReportDialog()}>
                                                send us a message
                                            </span>{" "}
                                            letting us know what went wrong.
                                        </Trans>
                                    </p>
                                )}
                            </div>

                            <div className="text-center">
                                <button
                                    type="button"
                                    onClick={() => window.location.reload(true)}
                                    className="btn btn-orange btn-sm">
                                    <Trans>Reload</Trans>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.props.children;
        }
    }
}

export default SentryErrorBoundary;
