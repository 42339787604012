import React, { Component } from "react";
import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
// import moment from "moment";
// import history from "../components/history";
import LoadingWrapper from "../components/LoadingWrapper";
// import { i18n } from "../App";
// import DeleteButton from "../components/DeleteButton";
// import MediaUpload from "../components/MediaUpload";
// import Wysiwyg from "../components/Wysiwyg";
// import Icon from "../components/Icon";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources, updateResource } from "../api";
// import { generateUUID, copyToClipBoard } from "../helpers";

class CompanyDetails extends Component {
    static contextType = ApiContext;
    state = {
        name: "",
        short_name: "",
        chinese_name: "",
        account_number: "",
        bank_address_1: "",
        bank_address_2: "",
        bank_address_3: "",
        invoice_email: "",
        swift_code: "",
        website: "",
        isLoading: false,
        isSubmitting: false,
        error: null
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchItem();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    // handleImageUpload = data => {
    //     this.safeSetState({ avatar: `${data}?${new Date().getTime()}` });
    //     this.context.setApiState("user", {
    //         ...this.context.user,
    //         avatar: `${data}?${new Date().getTime()}`
    //     });
    // };

    fetchItem = () => {
        this.safeSetState({ isLoading: true });
        this.context
            .callApi(() => fetchResources("settings"))
            .then(({ data }) => {
                this.safeSetState({
                    name: data.name,
                    short_name: data.short_name || "",
                    chinese_name: data.chinese_name,
                    account_number: data.account_number,
                    bank_address_1: data.bank_address_1,
                    bank_address_2: data.bank_address_2,
                    bank_address_3: data.bank_address_3,
                    invoice_email: data.invoice_email,
                    swift_code: data.swift_code || "",
                    website: data.website,
                    isLoading: false
                });
            })
            .catch(error => {
                this.safeSetState({ isLoading: false, error });
                toast.error(<Trans>Failed to fetch item.</Trans>);
            });
    };

    handleUpdate = e => {
        e.preventDefault();
        const { callApi } = this.context;
        const {
            name,
            short_name,
            chinese_name,
            account_number,
            bank_address_1,
            bank_address_2,
            bank_address_3,
            swift_code,
            invoice_email,
            website
        } = this.state;
        this.safeSetState({ isSubmitting: true, error: null });
        callApi(() =>
            updateResource(`/settings`, {
                name,
                short_name,
                chinese_name,
                account_number,
                bank_address_1,
                bank_address_2,
                bank_address_3,
                swift_code,
                invoice_email,
                website
            })
        )
            .then(({ data }) => {
                toast.success(<Trans>Saved!</Trans>);
                this.safeSetState({
                    name: data.name,
                    short_name: data.short_name || "",
                    chinese_name: data.chinese_name,
                    account_number: data.account_number,
                    bank_address_1: data.bank_address_1,
                    bank_address_2: data.bank_address_2,
                    bank_address_3: data.bank_address_3,
                    invoice_email: data.invoice_email,
                    swift_code: data.swift_code || "",
                    website: data.website,
                    isSubmitting: false
                });
            })
            .catch(error => {
                this.safeSetState({ isSubmitting: false, error: error.errors });
                toast.error(error.message);
            });
    };

    render() {
        // const { user } = this.context;
        const {
            name,
            short_name,
            chinese_name,
            account_number,
            bank_address_1,
            bank_address_2,
            bank_address_3,
            swift_code,
            invoice_email,
            website,
            error,
            isSubmitting,
            isLoading
        } = this.state;
        // const { locale } = this.context.user;

        return (
            <div className="main-page-container">
                <div className="p-2 md:p-8">
                    <div>
                        <h1 className="page-header">
                            <Trans>Company Details</Trans>
                        </h1>
                        <LoadingWrapper isLoading={isLoading} retry={this.fetchItem}>
                            <div className="form-container">
                                <div className="form-section-header bg-grey-lighter">
                                    <Trans>Basic Info</Trans>
                                </div>

                                {/* <div className="form-input-group"> */}
                                {/*     <label className="form-label"> */}
                                {/*         <Trans>Avatar</Trans> */}
                                {/*     </label> */}
                                {/*     <div className="w-full"> */}
                                {/*         {avatar ? ( */}
                                {/*             <div> */}
                                {/*                 <img */}
                                {/*                     className="profile-avatar" */}
                                {/*                     src={avatar} */}
                                {/*                     alt="Staff" */}
                                {/*                 /> */}
                                {/*             </div> */}
                                {/*         ) : ( */}
                                {/*             <div className="text-grey mb-4"> */}
                                {/*                 <Icon icon="user-square" size={300} /> */}
                                {/*             </div> */}
                                {/*         )} */}
                                {/*         <MediaUpload */}
                                {/*             onSuccess={this.handleImageUpload} */}
                                {/*             url={`/avatar/${id}`} */}
                                {/*             accept="image/*" */}
                                {/*         /> */}
                                {/*         {error && error.avatar && ( */}
                                {/*             <span className="text-red text-xs">{error.avatar}</span> */}
                                {/*         )} */}
                                {/*     </div> */}
                                {/* </div> */}

                                <form method="POST" onSubmit={this.handleUpdate}>
                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Name</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="text"
                                                value={name}
                                                disabled={isSubmitting}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        name: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.name && (
                                                <span className="text-red text-xs">
                                                    {error.name}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Chinese Name</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="text"
                                                value={chinese_name}
                                                disabled={isSubmitting}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        chinese_name: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.chinese_name && (
                                                <span className="text-red text-xs">
                                                    {error.chinese_name}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Short Name</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="text"
                                                value={short_name}
                                                disabled={isSubmitting}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        short_name: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.short_name && (
                                                <span className="text-red text-xs">
                                                    {error.short_name}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Website</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="text"
                                                value={website}
                                                disabled={isSubmitting}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        website: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.website && (
                                                <span className="text-red text-xs">
                                                    {error.website}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Invoice Reply-to Email</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="text"
                                                value={invoice_email}
                                                disabled={isSubmitting}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        invoice_email: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.invoice_email && (
                                                <span className="text-red text-xs">
                                                    {error.invoice_email}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-section-header bg-grey-lighter">
                                        <Trans>Bank Details</Trans>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Bank Account Number</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="text"
                                                value={account_number}
                                                disabled={isSubmitting}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        account_number: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.account_number && (
                                                <span className="text-red text-xs">
                                                    {error.account_number}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Bank Swift Code</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="text"
                                                value={swift_code}
                                                disabled={isSubmitting}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        swift_code: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.swift_code && (
                                                <span className="text-red text-xs">
                                                    {error.swift_code}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Bank Address Line 1</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="text"
                                                value={bank_address_1}
                                                disabled={isSubmitting}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        bank_address_1: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.bank_address_1 && (
                                                <span className="text-red text-xs">
                                                    {error.bank_address_1}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Bank Address Line 2</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="text"
                                                value={bank_address_2}
                                                disabled={isSubmitting}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        bank_address_2: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.bank_address_2 && (
                                                <span className="text-red text-xs">
                                                    {error.bank_address_2}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Bank Address Line 3</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="text"
                                                value={bank_address_3}
                                                disabled={isSubmitting}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        bank_address_3: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.bank_address_3 && (
                                                <span className="text-red text-xs">
                                                    {error.bank_address_3}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-actions">
                                        <button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="btn btn-primary">
                                            {isSubmitting ? (
                                                <Trans>Saving...</Trans>
                                            ) : (
                                                <Trans>Save</Trans>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </LoadingWrapper>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanyDetails;
