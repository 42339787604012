import React from "react";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";

const NoMatch = () => (
    <div className="text-4xl p-2 w-full min-h-screen opacity-95 flex-col flex items-center justify-center bg-red-lighter">
        <div className="text-left">
            <h1 className="text-sm mb-2 uppercase text-red">
                <Trans>Error 404</Trans>
            </h1>
            <h2 className="text-2xl font-hairline text-white mb-2">
                <Trans>Oops! The page you're looking for doesn't exist.</Trans>
            </h2>
            <p className="text-white text-sm">
                <Trans>
                    Click{" "}
                    <Link className="text-white hover:text-red" to="/">
                        here
                    </Link>{" "}
                    to return to safety!
                </Trans>
            </p>
        </div>
    </div>
);

export default NoMatch;
