import React from "react";
import { Route, Redirect } from "react-router-dom";
import { ApiContext } from "../contexts/ApiContext";

class ProtectedRoute extends React.Component {
    static contextType = ApiContext;

    render() {
        const { component: Component, ...rest } = this.props;
        const { user } = this.context;

        if (!user || !localStorage.getItem("THYME_JWT")) {
            return (
                <Route
                    {...rest}
                    render={props => (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    )}
                />
            );
        }

        if (!Component) return <Route {...rest} />;

        return <Route {...rest} render={props => <Component {...props} />} />;
    }
}

export default ProtectedRoute;
