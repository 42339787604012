import React, { Component } from "react";
import PropTypes from "prop-types";
import { Trans, t } from "@lingui/macro";
import { toast } from "react-toastify";
import moment from "moment";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources, deleteResource, updateResource } from "../api";
import LoadingWrapper from "../components/LoadingWrapper";
// import Icon from "../components/Icon";
import { i18n } from "../App";

class StintDetail extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        setState: PropTypes.func
    };
    static contextType = ApiContext;
    state = {
        id: null,
        name: "",
        notes: "",
        parent: "",
        clients: [],
        billable: false,
        start: null,
        end: null,
        isLoading: false,
        isSubmitting: false,
        isDeleting: false
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchStint();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    handleUpdate = () => {
        const { name, notes, parent, billable, start, end, id } = this.state;

        if (!name) {
            return toast.error(<Trans>A name is required.</Trans>);
        }

        this.safeSetState({ isSubmitting: true });
        this.context
            .callApi(() =>
                updateResource(`stints/${id}`, {
                    name,
                    notes,
                    parent,
                    billable,
                    start: moment(start).unix(),
                    end: end ? moment(end).unix() : null
                })
            )
            .then(({ data }) => {
                this.safeSetState({
                    id: data.id,
                    start: moment.unix(data.start).format("YYYY-MM-DDTHH:mm:ss"),
                    end: data.end ? moment.unix(data.end).format("YYYY-MM-DDTHH:mm:ss") : "",
                    notes: data.notes,
                    billable: data.billable,
                    parent: data.parent,
                    name: data.name,
                    isSubmitting: false
                });
                if (this.props.setState) {
                    this.props.setState({ stintId: null });
                }
                this.props.fetch();
                if (this.props.fetchCurrent) {
                    this.props.fetchCurrent();
                }
            })
            .catch(error => {
                this.safeSetState({ isSubmitting: false, error });
                if (typeof error === "object") {
                    return toast.error(<Trans>Failed to update timer.</Trans>);
                }
                toast.error(error);
            });
    };

    handleDelete = () => {
        const { id } = this.state;
        const confirm = window.confirm("Are you sure you want to delete this stint?");
        if (!confirm) {
            return;
        }
        this.safeSetState({ isDeleting: true });
        this.context
            .callApi(() => deleteResource(`stints/${id}`))
            .then(() => {
                this.safeSetState({ isDeleting: false });
                this.props.setState({ stintId: null });
                this.props.fetch();
            })
            .catch(error => {
                this.safeSetState({ isDeleting: false, error });
                if (typeof error === "object") {
                    return toast.error(<Trans>Failed to stop timer.</Trans>);
                }
                toast.error(error);
            });
    };

    fetchStint = () => {
        const { id } = this.props;
        this.safeSetState({ isLoading: true });
        this.context
            .callApi(() => fetchResources(`stints/${id}?edit`))
            .then(({ data: { stint, clients } }) => {
                this.safeSetState({
                    id: stint.id,
                    start: moment.unix(stint.start).format("YYYY-MM-DDTHH:mm:ss"),
                    end: stint.end ? moment.unix(stint.end).format("YYYY-MM-DDTHH:mm:ss") : "",
                    notes: stint.notes,
                    billable: stint.billable,
                    parent: stint.parent,
                    name: stint.name,
                    clients,
                    isLoading: false
                });
            })
            .catch(error => {
                this.safeSetState({ isLoading: false, error });
                if (typeof error === "object") {
                    return toast.error(<Trans>Failed to fetch timer.</Trans>);
                }
                toast.error(error);
            });
    };

    convertSecondsToTimeString = seconds => {
        if (!seconds) {
            return "";
        }

        const duration = moment.duration(seconds * 1000);
        const hrs = duration.hours(),
            mins = duration.minutes(),
            secs = duration.seconds();

        return `${hrs < 10 ? "0" + hrs : hrs}:${mins < 10 ? "0" + mins : mins}:${
            secs < 10 ? "0" + secs : secs
        }`;
    };

    render() {
        const {
            start,
            end,
            notes,
            billable,
            name,
            isLoading,
            parent,
            isSubmitting,
            isDeleting,
            error,
            clients
        } = this.state;
        return (
            <div className="w-full text-grey-dark overflow-y-auto">
                <LoadingWrapper isLoading={isLoading}>
                    <div className="rounded bg-white">
                        <div className="p-4 border-b">
                            <label className="block mb-1 text-grey-dark text-xs">
                                <Trans>Title</Trans>
                            </label>
                            <div className="w-full">
                                <input
                                    className="form-input w-full"
                                    type="text"
                                    value={name}
                                    disabled={isSubmitting}
                                    onChange={e =>
                                        this.safeSetState({
                                            name: e.target.value
                                        })
                                    }
                                />
                                {error && error.name && (
                                    <span className="text-red text-xs">{error.name}</span>
                                )}
                            </div>
                        </div>

                        <div className="p-4 border-b">
                            <label className="block mb-1 text-grey-dark text-xs">
                                <Trans>Start Time</Trans>
                            </label>
                            <div className="w-full">
                                <input
                                    className="form-input w-full"
                                    type="datetime-local"
                                    value={start || ""}
                                    disabled={isSubmitting}
                                    onChange={e => this.setState({ start: e.target.value })}
                                />
                                {error && error.start && (
                                    <span className="text-red text-xs">{error.start}</span>
                                )}
                            </div>
                        </div>

                        <div className="p-4 border-b">
                            <label className="block mb-1 text-grey-dark text-xs">
                                <Trans>End Time</Trans>
                            </label>
                            <div className="w-full">
                                <input
                                    className="form-input w-full"
                                    type="datetime-local"
                                    value={end || ""}
                                    disabled={isSubmitting}
                                    onChange={e => this.setState({ end: e.target.value })}
                                />
                                {error && error.end && (
                                    <span className="text-red text-xs">{error.end}</span>
                                )}
                            </div>
                        </div>

                        <div className="p-4 border-b">
                            <label className="block mb-1 text-grey-dark text-xs">
                                <Trans>Parent</Trans>
                            </label>
                            <div className="w-full">
                                <select
                                    value={parent}
                                    disabled={isSubmitting}
                                    className="form-input w-full"
                                    onChange={e => this.setState({ parent: e.target.value })}>
                                    <option value="">{i18n._(t`None`)}</option>
                                    {clients.map((client, index) => (
                                        <optgroup key={index} label={client.name}>
                                            {client.projects.map(project => (
                                                <option key={project.id} value={project.id}>
                                                    {project.name}
                                                </option>
                                            ))}
                                        </optgroup>
                                    ))}
                                </select>
                                {error && error.currency_id && (
                                    <span className="text-red text-xs">{error.currency_id}</span>
                                )}
                            </div>
                        </div>

                        <div className="p-4 border-b">
                            <label className="block mb-1 text-grey-dark text-xs">
                                <Trans>Notes</Trans>
                            </label>
                            <div className="w-full">
                                <textarea
                                    rows="5"
                                    className={`${
                                        error && error.notes ? "border-red" : ""
                                    } form-input w-full`}
                                    onChange={e =>
                                        this.safeSetState({
                                            notes: e.target.value
                                        })
                                    }
                                    value={notes || ""}
                                />
                                {error && error.notes && (
                                    <span className="text-red text-xs block mt-1">
                                        {error.notes}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="p-4 border-b">
                            <label className="block mb-1 text-grey-dark text-xs">
                                <Trans>Billable?</Trans>
                            </label>
                            <div className="w-full">
                                <label className="cursor-pointer">
                                    <input
                                        type="checkbox"
                                        onChange={e =>
                                            this.setState({
                                                billable: e.target.checked
                                            })
                                        }
                                        checked={billable}
                                        className="mr-2"
                                    />
                                    <span className="text-sm">
                                        <Trans>Check this box if this stint is billable.</Trans>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between p-4">
                        <button
                            onClick={this.handleDelete}
                            disabled={isSubmitting || isDeleting}
                            className="text-center cursor-pointer no-underline whitespace-no-wrap text-red hover:text-red-dark text-sm py-2 px-4">
                            {isDeleting ? <Trans>Deleting...</Trans> : <Trans>Delete</Trans>}
                        </button>
                        <button
                            onClick={() => this.props.setState({ stintId: null })}
                            disabled={isSubmitting || isDeleting}
                            className="text-center cursor-pointer no-underline whitespace-no-wrap text-blue hover:text-blue-dark text-sm py-2 px-4">
                            <Trans>Cancel</Trans>
                        </button>
                        <button
                            className="btn btn-primary"
                            disabled={isSubmitting || isDeleting}
                            onClick={this.handleUpdate}>
                            {isSubmitting ? <Trans>Updating...</Trans> : <Trans>Update</Trans>}
                        </button>
                    </div>
                </LoadingWrapper>
            </div>
        );
    }
}

export default StintDetail;
