import React from "react";
import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";

import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ResetPassword from "../pages/auth/ResetPassword";
import RequestPasswordReset from "../pages/auth/RequestPasswordReset";
import VerifyEmail from "../pages/auth/VerifyEmail";
import Profile from "../pages/Profile";
import ReleaseNotes from "../pages/ReleaseNotes";
import ChangePassword from "../pages/ChangePassword";

// import FeedbackForm from "../pages/FeedbackForm";
import ReportsIndex from "../pages/ReportsIndex";
import ExpenseIndex from "../pages/ExpenseIndex";
import ExpenseCategoryIndex from "../pages/ExpenseCategoryIndex";
// import FeedbackShow from "../pages/FeedbackShow";

import CurrencyRates from "../pages/CurrencyRates";

// import TaskIndex from "../pages/TaskIndex";
import ProjectIndex from "../pages/ProjectIndex";
import ProjectDetail from "../pages/ProjectDetail";
import ContactIndex from "../pages/ContactIndex";

// import AttachmentIndex from "../pages/AttachmentIndex";

import Timesheet from "../pages/Timesheet";

import InvoiceOverview from "../pages/InvoiceOverview";
import InvoiceCreate from "../pages/InvoiceCreate";
import InvoiceShow from "../pages/InvoiceShow";

import CompanyDetails from "../pages/CompanyDetails";

import ClientIndex from "../pages/ClientIndex";
import ClientCreate from "../pages/ClientCreate";
import ClientShow from "../pages/ClientShow";

import ContactCreate from "../pages/ContactCreate";
import ProjectCreate from "../pages/ProjectCreate";

import NoMatch from "../pages/NoMatch";

const RoutesContainer = ({ user }) => (
    <Switch>
        <ProtectedRoute exact path="/" component={Timesheet} />
        {/* Auth */}
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/forgot" component={RequestPasswordReset} />
        <Route exact path="/verify/:email/:token" component={VerifyEmail} />
        <Route exact path="/reset/:token" component={ResetPassword} />
        <Route exact path="/change-password" component={ChangePassword} />
        {/* Feedback */}
        <ProtectedRoute exact path="/profile" component={Profile} />
        <ProtectedRoute exact path="/company-details" component={CompanyDetails} />
        {/* Projects */}
        <ProtectedRoute exact path="/reports/:tab?" component={ReportsIndex} />
        <ProtectedRoute exact path="/invoices/create" component={InvoiceCreate} />
        <ProtectedRoute exact path="/invoices/paid" component={InvoiceOverview} />
        <ProtectedRoute exact path="/invoices/:id" component={InvoiceShow} />
        <ProtectedRoute exact path="/invoices/:id/edit" component={InvoiceCreate} />
        <ProtectedRoute exact path="/invoices/create/:client_id" component={InvoiceCreate} />
        <ProtectedRoute exact path="/invoices" component={InvoiceOverview} />
        <ProtectedRoute exact path="/expenses" component={ExpenseIndex} />
        <ProtectedRoute exact path="/expense-categories" component={ExpenseCategoryIndex} />
        <ProtectedRoute exact path="/contacts" component={ContactIndex} />
        <ProtectedRoute exact path="/projects" component={ProjectIndex} />
        {/* Projects */}
        <ProtectedRoute exact path="/projects/create" component={ProjectCreate} />
        <ProtectedRoute exact path="/projects/:id/edit" component={ProjectCreate} />
        <ProtectedRoute exact path="/projects/:id" component={ProjectDetail} />
        {/* Clients */}
        <ProtectedRoute exact path="/currency-rates" component={CurrencyRates} />
        <ProtectedRoute exact path="/release-notes" component={ReleaseNotes} />
        <ProtectedRoute exact path="/clients" component={ClientIndex} />
        <ProtectedRoute exact path="/clients/archive" component={ClientIndex} />
        <ProtectedRoute exact path="/clients/create" component={ClientCreate} />
        <ProtectedRoute exact path="/clients/:id" component={ClientShow} />
        <ProtectedRoute exact path="/clients/:id/edit" component={ClientCreate} />
        {/* Contacts */}
        <ProtectedRoute exact path="/contacts/create" component={ContactCreate} />
        <ProtectedRoute exact path="/contacts/:id/edit" component={ContactCreate} />
        <ProtectedRoute
            exact
            path="/clients/:client_id/contacts/create"
            component={ContactCreate}
        />
        <Route component={NoMatch} />
    </Switch>
);

export default RoutesContainer;
