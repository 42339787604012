import React, { Component } from "react";
import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
import { Trans, t } from "@lingui/macro";
// import moment from "moment";
// import history from "../components/history";
import LoadingWrapper from "../components/LoadingWrapper";
import { i18n } from "../App";
// import DeleteButton from "../components/DeleteButton";
import MediaUpload from "../components/MediaUpload";
// import Wysiwyg from "../components/Wysiwyg";
import Icon from "../components/Icon";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources, updateResource } from "../api";
import { generateUUID, copyToClipBoard } from "../helpers";

class Profile extends Component {
    static contextType = ApiContext;
    state = {
        email: "",
        family_name: "",
        given_names: "",
        access_token: "",
        currency_id: "",
        currencies: [],
        id: "",
        isLoading: false,
        isFetchingCurrencies: false,
        isSubmitting: false,
        error: null
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchItem();
        this.fetchCurrencies();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.fetchItem();
        }
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    handleImageUpload = data => {
        this.safeSetState({ avatar: `${data}?${new Date().getTime()}` });
        this.context.setApiState("user", {
            ...this.context.user,
            avatar: `${data}?${new Date().getTime()}`
        });
    };

    fetchItem = () => {
        this.safeSetState({ isLoading: true });
        this.context
            .callApi(() => fetchResources("profile"))
            .then(({ data }) => {
                this.safeSetState({
                    avatar: data.avatar ? `${data.avatar}?${new Date().getTime()}` : null,
                    email: data.email,
                    currency_id: data.currency_id,
                    access_token: data.access_token || "",
                    family_name: data.family_name || "",
                    given_names: data.given_names,
                    id: data.id,
                    isLoading: false,
                    error: null
                });
            })
            .catch(error => {
                this.safeSetState({ isLoading: false, error });
                toast.error(<Trans>Failed to fetch item.</Trans>);
            });
    };

    handleUpdate = e => {
        e.preventDefault();
        const { callApi } = this.context;
        const {
            email,
            access_token,
            avatar,
            family_name,
            given_names,
            id,
            currency_id
        } = this.state;
        this.safeSetState({ isSubmitting: true, error: null });
        callApi(() =>
            updateResource(`/users/${id}`, {
                email,
                access_token,
                avatar,
                family_name,
                given_names,
                currency_id
            })
        )
            .then(({ data }) => {
                toast.success(<Trans>Saved!</Trans>);
                this.safeSetState({
                    avatar: data.avatar ? `${data.avatar}?${new Date().getTime()}` : null,
                    email: data.email,
                    currency_id: data.currency_id,
                    access_token: data.access_token || "",
                    family_name: data.family_name || "",
                    given_names: data.given_names,
                    id: data.id,
                    isSubmitting: false,
                    error: null
                });
            })
            .catch(error => {
                this.safeSetState({ isSubmitting: false, error: error.errors });
                toast.error(error.message);
            });
    };

    fetchCurrencies = () => {
        this.safeSetState({ isFetchingCurrencies: true });
        this.context
            .callApi(() => fetchResources("currencies"))
            .then(({ data }) => {
                this.safeSetState({
                    currencies: data,
                    isFetchingCurrencies: false
                });
            })
            .catch(error => {
                this.safeSetState({
                    isFetchingCurrencies: false,
                    error,
                    message: error.status === 403 ? <Trans>Not allowed.</Trans> : ""
                });
                toast.error(<Trans>Failed to fetch currencies.</Trans>);
            });
    };

    render() {
        // const { user } = this.context;
        const {
            email,
            id,
            avatar,
            family_name,
            access_token,
            given_names,
            isFetchingCurrencies,
            currency_id,
            currencies,
            error,
            isSubmitting,
            isLoading
        } = this.state;
        // const { locale } = this.context.user;

        return (
            <div className="main-page-container">
                <div className="p-2 md:p-8">
                    <div>
                        <h1 className="page-header">
                            <Trans>Profile</Trans>
                        </h1>
                        <LoadingWrapper isLoading={isLoading || isFetchingCurrencies}>
                            <div className="form-container">
                                <div className="form-section-header bg-grey-lighter">
                                    <Trans>Basic Info</Trans>
                                </div>

                                <div className="form-input-group">
                                    <label className="form-label">
                                        <Trans>Avatar</Trans>
                                    </label>
                                    <div className="w-full">
                                        {avatar ? (
                                            <div>
                                                <img
                                                    className="profile-avatar"
                                                    src={avatar}
                                                    alt="Staff"
                                                />
                                            </div>
                                        ) : (
                                            <div className="text-grey mb-4">
                                                <Icon icon="user-square" size={300} />
                                            </div>
                                        )}
                                        <MediaUpload
                                            onSuccess={this.handleImageUpload}
                                            url={`/avatar/${id}`}
                                            accept="image/*"
                                        />
                                        {error && error.avatar && (
                                            <span className="text-red text-xs">{error.avatar}</span>
                                        )}
                                    </div>
                                </div>
                                <form method="POST" onSubmit={this.handleUpdate}>
                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Family Name</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="text"
                                                value={family_name}
                                                disabled={isSubmitting}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        family_name: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.family_name && (
                                                <span className="text-red text-xs">
                                                    {error.family_name}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Given Names</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="text"
                                                value={given_names}
                                                disabled={isSubmitting}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        given_names: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.given_names && (
                                                <span className="text-red text-xs">
                                                    {error.given_names}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Email</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="text"
                                                value={email}
                                                disabled
                                                onChange={e =>
                                                    this.safeSetState({ email: e.target.value })
                                                }
                                            />
                                            {error && error.email && (
                                                <span className="text-red text-xs">
                                                    {error.email}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Personal Access Token</Trans>
                                        </label>
                                        <div className="w-full">
                                            <div className="flex">
                                                <input
                                                    className="form-input w-full mr-2"
                                                    type="text"
                                                    value={access_token}
                                                    disabled
                                                    onChange={e =>
                                                        this.safeSetState({
                                                            access_token: e.target.value
                                                        })
                                                    }
                                                />
                                                <button
                                                    type="button"
                                                    disabled={!this.state.access_token}
                                                    style={{ height: 34, width: 34, minWidth: 34 }}
                                                    className="text-grey border-grey hover:border-teal hover:text-teal border rounded mr-2"
                                                    onClick={() =>
                                                        copyToClipBoard(this.state.access_token)
                                                    }>
                                                    <Icon icon="copy" />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    onClick={() =>
                                                        this.setState({
                                                            access_token: generateUUID()
                                                        })
                                                    }>
                                                    <Trans>Generate</Trans>
                                                </button>
                                            </div>
                                            {error && error.access_token && (
                                                <span className="text-red text-xs">
                                                    {error.access_token}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-section-header bg-grey-lighter">
                                        <Trans>Preferences</Trans>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">Currency</label>
                                        <div className="w-full">
                                            <select
                                                value={currency_id}
                                                disabled={isSubmitting}
                                                className="form-input w-full"
                                                onChange={e =>
                                                    this.setState({ currency_id: e.target.value })
                                                }>
                                                <option>-- {i18n._(t`Select Currency`)} --</option>
                                                {currencies.map(currency => (
                                                    <option key={currency.id} value={currency.id}>
                                                        {currency.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {error && error.currency_id && (
                                                <span className="text-red text-xs">
                                                    {error.currency_id}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-actions">
                                        <button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="btn btn-primary">
                                            {isSubmitting ? (
                                                <Trans>Saving...</Trans>
                                            ) : (
                                                <Trans>Save</Trans>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </LoadingWrapper>
                    </div>
                </div>
            </div>
        );
    }
}

export default Profile;
