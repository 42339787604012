import React, { Component } from "react";
import { toast } from "react-toastify";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../App";
import { fetchResources, createResource } from "../api";
import { ApiContext } from "../contexts/ApiContext";

class NewInvoiceButton extends Component {
    static contextType = ApiContext;
    state = {
        show_form: false,
        notes: "",
        amount: "",
        item_name: "",
        user_id: "",
        category_id: "",
        project_id: "",
        currency_id: [],
        paid_at: "",
        users: [],
        clients: [],
        categories: [],
        currencies: [],
        isFetchingUsers: false,
        isFetchingCategories: false,
        isFetchingProjects: false,
        isFetchingCurrencies: false,
        isSubmitting: false
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.show_form !== this.state.show_form && this.state.show_form) {
            this.fetchProjects();
            this.fetchCategories();
            this.fetchUsers();
            this.fetchCurrencies();
        }
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    fetchCurrencies = () => {
        this.safeSetState({ isFetchingCurrencies: true });
        this.context
            .callApi(() => fetchResources("currencies"))
            .then(({ data }) => {
                this.safeSetState({
                    currencies: data,
                    isFetchingCurrencies: false
                });
            })
            .catch(error => {
                this.safeSetState({
                    isFetchingCurrencies: false,
                    error,
                    message: error.status === 403 ? <Trans>Not allowed.</Trans> : ""
                });
                toast.error(<Trans>Failed to fetch currencies.</Trans>);
            });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.safeSetState({ isSubmitting: true });
        const {
            notes,
            amount,
            item_name,
            user_id,
            category_id,
            project_id,
            paid_at,
            currency_id
        } = this.state;
        const payload = {
            notes,
            amount,
            item_name,
            user_id,
            category_id,
            project_id,
            paid_at,
            currency_id
        };
        this.context
            .callApi(() => createResource("expenses", payload))
            .then(({ data }) => {
                this.safeSetState({
                    isSubmitting: false,
                    notes: "",
                    amount: "",
                    item_name: "",
                    user_id: "",
                    category_id: "",
                    project_id: "",
                    paid_at: ""
                });
                toast.success(<Trans>Expense created!</Trans>);
            })
            .catch(error => {
                this.safeSetState({
                    isSubmitting: false,
                    error: error.errors
                });
                toast.error(<Trans>Failed to create expense.</Trans>);
            });
    };

    fetchUsers = () => {
        this.safeSetState({ isFetchingUsers: true });
        this.context
            .callApi(() => fetchResources("users?simple"))
            .then(({ data }) => {
                this.safeSetState({
                    users: data,
                    isFetchingUsers: false
                });
            })
            .catch(error => {
                this.safeSetState({
                    isFetchingUsers: false,
                    error,
                    message: error.status === 403 ? <Trans>Not allowed.</Trans> : ""
                });
                toast.error(<Trans>Failed to fetch users.</Trans>);
            });
    };

    fetchProjects = () => {
        this.safeSetState({ isFetchingProjects: true });
        this.context
            .callApi(() => fetchResources("projects?simple"))
            .then(({ data }) => {
                this.safeSetState({
                    clients: data,
                    isFetchingProjects: false
                });
            })
            .catch(error => {
                this.safeSetState({
                    isFetchingProjects: false,
                    error,
                    message: error.status === 403 ? <Trans>Not allowed.</Trans> : ""
                });
                toast.error(<Trans>Failed to fetch projects.</Trans>);
            });
    };

    fetchCategories = () => {
        this.safeSetState({ isFetchingCategories: true });
        this.context
            .callApi(() => fetchResources("expense-categories?simple"))
            .then(({ data }) => {
                this.safeSetState({
                    categories: data,
                    isFetchingCategories: false
                });
            })
            .catch(error => {
                this.safeSetState({
                    isFetchingCategories: false,
                    error,
                    message: error.status === 403 ? <Trans>Not allowed.</Trans> : ""
                });
                toast.error(<Trans>Failed to fetch categories.</Trans>);
            });
    };

    generate = () => {
        this.setState({
            project_id: 1,
            client_id: 1,
            user_id: 1,
            paid_at: "2019-01-01",
            category_id: 1,
            amount: "123.45",
            notes: "Example expense notes."
        });
    };

    render() {
        const {
            show_form,
            isSubmitting,
            project_id,
            clients,
            item_name,
            paid_at,
            categories,
            category_id,
            users,
            error,
            currency_id,
            currencies,
            user_id,
            amount,
            notes
        } = this.state;

        return (
            <div>
                <div className="header-actions">
                    <button
                        onClick={() => this.setState({ show_form: true })}
                        disabled={show_form}
                        className="btn md:mb-0 mb-2 md:mr-2 btn-primary mr-auto">
                        <Trans>New Expense</Trans>
                    </button>
                </div>
                {show_form ? (
                    <div className="shadow bg-white rounded mb-4">
                        <form method="post" onSubmit={this.handleSubmit}>
                            <div className="form-input-group">
                                <label className="form-label">Item Name</label>
                                <div className="w-full">
                                    <input
                                        type="text"
                                        disabled={isSubmitting}
                                        value={item_name}
                                        className="form-input w-full"
                                        onChange={e => this.setState({ item_name: e.target.value })}
                                    />
                                    {error && error.item_name && (
                                        <span className="block text-red text-xs mt-1">
                                            {error.item_name}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="form-input-group">
                                <label className="form-label">
                                    <Trans>Currency</Trans>
                                </label>
                                <div className="w-full">
                                    <select
                                        disabled={isSubmitting || !currencies.length}
                                        value={currency_id}
                                        className="form-input w-full"
                                        style={{ minWidth: 175 }}
                                        onChange={e =>
                                            this.setState({ currency_id: e.target.value })
                                        }>
                                        <option>-- {i18n._(t`Select Currency`)} --</option>
                                        {currencies.map((currency, index) => (
                                            <option key={currency.id} value={currency.id}>
                                                {currency.name}
                                            </option>
                                        ))}
                                    </select>
                                    {error && error.currency_id && (
                                        <span className="block text-red text-xs mt-1">
                                            {error.currency_id}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="form-input-group">
                                <label className="form-label">
                                    <Trans>Amount</Trans>
                                </label>
                                <div className="w-full">
                                    <input
                                        type="text"
                                        disabled={isSubmitting}
                                        value={amount}
                                        className="form-input w-full"
                                        onChange={e => this.setState({ amount: e.target.value })}
                                    />
                                    {error && error.amount && (
                                        <span className="block text-red text-xs mt-1">
                                            {error.amount}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="form-input-group">
                                <label className="form-label">Paid At</label>
                                <div className="w-full">
                                    <input
                                        type="date"
                                        disabled={isSubmitting}
                                        value={paid_at}
                                        className="form-input w-full"
                                        onChange={e => this.setState({ paid_at: e.target.value })}
                                    />
                                    {error && error.paid_at && (
                                        <span className="block text-red text-xs mt-1">
                                            {error.paid_at}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="form-input-group">
                                <label className="form-label">
                                    <Trans>Project</Trans>
                                </label>
                                <div className="w-full">
                                    <select
                                        disabled={isSubmitting || !clients.length}
                                        value={project_id}
                                        className="form-input w-full"
                                        style={{ minWidth: 175 }}
                                        onChange={e =>
                                            this.setState({ project_id: e.target.value })
                                        }>
                                        <option>-- {i18n._(t`Select Project`)} --</option>
                                        {clients.map((client, index) => (
                                            <optgroup key={index} label={client.client_name}>
                                                {client.projects.map(project => (
                                                    <option key={project.id} value={project.id}>
                                                        {project.name}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        ))}
                                    </select>
                                    {error && error.project_id && (
                                        <span className="block text-red text-xs mt-1">
                                            {error.project_id}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="form-input-group">
                                <label className="form-label">
                                    <Trans>Category</Trans>
                                </label>
                                <div className="w-full">
                                    <select
                                        disabled={isSubmitting || !categories.length}
                                        value={category_id}
                                        className="form-input w-full"
                                        style={{ minWidth: 175 }}
                                        onChange={e =>
                                            this.setState({ category_id: e.target.value })
                                        }>
                                        <option>-- {i18n._(t`Select Category`)} --</option>
                                        {categories.map((cat, index) => (
                                            <option key={cat.id} value={cat.id}>
                                                {cat.name}
                                            </option>
                                        ))}
                                    </select>
                                    {error && error.category_id && (
                                        <span className="block text-red text-xs mt-1">
                                            {error.category_id}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="form-input-group">
                                <label className="form-label">
                                    <Trans>User</Trans>
                                </label>
                                <div className="w-full">
                                    <select
                                        disabled={isSubmitting || !users.length}
                                        value={user_id}
                                        className="form-input w-full"
                                        style={{ minWidth: 175 }}
                                        onChange={e => this.setState({ user_id: e.target.value })}>
                                        <option>-- {i18n._(t`Select User`)} --</option>
                                        {users.map((user, index) => (
                                            <option key={user.id} value={user.id}>
                                                {user.given_names}
                                            </option>
                                        ))}
                                    </select>
                                    {error && error.user_id && (
                                        <span className="block text-red text-xs mt-1">
                                            {error.user_id}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="form-input-group">
                                <label className="form-label">
                                    <Trans>Notes</Trans>
                                </label>
                                <textarea
                                    disabled={isSubmitting}
                                    value={notes}
                                    rows={4}
                                    className="form-input w-full"
                                    onChange={e => this.setState({ notes: e.target.value })}
                                />
                            </div>

                            <div className="p-4 flex justify-between items-center">
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={() => this.setState({ show_form: false })}>
                                    <Trans>Cancel</Trans>
                                </button>
                                {process.env.NODE_END === "development" ? (
                                    <button type="button" onClick={this.generate} className="btn">
                                        Generate
                                    </button>
                                ) : null}
                                <button className="btn btn-primary" disabled={isSubmitting}>
                                    <Trans>Record Expense</Trans>
                                </button>
                            </div>
                        </form>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default NewInvoiceButton;
