import React from "react";
import PropTypes from 'prop-types'
import { Trans } from "@lingui/macro";
import NewInvoiceButton from "../components/NewInvoiceButton";
import InvoiceStats from "../components/InvoiceStats";
import InvoiceIndex from "../components/InvoiceIndex";

const InvoiceOverview = ({ location }) => (
    <div className="main-page-container">
        <div className="p-2 md:p-8">
            <h1 className="page-header">
                <Trans>Invoices</Trans>
            </h1>
            <div className="">
                <NewInvoiceButton />
                <InvoiceStats />
                <InvoiceIndex showPaid={location.pathname.includes("paid")} />
            </div>
        </div>
    </div>
);

InvoiceOverview.propTypes = {
    location: PropTypes.object.isRequired
}

export default InvoiceOverview;
