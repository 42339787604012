import React, { Component } from "react";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import moment from "moment";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources } from "../api";
import LoadingWrapper from "../components/LoadingWrapper";
import history from "../components/history";

class InvoiceStats extends Component {
    static contextType = ApiContext;

    state = {
        invoices: [],
        isLoading: false
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchInvoices();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.showPaid !== this.props.showPaid) {
            this.fetchInvoices();
        }
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    fetchInvoices = (url = "invoices?") => {
        if (this.props.showPaid) {
            url += "&paid";
        }
        this.safeSetState({ isLoading: true });
        this.context
            .callApi(() => fetchResources(url))
            .then(({ data }) => {
                this.safeSetState({
                    invoices: data.data,
                    isLoading: false,
                    links: data.links || null,
                    meta: data.meta || null
                });
            })
            .catch(error => {
                this.safeSetState({ isLoading: false, error });
                if (typeof error === "object") {
                    return toast.error(<Trans>Failed to fetch item.</Trans>);
                }
                toast.error(error);
            });
    };

    render() {
        const { isLoading, invoices, links, meta } = this.state;
        const { showPaid } = this.props;
        const { user } = this.context;

        return (
            <div className="">
                <div className="flex justify-center">
                    <Link
                        to="/invoices"
                        className={`${!showPaid ? "tab-active" : ""} tab no-underline`}>
                        <Trans>Open</Trans>
                    </Link>
                    <Link
                        to="invoices/paid"
                        className={`${showPaid ? "tab-active" : ""} tab no-underline`}>
                        <Trans>All Invoices</Trans>
                    </Link>
                </div>
                <div className="rounded overflow-hidden shadow p-4 bg-white">
                    <LoadingWrapper isLoading={isLoading}>
                        <table className="w-full rounded overflow-hidden">
                            <thead>
                                <tr className="bg-grey-lighter border-b">
                                    <th className="text-xs p-2 font-medium text-left">
                                        <Trans>Status</Trans>
                                    </th>
                                    <th className="text-xs p-2 font-medium text-left">
                                        <Trans>Issue Date</Trans>
                                    </th>
                                    <th className="text-xs p-2 font-medium text-left">ID</th>
                                    <th className="text-xs p-2 font-medium text-left">
                                        <Trans>Client</Trans>
                                    </th>
                                    {!showPaid ? null : (
                                        <th className="text-xs p-2 font-medium text-left">
                                            <Trans>Paid At</Trans>
                                        </th>
                                    )}
                                    <th className="text-xs p-2 font-medium text-right">
                                        {showPaid ? (
                                            <Trans>Total</Trans>
                                        ) : (
                                            <Trans>Amount Due</Trans>
                                        )}
                                    </th>
                                    <th className="text-xs p-2 font-medium text-right">&nbsp;</th>
                                </tr>
                            </thead>
                            <tfoot>
                                {links && links.first !== links.last && (
                                    <tr className="text-xs bg-grey-lightest transition">
                                        <td className="py-2 px-4" colSpan={!showPaid ? 6 : 7}>
                                            <div className="flex justify-between items-center">
                                                {links.first && (
                                                    <button
                                                        className="mr-2 text-grey hover:text-grey-dark transition"
                                                        onClick={() =>
                                                            this.fetchInvoices(links.first)
                                                        }>
                                                        <Trans>First</Trans>
                                                    </button>
                                                )}
                                                <div>
                                                    {links.prev && (
                                                        <button
                                                            className="mr-2 text-grey hover:text-grey-dark transition"
                                                            onClick={() =>
                                                                this.fetchInvoices(links.prev)
                                                            }>
                                                            <Trans>Prev</Trans>
                                                        </button>
                                                    )}
                                                    {links.next && (
                                                        <button
                                                            className="mr-2 text-grey hover:text-grey-dark transition"
                                                            onClick={() =>
                                                                this.fetchInvoices(links.next)
                                                            }>
                                                            <Trans>Next</Trans>
                                                        </button>
                                                    )}
                                                </div>
                                                {links.last && (
                                                    <button
                                                        className="mr-2 text-grey hover:text-grey-dark transition"
                                                        onClick={() =>
                                                            this.fetchInvoices(links.last)
                                                        }>
                                                        <Trans>Last</Trans>
                                                    </button>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                {meta ? (
                                    <tr className="text-xs">
                                        <td className="py-2" colSpan={!showPaid ? 5 : 6}>
                                            {meta && meta.from && (
                                                <>
                                                    {user.locale === "en"
                                                        ? `Showing ${meta.from}-${meta.to} of ${
                                                              meta.total
                                                          }`
                                                        : `显示${meta.from}-${meta.to}, 共${
                                                              meta.total
                                                          }`}
                                                </>
                                            )}
                                        </td>
                                        <td className="whitespace-no-wrap py-2 text-right">
                                            {user.locale === "en"
                                                ? `Page ${meta.current_page} of ${meta.last_page}`
                                                : `第${meta.current_page}页，共${meta.last_page}页`}
                                        </td>
                                    </tr>
                                ) : null}
                            </tfoot>
                            <tbody>
                                {!invoices.length ? (
                                    <tr className="bg-white border-b">
                                        <td
                                            colSpan={!showPaid ? 6 : 7}
                                            className="p-2 text-sm text-left">
                                            <Trans>No invoices.</Trans>
                                        </td>
                                    </tr>
                                ) : null}
                                {invoices.map(invoice => (
                                    <tr
                                        key={invoice.id}
                                        onClick={() => history.push(`/invoices/${invoice.id}`)}
                                        className="hover:bg-grey-lightest cursor-pointer bg-white border-b">
                                        <td className="p-2 text-sm text-left">{invoice.status}</td>
                                        <td className="p-2 text-sm text-left whitespace-no-wrap">
                                            {moment.unix(invoice.billed_at).format("YYYY-MM-DD")}
                                        </td>
                                        <td className="p-2 text-sm text-left">{invoice.id}</td>
                                        <td className="p-2 text-sm text-left">
                                            {invoice.client_name}
                                        </td>
                                        {!showPaid ? null : (
                                            <td className="p-2 text-sm text-left whitespace-no-wrap">
                                                {invoice.paid_at
                                                    ? moment
                                                          .unix(invoice.paid_at)
                                                          .format("YYYY-MM-DD")
                                                    : "-"}
                                            </td>
                                        )}
                                        <td className="p-2 text-sm text-right">
                                            {showPaid ? (
                                                <>
                                                    {invoice.sub_total.toFixed(
                                                        invoice.currency_precision
                                                    )}{" "}
                                                    {invoice.currency_code}
                                                </>
                                            ) : (
                                                <>
                                                    {invoice.amount_due.toFixed(
                                                        invoice.currency_precision
                                                    )}{" "}
                                                    {invoice.currency_code}
                                                </>
                                            )}
                                        </td>
                                        <td className="p-2 text-sm text-right">
                                            <span className="text-grey-dark text-xs inline-block mr-2">
                                                {showPaid ? (
                                                    <>
                                                        {invoice.preferred_currency_sub_total.toFixed(
                                                            invoice.preferred_currency_precision
                                                        )}{" "}
                                                        {invoice.preferred_currency_code}
                                                    </>
                                                ) : (
                                                    <>
                                                        {invoice.preferred_currency_amount_due.toFixed(
                                                            invoice.preferred_currency_precision
                                                        )}{" "}
                                                        {invoice.preferred_currency_code}
                                                    </>
                                                )}
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </LoadingWrapper>
                </div>
            </div>
        );
    }
}

export default InvoiceStats;
