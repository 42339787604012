import React from "react";
import { Trans } from "@lingui/macro";
import PropTypes from "prop-types";
import Spinner from "react-spinkit";
import Debug from "../components/Debug";

const LoadingWrapper = ({ isLoading, error, children, minHeight, retry, message }) => {
    if (isLoading) {
        return (
            <div
                className="flex w-full justify-center items-center p-4"
                style={minHeight ? { minHeight } : {}}>
                <Spinner name="ball-beat" color="#039965" />
            </div>
        );
    }

    if (error) {
        let notFound = error.response && error.response.status && error.response.status === 404;

        return (
            <div className="text-4xl p-4 w-full flex-col flex items-center justify-center bg-red-lighter rounded mb-2">
                <h1 className="text-sm mb-2 uppercase text-red font-light">
                    <Trans>Error</Trans>
                    {notFound ? " 404" : ""}
                </h1>

                <h2 className="text-xl font-hairline text-white mb-2">
                    {message ? (
                        message
                    ) : notFound ? (
                        <Trans>We couldn't find that resource.</Trans>
                    ) : (
                        <Trans>Oops! Looks like something went wrong.</Trans>
                    )}
                </h2>

                {retry && (
                    <div className="p-2 text-sm">
                        <button onClick={retry} className="btn btn-red btn-sm">
                            <Trans>Retry</Trans>
                        </button>
                    </div>
                )}

                {error.response && (
                    <Debug>
                        {JSON.parse(
                            JSON.stringify(error.response, ["data", "status", "statusText"], 2)
                        )}
                    </Debug>
                )}
            </div>
        );
    }

    return children || null;
};

LoadingWrapper.propType = {
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired
};

export default LoadingWrapper;
