import React, { Component } from "react";
// import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
// import ResourceIndex from "./ResourceIndex";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources } from "../api";
import LoadingWrapper from "../components/LoadingWrapper";
import DeleteButton from "../components/DeleteButton";
import history from "../components/history";
import Icon from "../components/Icon";

class ClientIndex extends Component {
    static contextType = ApiContext;
    state = {
        clients: [],
        pagination: null,
        name: "",
        isLoading: false,
        isSubmitting: false,
        error: null
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchClients();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.path !== this.props.match.path) {
            this.fetchClients();
        }
    }

    fetchClients = (url = null) => {
        if (this.props.match.path.includes("archive")) {
            url = "clients/archive";
        }
        this.safeSetState({ isLoading: true });
        this.context
            .callApi(() => fetchResources(url ? url : "clients"))
            .then(({ data }) => {
                this.safeSetState({
                    clients: data.data,
                    isLoading: false,
                    error: null,
                    links: data.links || null,
                    meta: data.meta || null
                });
            })
            .catch(error => {
                this.safeSetState({ isLoading: false, error });
                if (typeof error === "object") {
                    return toast.error(<Trans>Failed to fetch item.</Trans>);
                }
                toast.error(error);
            });
    };

    render() {
        const { clients, isLoading } = this.state;

        const isArchive = this.props.match.path.includes("archive");

        return (
            <div className="main-page-container">
                <div className="p-2 md:p-8">
                    <h1 className="page-header">
                        <Trans>Manage Clients</Trans>
                    </h1>
                    <div className="header-actions">
                        <div className="flex">
                            <Link to={`/clients/create`} className="btn btn-primary mr-2">
                                <Trans>New Client</Trans>
                            </Link>
                            <Link to={`/contacts/create`} className="btn">
                                <Trans>Add Contact</Trans>
                            </Link>
                        </div>
                        {!isArchive ? (
                            <Link to={`/clients/archive`} className="btn">
                                <Trans>Archive</Trans>
                            </Link>
                        ) : (
                            <Link to={`/clients`} className="btn btn-primary">
                                <Trans>Active</Trans>
                            </Link>
                        )}
                    </div>
                    <div className="">
                        <LoadingWrapper isLoading={isLoading}>
                            {clients.length ? (
                                <>
                                    {clients.map(client => (
                                        <div
                                            key={client.id}
                                            className="shadow rounded overflow-hidden mb-4 bg-white rounded">
                                            <div className="flex p-2 items-center border-b justify-between bg-grey-lighter">
                                                <div className="flex items-center">
                                                    {!isArchive ? (
                                                        <Link
                                                            className="ml-2 mr-4 text-grey"
                                                            to={`/clients/${client.id}/edit`}>
                                                            <Icon icon="edit" size={16} />
                                                        </Link>
                                                    ) : null}
                                                    <h2 className="text-base font-light">
                                                        {client.name}
                                                    </h2>
                                                </div>
                                                {isArchive ? (
                                                    <DeleteButton
                                                        action="restore"
                                                        onDelete={() => history.push("/clients")}
                                                        url={`clients/${client.id}/archive`}>
                                                        <span className="btn block">
                                                            <Trans>Restore Client</Trans>
                                                        </span>
                                                    </DeleteButton>
                                                ) : (
                                                    <Link
                                                        to={`/clients/${client.id}/contacts/create`}
                                                        className="btn">
                                                        <Trans>Add Contact</Trans>
                                                    </Link>
                                                )}
                                            </div>
                                            {!isArchive ? (
                                                <div>
                                                    {client.contacts.map(contact => (
                                                        <div
                                                            key={contact.id}
                                                            className="p-4 flex items-start border-b">
                                                            <Link
                                                                className="mr-4 text-grey"
                                                                to={`/contacts/${contact.id}/edit`}>
                                                                <Icon icon="edit" size={16} />
                                                            </Link>
                                                            <div className="">
                                                                <h3 className="text-sm">
                                                                    {contact.given_names}{" "}
                                                                    {contact.family_name}{" "}
                                                                    {contact.job_title ? (
                                                                        <span className="text-xs text-grey">
                                                                            {contact.job_title}
                                                                        </span>
                                                                    ) : null}
                                                                </h3>
                                                                <a
                                                                    className="text-xs"
                                                                    href={`mailto:${
                                                                        contact.email
                                                                    }`}>
                                                                    {contact.email}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : null}
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <div className="shadow rounded overflow-hidden text-sm p-4 bg-white rounded">
                                    <Trans>No archived clients.</Trans>
                                </div>
                            )}
                        </LoadingWrapper>
                    </div>
                </div>
            </div>
        );
    }
}
export default ClientIndex;
