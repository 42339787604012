import React, { Component } from "react";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
import Modal from "react-modal";
// import { Link } from "react-router-dom";
import moment from "moment";
import { fetchResources } from "../api";
import { ApiContext } from "../contexts/ApiContext";
// import history from "../components/history";
import Icon from "../components/Icon";
// import Graph from "../components/Graph";
import StintDetail from "../components/StintDetail";
import LoadingWrapper from "../components/LoadingWrapper";
import DatePicker from "react-datepicker";
// import { locales } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
// registerLocale("en-GB", locales.enGB);

const customModalStyles = {
    overlay: {
        zIndex: 50
    },
    content: {
        maxWidth: 400,
        width: "100%",
        maxHeight: 640,
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        margin: "auto",
        padding: 10,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    }
};

class Timesheet extends Component {
    static contextType = ApiContext;
    state = {
        stints: [],
        users: [],
        stintId: null,
        user_id: this.context.user.id,
        display: "week", // week|day
        current_date: moment()
            .startOf("day")
            .unix(),
        isLoading: false
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchUsers();
        this.fetchStints();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            moment
                .unix(prevState.current_date)
                .startOf("isoweek")
                .unix() !==
                moment
                    .unix(this.state.current_date)
                    .startOf("isoweek")
                    .unix() ||
            prevState.user_id !== this.state.user_id
        ) {
            this.fetchStints();
        }
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    setParentState = object => {
        this.setState(object);
    };

    fetchStints = () => {
        const { user_id, current_date } = this.state;
        this.safeSetState({ isFetchingStints: true });
        this.context
            .callApi(() => fetchResources(`stints-by-week/${user_id}/${current_date}`))
            .then(({ data }) => {
                this.safeSetState({ stints: data, isFetchingStints: false });
            })
            .catch(error => {
                this.safeSetState({ isFetchingStints: false, error });
                toast.error(<Trans>Failed to fetch stints.</Trans>);
            });
    };

    handleChange = date => {
        if (this.state.display === "week") {
            return this.setState({
                current_date: moment(date)
                    .startOf("isoweek")
                    .unix()
            });
        }
        this.setState({
            current_date: moment(date)
                .startOf("day")
                .unix()
        });
    };

    fetchUsers = () => {
        this.safeSetState({ isFetchingUsers: true });
        this.context
            .callApi(() => fetchResources("users?simple"))
            .then(({ data }) => {
                this.safeSetState({
                    users: data,
                    isFetchingUsers: false
                });
            })
            .catch(error => {
                this.safeSetState({
                    isFetchingUsers: false,
                    error,
                    message: error.status === 403 ? <Trans>Not allowed.</Trans> : ""
                });
                toast.error(<Trans>Failed to fetch users.</Trans>);
            });
    };

    render() {
        const {
            current_date,
            display,
            users,
            stintId,
            user_id,
            stints,
            isFetchingStints
        } = this.state;

        return (
            <div className="main-page-container">
                <div className="p-2 md:p-8">
                    <h1 className="page-header">
                        <Trans>Timesheet</Trans>
                    </h1>

                    {users.length &&
                    parseInt(user_id, 10) !== parseInt(this.context.user.id, 10) ? (
                        <div className="p-2 mb-4 text-sm bg-orange text-white rounded shadow">
                            You are currently editing{" "}
                            {
                                users.filter(u => parseInt(u.id, 10) === parseInt(user_id, 10))[0]
                                    .given_names
                            }
                            's timesheet.
                        </div>
                    ) : null}

                    <div className="timesheet-date-picker flex-col md:flex-row mb-4">
                        <div className="flex flex-1 justify-between w-full mb-2 md:mb-0">
                            <div className="text-xl flex items-center">
                                <span
                                    className={`font-medium mr-2 whitespace-no-wrap ${
                                        display === "week" ? "text-sm" : ""
                                    }`}>
                                    {display === "day"
                                        ? moment.unix(current_date).format("ddd")
                                        : moment
                                              .unix(current_date)
                                              .startOf("isoWeek")
                                              .format("MMM DD") +
                                          " - " +
                                          moment
                                              .unix(current_date)
                                              .endOf("isoWeek")
                                              .format("MMM DD")}
                                </span>
                                {display === "day" ? (
                                    <span className="text-grey font-light whitespace-no-wrap">
                                        {moment.unix(current_date).format("MMM DD")}
                                    </span>
                                ) : null}
                            </div>
                            <div className="flex">
                                <div className="flex mr-2">
                                    <button
                                        onClick={() =>
                                            this.setState(prevState => ({
                                                current_date: moment
                                                    .unix(current_date)
                                                    .subtract(1, display + "s")
                                                    .unix()
                                            }))
                                        }
                                        className="text-center cursor-pointer no-underline whitespace-no-wrap text-sm border bg-grey-lightest hover:bg-grey-light text-grey-dark py-2 px-4 rounded-l">
                                        <Icon size={14} icon="left" />
                                    </button>
                                    <button
                                        onClick={() =>
                                            this.setState({
                                                current_date: moment()
                                                    .startOf(display === "week" ? "isoweek" : "day")
                                                    .unix()
                                            })
                                        }
                                        className={`text-center cursor-pointer no-underline whitespace-no-wrap text-sm border-t border-b text-grey-dark py-2 px-4 ${
                                            current_date ===
                                            moment()
                                                .startOf(display === "week" ? "isoweek" : "day")
                                                .unix()
                                                ? "bg-grey hover:bg-grey-dark text-white"
                                                : "bg-grey-lightest hover:bg-grey-light"
                                        }`}>
                                        {display === "week" ? (
                                            <Trans>This Week</Trans>
                                        ) : (
                                            <Trans>Today</Trans>
                                        )}
                                    </button>
                                    <button
                                        onClick={() =>
                                            this.setState(prevState => ({
                                                current_date: moment
                                                    .unix(current_date)
                                                    .add(1, display + "s")
                                                    .unix()
                                            }))
                                        }
                                        className="text-center cursor-pointer no-underline whitespace-no-wrap text-sm border bg-grey-lightest hover:bg-grey-light text-grey-dark py-2 px-4 rounded-r">
                                        <Icon size={14} icon="right" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between items-center w-full md:w-auto">
                            <div>
                                <DatePicker
                                    customInput={<CalendarButton />}
                                    selected={moment.unix(current_date).toDate()}
                                    onChange={this.handleChange}
                                    // locale="en-GB"
                                />
                            </div>

                            <div className="flex mr-2">
                                <button
                                    onClick={() => this.setState({ display: "day" })}
                                    className={`text-center cursor-pointer no-underline whitespace-no-wrap text-sm border-l border-t border-b text-grey-dark py-2 px-4 rounded-l ${
                                        display === "day"
                                            ? "bg-grey hover:bg-grey-dark text-white"
                                            : "bg-grey-lightest hover:bg-grey-light"
                                    }`}>
                                    <Trans>Day</Trans>
                                </button>
                                <button
                                    onClick={() => this.setState({ display: "week" })}
                                    className={`text-center cursor-pointer no-underline whitespace-no-wrap text-sm border text-grey-dark py-2 px-4 rounded-r ${
                                        display === "week"
                                            ? "bg-grey hover:bg-grey-dark text-white"
                                            : "bg-grey-lightest hover:bg-grey-light"
                                    }`}>
                                    <Trans>Week</Trans>
                                </button>
                            </div>

                            <div className="flex">
                                <select
                                    style={{ minWidth: 100 }}
                                    disabled={!users.length}
                                    value={user_id}
                                    onChange={e => this.setState({ user_id: e.target.value })}
                                    className="bg-grey-lighter text-grey-dark rounded border">
                                    {users.map(user => (
                                        <option key={user.id} value={user.id}>
                                            {user.given_names}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <LoadingWrapper isLoading={isFetchingStints}>
                        {display === "week" ? (
                            <WeekView
                                stints={stints}
                                start_of_week={moment
                                    .unix(current_date)
                                    .startOf("isoweek")
                                    .unix()}
                                current_date={current_date}
                            />
                        ) : (
                            <DayView
                                setState={this.setParentState}
                                stints={stints}
                                start_of_week={moment
                                    .unix(current_date)
                                    .startOf("isoweek")
                                    .unix()}
                                current_date={current_date}
                            />
                        )}
                    </LoadingWrapper>
                </div>

                <Modal isOpen={Boolean(stintId)} ariaHideApp={false} style={customModalStyles}>
                    <div className="flex flex-col w-full">
                        {stintId ? (
                            <StintDetail
                                id={stintId}
                                fetch={this.fetchStints}
                                setState={this.setParentState}
                            />
                        ) : null}
                    </div>
                </Modal>
            </div>
        );
    }
}

const DayView = ({ stints, current_date, start_of_week, setState }) => (
    <div className="bg-white shadow rounded p-2">
        <div className="flex items-center bg-grey-light rounded-t overflow-hidden">
            <div
                className={`timesheet-day-view-day ${
                    current_date === start_of_week ? "active" : ""
                }`}
                onClick={() => setState({ current_date: start_of_week })}>
                <span className="mb-1">{moment.unix(start_of_week).format("ddd")}</span>
                <span className="text-xs text-grey">
                    {stints
                        .filter(
                            s =>
                                moment
                                    .unix(s.start)
                                    .startOf("day")
                                    .unix() ===
                                moment
                                    .unix(start_of_week)
                                    .startOf("day")
                                    .unix()
                        )
                        .reduce((acc, stint) => {
                            return acc + stint.duration;
                        }, 0)
                        .toFixed(2)}
                </span>
            </div>
            <div
                className={`timesheet-day-view-day ${
                    current_date ===
                    moment
                        .unix(start_of_week)
                        .add(1, "days")
                        .unix()
                        ? "active"
                        : ""
                }`}
                onClick={() =>
                    setState({
                        current_date: moment
                            .unix(start_of_week)
                            .add(1, "days")
                            .unix()
                    })
                }>
                <span className="mb-1">
                    {moment
                        .unix(start_of_week)
                        .add(1, "days")
                        .format("ddd")}
                </span>
                <span className="text-xs text-grey">
                    {stints
                        .filter(
                            s =>
                                moment
                                    .unix(s.start)
                                    .startOf("day")
                                    .unix() ===
                                moment
                                    .unix(start_of_week)
                                    .add(1, "days")
                                    .startOf("day")
                                    .unix()
                        )
                        .reduce((acc, stint) => {
                            return acc + stint.duration;
                        }, 0)
                        .toFixed(2)}
                </span>
            </div>
            <div
                className={`timesheet-day-view-day ${
                    current_date ===
                    moment
                        .unix(start_of_week)
                        .add(2, "days")
                        .unix()
                        ? "active"
                        : ""
                }`}
                onClick={() =>
                    setState({
                        current_date: moment
                            .unix(start_of_week)
                            .add(2, "days")
                            .unix()
                    })
                }>
                <span className="mb-1">
                    {moment
                        .unix(start_of_week)
                        .add(2, "days")
                        .format("ddd")}
                </span>
                <span className="text-xs text-grey">
                    {stints
                        .filter(
                            s =>
                                moment
                                    .unix(s.start)
                                    .startOf("day")
                                    .unix() ===
                                moment
                                    .unix(start_of_week)
                                    .add(2, "days")
                                    .startOf("day")
                                    .unix()
                        )
                        .reduce((acc, stint) => {
                            return acc + stint.duration;
                        }, 0)
                        .toFixed(2)}
                </span>
            </div>
            <div
                className={`timesheet-day-view-day ${
                    current_date ===
                    moment
                        .unix(start_of_week)
                        .add(3, "days")
                        .unix()
                        ? "active"
                        : ""
                }`}
                onClick={() =>
                    setState({
                        current_date: moment
                            .unix(start_of_week)
                            .add(3, "days")
                            .unix()
                    })
                }>
                <span className="mb-1">
                    {moment
                        .unix(start_of_week)
                        .add(3, "days")
                        .format("ddd")}
                </span>
                <span className="text-xs text-grey">
                    {stints
                        .filter(
                            s =>
                                moment
                                    .unix(s.start)
                                    .startOf("day")
                                    .unix() ===
                                moment
                                    .unix(start_of_week)
                                    .add(3, "days")
                                    .startOf("day")
                                    .unix()
                        )
                        .reduce((acc, stint) => {
                            return acc + stint.duration;
                        }, 0)
                        .toFixed(2)}
                </span>
            </div>
            <div
                className={`timesheet-day-view-day ${
                    current_date ===
                    moment
                        .unix(start_of_week)
                        .add(4, "days")
                        .unix()
                        ? "active"
                        : ""
                }`}
                onClick={() =>
                    setState({
                        current_date: moment
                            .unix(start_of_week)
                            .add(4, "days")
                            .unix()
                    })
                }>
                <span className="mb-1">
                    {moment
                        .unix(start_of_week)
                        .add(4, "days")
                        .format("ddd")}
                </span>
                <span className="text-xs text-grey">
                    {stints
                        .filter(
                            s =>
                                moment
                                    .unix(s.start)
                                    .startOf("day")
                                    .unix() ===
                                moment
                                    .unix(start_of_week)
                                    .add(4, "days")
                                    .startOf("day")
                                    .unix()
                        )
                        .reduce((acc, stint) => {
                            return acc + stint.duration;
                        }, 0)
                        .toFixed(2)}
                </span>
            </div>
            <div
                className={`timesheet-day-view-day ${
                    current_date ===
                    moment
                        .unix(start_of_week)
                        .add(5, "days")
                        .unix()
                        ? "active"
                        : ""
                }`}
                onClick={() =>
                    setState({
                        current_date: moment
                            .unix(start_of_week)
                            .add(5, "days")
                            .unix()
                    })
                }>
                <span className="mb-1">
                    {moment
                        .unix(start_of_week)
                        .add(5, "days")
                        .format("ddd")}
                </span>
                <span className="text-xs text-grey">
                    {stints
                        .filter(
                            s =>
                                moment
                                    .unix(s.start)
                                    .startOf("day")
                                    .unix() ===
                                moment
                                    .unix(start_of_week)
                                    .add(5, "days")
                                    .startOf("day")
                                    .unix()
                        )
                        .reduce((acc, stint) => {
                            return acc + stint.duration;
                        }, 0)
                        .toFixed(2)}
                </span>
            </div>
            <div
                className={`timesheet-day-view-day ${
                    current_date ===
                    moment
                        .unix(start_of_week)
                        .add(6, "days")
                        .unix()
                        ? "active"
                        : ""
                }`}
                onClick={() =>
                    setState({
                        current_date: moment
                            .unix(start_of_week)
                            .add(6, "days")
                            .unix()
                    })
                }>
                <span className="mb-1">
                    {moment
                        .unix(start_of_week)
                        .add(6, "days")
                        .format("ddd")}
                </span>
                <span className="text-xs text-grey">
                    {stints
                        .filter(
                            s =>
                                moment
                                    .unix(s.start)
                                    .startOf("day")
                                    .unix() ===
                                moment
                                    .unix(start_of_week)
                                    .add(6, "days")
                                    .startOf("day")
                                    .unix()
                        )
                        .reduce((acc, stint) => {
                            return acc + stint.duration;
                        }, 0)
                        .toFixed(2)}
                </span>
            </div>
            <div
                style={{ flex: 2 }}
                className="justify-center items-center flex p-2 border-t border-b border-r">
                <span className="text-sm text-grey-darker">
                    <Trans>Total</Trans>{" "}
                    {stints
                        .reduce((acc, stint) => {
                            return acc + stint.duration;
                        }, 0)
                        .toFixed(2)}
                </span>
            </div>
        </div>

        <table className="w-full">
            <tbody>
                {stints.filter(
                    s =>
                        moment
                            .unix(s.start)
                            .startOf("day")
                            .unix() ===
                        moment
                            .unix(current_date)
                            .startOf("day")
                            .unix()
                ).length ? (
                    stints
                        .filter(
                            s =>
                                moment
                                    .unix(s.start)
                                    .startOf("day")
                                    .unix() ===
                                moment
                                    .unix(current_date)
                                    .startOf("day")
                                    .unix()
                        )
                        .map(stint => (
                            <tr key={stint.id} className="border-b">
                                <td className="p-2 text-sm">
                                    <span>{moment.unix(stint.start).format("HH:mm")}</span>
                                    <br />
                                    <span className="text-grey">
                                        {moment.unix(stint.end).format("HH:mm")}
                                    </span>
                                </td>
                                <td className="p-2 text-sm">
                                    {stint.project_name ? (
                                        <span className="font-normal mr-1">
                                            {stint.project_name}
                                        </span>
                                    ) : null}
                                    {stint.client_name ? <span>({stint.client_name})</span> : null}
                                    {stint.task_name ? (
                                        <span className="text-sm block">{stint.task_name}</span>
                                    ) : null}
                                </td>
                                <td className="p-2 text-right">
                                    {stint.end ? (
                                        stint.duration.toFixed(2)
                                    ) : (
                                        <Trans>In progress</Trans>
                                    )}
                                </td>
                                <td className="text-right">
                                    <div
                                        className="p-2 cursor-pointer hover:text-teal transition-color"
                                        onClick={() => setState({ stintId: stint.id })}>
                                        <Icon icon="edit" />
                                    </div>
                                </td>
                            </tr>
                        ))
                ) : (
                    <tr className="border-b">
                        <td className="text-sm p-2" colSpan={4}>
                            <Trans>No time recorded.</Trans>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
);

const WeekView = ({ stints, current_date, start_of_week }) => (
    <div className="bg-white shadow rounded">
        <div className="p-4">
            <table className="rounded overflow-hidden w-full">
                <thead>
                    <tr className="bg-grey-lighter border-b">
                        <th>&nbsp;</th>
                        {[0, 1, 2, 3, 4, 5, 6].map(i => (
                            <th className="p-2 font-light text-right" key={i}>
                                <span className="block text-sm">
                                    {moment
                                        .unix(current_date)
                                        .add(i, "days")
                                        .format("ddd")}
                                </span>
                                <span className="text-xs">
                                    {moment
                                        .unix(current_date)
                                        .add(i, "days")
                                        .format("DD MMM")}
                                </span>
                            </th>
                        ))}
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tfoot>
                    <tr className="bg-grey-lighter text-sm">
                        <td className="p-2">&nbsp;</td>
                        <td className="p-2">DAILY_TOTAL</td>
                        <td className="p-2">DAILY_TOTAL</td>
                        <td className="p-2">DAILY_TOTAL</td>
                        <td className="p-2">DAILY_TOTAL</td>
                        <td className="p-2">DAILY_TOTAL</td>
                        <td className="p-2">DAILY_TOTAL</td>
                        <td className="p-2">DAILY_TOTAL</td>
                        <td className="p-2">TOTAL</td>
                        <td className="p-2">&nbsp;</td>
                    </tr>
                </tfoot>
                <tbody />
            </table>
            {JSON.stringify(stints)}
        </div>
    </div>
);

class CalendarButton extends Component {
    render() {
        return (
            <div
                onClick={this.props.onClick}
                style={{ height: 32, width: 32 }}
                className="flex items-center justify-center bg-grey-lighter border cursor-pointer hover:bg-grey-light transition-bg rounded p-2 mr-2">
                <Icon size={14} icon="calendar" />
            </div>
        );
    }
}

export default Timesheet;
