import React, { Component } from "react";
// import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
// import ResourceIndex from "./ResourceIndex";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources } from "../api";
import LoadingWrapper from "../components/LoadingWrapper";
// import DeleteButton from "../components/DeleteButton";
// import history from "../components/history";
// import Icon from "../components/Icon";

class ProjectDetail extends Component {
    static contextType = ApiContext;
    state = {
        project: null,
        isLoading: false,
        error: null
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchProject();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.fetchProject();
        }
    }

    fetchProject = () => {
        this.safeSetState({ isLoading: true });
        this.context
            .callApi(() => fetchResources(`projects/${this.props.match.params.id}`))
            .then(({ data }) => {
                this.safeSetState({
                    project: data,
                    isLoading: false
                });
            })
            .catch(error => {
                this.safeSetState({ isLoading: false, error });
                if (typeof error === "object") {
                    return toast.error(<Trans>Failed to fetch project.</Trans>);
                }
                toast.error(error);
            });
    };

    render() {
        const { project, isLoading } = this.state;

        return (
            <div className="main-page-container">
                <div className="p-2 md:p-8">
                    <h1 className="page-header">
                        <Trans>Project Detail</Trans>
                    </h1>
                    <div className="">
                        <LoadingWrapper isLoading={isLoading}>
                            {project ? (
                                <>
                                    <div className="bg-white shadow rounded p-2 mb-4 flex justify-between items-end">
                                        <div>
                                            <h3 className="uppercase text-sm mb-1">
                                                {project.client_name}
                                            </h3>
                                            <h2 className="text-xl font-light">
                                                {project.project_name}
                                            </h2>
                                        </div>
                                        <Link
                                            to={`/projects/${project.id}/edit`}
                                            className="btn btn-primary">
                                            <Trans>Edit Project</Trans>
                                        </Link>
                                    </div>
                                    <div className="text-sm leading-normal mb-4">
                                        <div
                                            className="flex flex-col p-4 bg-white rounded shadow"
                                            style={{ maxWidth: 240 }}>
                                            <span className="text-sm font-light">
                                                <Trans>Total Hours</Trans>
                                            </span>
                                            <span className="text-xl mb-2">
                                                {project.total_hours.toFixed(2)}
                                            </span>

                                            <div className="flex justify-between">
                                                <div>
                                                    <Trans>Billable Hours</Trans>
                                                </div>
                                                <span>{project.billable_hours.toFixed(2)}</span>
                                            </div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <Trans>Non-Billable Hours</Trans>
                                                </div>
                                                <span>{project.non_billable_hours.toFixed(2)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </LoadingWrapper>

                        <div className="text-sm leading-normal p-4 bg-white rounded shadow whitespace-pre">
                            {JSON.stringify(this.state, null, "\t")}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ProjectDetail;
