import React, { Component } from "react";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
import LoadingWrapper from "../components/LoadingWrapper";
import Graph from "../components/Graph";
import Icon from "../components/Icon";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources } from "../api";

class CurrencyRates extends Component {
    static contextType = ApiContext;

    state = {
        code: "CNY",
        currency: null,
        currencies: [],
        data: null,
        year: new Date().getFullYear(),
        isLoading: false
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchRates();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.code !== this.state.code || prevState.year !== this.state.year) {
            this.fetchRates();
        }
    }

    fetchRates = () => {
        const { year, code } = this.state;
        this.safeSetState({ isLoading: true, error: null });
        this.context
            .callApi(() => fetchResources(`currency-rates?year=${year}&code=${code}`))
            .then(({ data }) => {
                this.safeSetState({
                    data: data.data,
                    currency: data.currency,
                    currencies: data.currencies,
                    isLoading: false
                });
            })
            .catch(error => {
                this.safeSetState({ isLoading: false, error });
                if (typeof error === "object") {
                    return toast.error(<Trans>Failed to fetch data.</Trans>);
                }
                toast.error(error);
            });
    };

    render() {
        const { isLoading, year, data, code, currencies } = this.state;
        return (
            <div className="main-page-container">
                <div className="p-2 md:p-8">
                    <div>
                        <h1 className="page-header">
                            <Trans>Currency Rates</Trans> ({code})
                        </h1>
                        <div className="flex-1 shadow rounded bg-white p-2">
                            <LoadingWrapper isLoading={isLoading}>
                                <div className="flex items-center mb-4 justify-between">
                                    <div>
                                        <span
                                            className="cursor-pointer hover:text-teal"
                                            onClick={() =>
                                                this.setState(prevState => ({
                                                    year: prevState.year - 1
                                                }))
                                            }>
                                            <Icon icon="left" />
                                        </span>
                                        <span className="mx-2 font-bold">{year}</span>
                                        <span
                                            className="cursor-pointer hover:text-teal"
                                            onClick={() =>
                                                this.setState(prevState => ({
                                                    year: prevState.year + 1
                                                }))
                                            }>
                                            <Icon icon="right" />
                                        </span>
                                    </div>

                                    <select
                                        value={code}
                                        onChange={e => this.setState({ code: e.target.value })}>
                                        {currencies.map((c, index) => (
                                            <option key={index} value={c}>
                                                {c}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {data ? <Graph precision={4} data={data} type="line" /> : null}
                            </LoadingWrapper>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CurrencyRates;
