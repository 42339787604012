import axios from "axios";
import history from "./components/history";

const http = () => {
    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        timeout: 60000,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("THYME_JWT")}`,
            "X-Localization": `${localStorage.getItem("THYME_LANG")}`
        }
    });

    instance.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            process.env.NODE_ENV === "development" &&
                console.warn(
                    "%cAPI Error",
                    "color: #f6993f; font-weight: bold",
                    error,
                    error.response
                );
            if (
                error.response &&
                error.response.status &&
                error.response.status === 500 &&
                error.response.data &&
                error.response.data.error
            ) {
                throw error.response.data.error;
            }

            if (error.response && error.response.status && error.response.status === 404) {
                throw error.response.statusText;
            }

            if (error.response && error.response.status && error.response.status === 422) {
                throw error.response.data;
            }

            if (error.response && error.response.status && error.response.status === 429) {
                throw error.response.data;
            }

            if (error.response && error.response.status && error.response.status === 403) {
                console.warn("%c403", "color: #f6993f; font-weight: bold", error.response);
                if (error.response.data["show-verify-email"]) {
                    localStorage.setItem("THYME_VERIFY_EMAIL", true);
                }

                if (
                    error.response.data &&
                    (error.response.data.error === "Token Expired." ||
                        error.response.data.error === "令牌已过期。")
                ) {
                    localStorage.removeItem("THYME_JWT");
                    return history.push("/login");
                }
                throw error.response.data;
            }

            if (error.response && error.response.status && error.response.status === 401) {
                const jwt = localStorage.getItem("THYME_JWT");

                if (!jwt) {
                    history.push("/login");

                    throw error.response.data;
                }

                return verifyJwt(jwt)
                    .then(({ data }) => {
                        localStorage.setItem("THYME_JWT", data.token);
                    })
                    .catch(error => {
                        localStorage.removeItem("THYME_JWT");
                        history.push("/login");
                    });
            }

            return Promise.reject(error);
        }
    );
    return instance;
};

/* AUTH */
export const verifyJwt = token => http().post("verify-jwt", { token });
export const verifyEmail = payload => http().post("verify-email", payload);
// export const refreshJwt = token => http().post("refresh-jwt", { token });
export const me = () => http().get("me");
export const login = payload => http().post("login", payload);
export const requestVerificationEmail = payload =>
    http().post("request-verification-email", payload);
export const sendPasswordResetEmail = email => http().post("forgot", { email });
export const resetPassword = payload => http().post("reset", payload);
export const register = payload => http().post("register", payload);
export const updateLocale = locale => http().post(`locale/${locale}`);

export const deleteResource = url => http().delete(url);
export const fetchResources = url => http().get(url);
export const updateResource = (url, payload = []) => http().patch(url, payload);
export const createResource = (url, payload = []) => http().post(url, payload);

export const paymentReminder = id => http().post(`payment-reminder/${id}`);

export const submitFeedback = payload => http().post("feedback", payload);
export const uploadScreenshot = payload => http().post("screenshot", payload);

/* User management */
// export const getStaff = () => http().get(`staffs`);
// export const getLocations = () => http().get(`locations`);
// export const getAttachments = () => http().get(`attachments`);
// export const getTrips = () => http().get(`trips`);
// export const getTeachers = () => http().get(`teachers`);
// export const getClients = () => http().get(`clients`);
// export const getParticipants = () => http().get(`participants`);
// export const getSchools = () => http().get(`schools`);

// export const getMyTrips = _ => http().get(`my-trips`);
export const getEvents = month => http().get(`my-trips?calendar&month=` + month);
export const getAllEvents = month => http().get(`trips?calendar&month=` + month);
export const updateTimesheet = payload => http().patch(`timesheets/verify`, payload);
export const getTimesheets = month => http().get(`timesheets?month=` + month);

export const getPaymentSummary = (tripId, participantId) =>
    http().get(`payment-form/${tripId}/${participantId}`);
export const getPaymentForm = (tripId, participantId) =>
    http().post(`payment-form/${tripId}/${participantId}`);

export const getSchool = id => http().get(`schools/${id}`);
export const updateSchool = (id, payload) => http().patch(`schools/${id}`, payload);
export const notifyTeachers = id => http().post(`notify/${id}`);
export const createSchool = payload => http().post(`schools`, payload);
