import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ApiProvider } from "./contexts/ApiContext";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";
import { name, version } from "../package.json";
import SentryErrorBoundary from "./components/SentryErrorBoundary";

process.env.NODE_ENV === "development" &&
    console.log(
        "%cBooting App",
        "color: #fff; font-weight: bold; background: #3490dc; padding: 2px 4px; border-radius: 2px;",
        `${name}@${version} [${process.env.NODE_ENV}]`
    );

if (process.env.NODE_ENV !== "development") {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        release: `${name}g@${version}`
    });
}

ReactDOM.render(
    <ApiProvider>
        <SentryErrorBoundary>
            <App />
        </SentryErrorBoundary>
    </ApiProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
