import React, { Component } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { i18n } from "../App";
import { Trans, t } from "@lingui/macro";
// import moment from "moment";
import history from "../components/history";
import DeleteButton from "../components/DeleteButton";
import ProjectTaskList from "../components/ProjectTaskList";
import LoadingWrapper from "../components/LoadingWrapper";
import { ApiContext } from "../contexts/ApiContext";
import { createResource, fetchResources, updateResource } from "../api";

const colors = ["indigo", "blue", "red", "pink", "orange", "teal", "green", "grey", "black"];

class ProjectCreate extends Component {
    static contextType = ApiContext;
    static propTypes = {
        match: PropTypes.object
    };
    state = {
        name: "",
        client_id: "",
        project_name: "",
        color: "",
        new_task_name: "",
        new_task_estimate: "",
        project_hourly_rate: "",
        project_fees: "",
        total_fees: "",
        total_hours: "",
        budget_resets_every_month: false,
        project_type: "TIME", // TIME|FIXED|NON_BILLABLE
        hourly_rate_type: "PROJECT_HOURLY", // NONE|PROJECT_HOURLY|PERSON_HOURLY|TASK_HOURLY
        budget_type: "TOTAL_HOURS", // NONE|TOTAL_FEES|FEES_PER_TASK|TOTAL_HOURS|HOURS_PER_TASK|HOURS_PER_PERSON
        code: "",
        notes: "",
        currency_id: "",
        currency_code: "",
        clients: [],
        tasks: [],
        isLoading: false,
        isSubmitting: false,
        error: null
    };

    componentDidMount() {
        this._isMounted = true;
        const {
            match: {
                params: { id }
            }
        } = this.props;
        this.fetchClients();
        if (id) {
            this.fetchProject();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    fetchProject = () => {
        const {
            match: {
                params: { id }
            }
        } = this.props;
        this.safeSetState({ isLoading: true });
        this.context
            .callApi(() => fetchResources("projects/" + id))
            .then(({ data }) => {
                this.safeSetState({
                    project_name: data.project_name || "",
                    code: data.code || "",
                    notes: data.notes || "",
                    currency_id: data.currency_id || "",
                    client_id: data.client_id,
                    currency_code: data.currency_code,
                    budget_type: data.budget_type,
                    tasks: data.tasks,
                    hourly_rate_type: data.hourly_rate_type,
                    project_hourly_rate: data.hourly_rate,
                    color: data.color,
                    total_fees: data.total_fees,
                    total_hours: data.total_hours,
                    project_fees: data.total_fees,
                    project_type: data.project_type,
                    isLoading: false
                });
            })
            .catch(error => {
                this.safeSetState({
                    isLoading: false,
                    error,
                    message:
                        error.status === 403 ? (
                            <Trans>You are not allowed to view this item.</Trans>
                        ) : (
                            ""
                        )
                });
                toast.error(<Trans>Failed to fetch project.</Trans>);
            });
    };

    handleDelete = () => {
        history.push("/projects");
        toast.success(<Trans>Project archived!</Trans>);
    };

    handleSubmit = e => {
        e.preventDefault();
        const {
            match: {
                params: { id }
            }
        } = this.props;
        if (!id) {
            return this.handleCreate();
        }
        const { callApi } = this.context;
        const {
            client_id,
            currency_id,
            project_name,
            project_type,
            budget_type,
            code,
            notes,
            color,
            tasks,
            hourly_rate_type,
            project_hourly_rate,
            total_fees,
            total_hours,
            project_fees
        } = this.state;
        this.safeSetState({ isSubmitting: true, error: null });
        callApi(() =>
            updateResource(`/projects/${id}`, {
                client_id,
                currency_id,
                project_name,
                project_type,
                budget_type,
                code,
                color,
                notes,
                tasks,
                hourly_rate_type,
                project_hourly_rate,
                total_fees,
                total_hours,
                project_fees
            })
        )
            .then(({ data }) => {
                this.safeSetState({
                    client_id: data.client_id,
                    currency_id: data.currency_id,
                    project_name: data.name,
                    project_type: data.project_type,
                    budget_type: data.budget_type,
                    code: data.code,
                    notes: data.notes,
                    color: data.color,
                    tasks: data.tasks,
                    hourly_rate_type: data.hourly_rate_type,
                    project_hourly_rate: data.hourly_rate,
                    total_fees: data.total_fees,
                    total_hours: data.total_hours,
                    project_fees: data.total_fees,
                    isLoading: false
                });
                toast.success(<Trans>Saved!</Trans>);
                this.safeSetState({ isSubmitting: false });
            })
            .catch(error => {
                this.safeSetState({ isSubmitting: false, error: error.errors });
                toast.error(<Trans>Failed to update.</Trans>);
            });
    };

    setParentState = object => {
        this.setState(object);
    };

    handleCreate = () => {
        const { callApi } = this.context;
        const {
            client_id,
            currency_id,
            project_name,
            project_type,
            budget_type,
            code,
            notes,
            color,
            tasks,
            hourly_rate_type,
            project_hourly_rate,
            total_fees,
            total_hours,
            project_fees
        } = this.state;
        this.safeSetState({ isSubmitting: true, error: null });
        callApi(() =>
            createResource(`/projects`, {
                client_id,
                project_name,
                project_type,
                budget_type,
                currency_id,
                code,
                color,
                notes,
                tasks,
                hourly_rate_type,
                project_hourly_rate,
                total_fees,
                total_hours,
                project_fees
            })
        )
            .then(res => {
                toast.success(<Trans>Saved!</Trans>);
                this.safeSetState({ isSubmitting: false });
                history.push(`/projects/${res.data.id}/edit`);
            })
            .catch(error => {
                console.warn("%cerror", "color: #f6993f; font-weight: bold", error);
                this.safeSetState({ isSubmitting: false, error: error.errors });
                let msg =
                    typeof error.message === "object" ? "Failed to create project" : error.message;
                toast.error(msg);
            });
    };

    generateProject = () => {
        this.safeSetState({
            name: "Halcrow",
            client_id: 1,
            project_name: "Manor Park",
            code: "Swindon",
            notes: "Wiltshire",
            currency_id: 32
        });
    };

    fetchClients = () => {
        this.safeSetState({ isFetchingClients: true });
        this.context
            .callApi(() => fetchResources("clients?simple"))
            .then(({ data }) => {
                this.safeSetState({
                    clients: data,
                    isFetchingClients: false
                });
            })
            .catch(error => {
                this.safeSetState({
                    isFetchingClients: false,
                    error,
                    message: error.status === 403 ? <Trans>Not allowed.</Trans> : ""
                });
                toast.error(<Trans>Failed to fetch clients.</Trans>);
            });
    };

    addNewTask = () => {
        if (!this.state.new_task_name || !this.state.new_task_estimate) {
            return toast.error(<Trans>A task name and estimate is required.</Trans>);
        }
        if (this.state.new_task_estimate > 16) {
            return toast.error(<Trans>A single task should be no longer than 16 hours.</Trans>);
        }
        if (this.state.new_task_estimate < 0) {
            return toast.error(<Trans>A single task scan't have a negative estimate.</Trans>);
        }

        this.setState(prevState => ({
            tasks: prevState.tasks.concat({
                id: null,
                name: prevState.new_task_name,
                estimate: prevState.new_task_estimate,
                billable: false,
                budget: "",
                hourly_rate: ""
            }),
            new_task_name: "",
            new_task_estimate: ""
        }));
    };

    removeTask = index => {
        this.setState(prevState => ({ tasks: prevState.tasks.filter((t, i) => i !== index) }));
    };

    updateTask = (task, index) => {
        if (task.estimate > 16) {
            return toast.error(<Trans>A single task should be no longer than 16 hours.</Trans>);
        }

        if (this.state.new_task_estimate < 0) {
            return toast.error(<Trans>A single task scan't have a negative estimate.</Trans>);
        }

        this.setState(prevState => ({
            tasks: prevState.tasks.map((t, i) => {
                if (i === index) {
                    return task;
                }
                return t;
            })
        }));
    };

    render() {
        const {
            clients,
            client_id,
            currency_code,
            project_name,
            budget_type,
            code,
            notes,
            new_task_name,
            new_task_estimate,
            tasks,
            hourly_rate_type,
            project_hourly_rate,
            total_fees,
            total_hours,
            project_fees,
            project_type,
            error,
            isSubmitting,
            isLoading
        } = this.state;
        const {
            match: {
                params: { id }
            }
        } = this.props;

        let tableColCount = 3;
        if (hourly_rate_type === "TASK_HOURLY") {
            tableColCount++;
        }
        if (budget_type === "FEES_PER_TASK") {
            tableColCount++;
        }
        if (project_type === "NON_BILLABLE") {
            tableColCount = 2;
        }

        return (
            <div className="main-page-container">
                <div className="p-2 md:p-8">
                    <div>
                        <h1 className="page-header">
                            {id ? <Trans>Edit Project</Trans> : <Trans>Create Project</Trans>}
                        </h1>
                        <LoadingWrapper isLoading={isLoading}>
                            <div className="form-container">
                                <form method="POST" onSubmit={this.handleSubmit}>
                                    <div className="form-section-header bg-grey-lighter">
                                        <Trans>Basic Info</Trans>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Client</Trans>
                                        </label>
                                        <div className="w-full">
                                            <select
                                                value={client_id}
                                                disabled={isSubmitting || !clients.length}
                                                className="form-input w-full"
                                                onChange={e =>
                                                    this.setState({
                                                        client_id: e.target.value,
                                                        currency_code: e.target[
                                                            e.target.selectedIndex
                                                        ].getAttribute("data-currencycode"),
                                                        currency_id: e.target[
                                                            e.target.selectedIndex
                                                        ].getAttribute("data-currencyid")
                                                    })
                                                }>
                                                <option>-- {i18n._(t`Select Client`)} --</option>
                                                {clients.map(client => (
                                                    <option
                                                        key={client.id}
                                                        data-currencycode={client.currency_code}
                                                        data-currencyid={client.currency_id}
                                                        value={client.id}>
                                                        {client.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {error && error.client_id && (
                                                <span className="text-red text-xs">
                                                    {error.client_id}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Project Name</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="text"
                                                value={project_name}
                                                disabled={isSubmitting}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        project_name: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.project_name && (
                                                <span className="text-red text-xs">
                                                    {error.project_name}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Project Code</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="text"
                                                value={code}
                                                disabled={isSubmitting}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        code: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.code && (
                                                <span className="text-red text-xs">
                                                    {error.code}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Project Color</Trans>
                                        </label>
                                        <div className="w-full flex flex-wrap">
                                            {colors.map(color => (
                                                <div
                                                    onClick={() => this.setState({ color })}
                                                    key={color}
                                                    className={`${
                                                        this.state.color === color
                                                            ? "border-" + color
                                                            : "border-white"
                                                    } p-1 mr-2 rounded cursor-pointer border`}>
                                                    <span
                                                        style={{ width: 20, height: 20 }}
                                                        className={`block rounded bg-${color} transition-bg hover:bg-${color}-dark`}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Notes</Trans>
                                        </label>
                                        <div className="w-full">
                                            <textarea
                                                rows="5"
                                                className={`${
                                                    error && error.notes ? "border-red" : ""
                                                } form-input w-full`}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        notes: e.target.value
                                                    })
                                                }
                                                value={notes || ""}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Project Type</Trans>
                                        </label>
                                        <div className="w-full">
                                            <div className="flex justify-between mb-2">
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        this.setState({ project_type: "TIME" })
                                                    }
                                                    className={`${
                                                        project_type === "TIME" ? "active" : ""
                                                    } btn-outline`}>
                                                    <Trans>Time</Trans>
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        this.setState({ project_type: "FIXED" })
                                                    }
                                                    className={`${
                                                        project_type === "FIXED" ? "active" : ""
                                                    } btn-outline mx-2`}>
                                                    <Trans>Fixed Fee</Trans>
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        this.setState({
                                                            project_type: "NON_BILLABLE",
                                                            budget_type: "NONE"
                                                        })
                                                    }
                                                    className={`${
                                                        project_type === "NON_BILLABLE"
                                                            ? "active"
                                                            : ""
                                                    } btn-outline`}>
                                                    <Trans>Non-Billable</Trans>
                                                </button>
                                            </div>

                                            <div className="p-4 rounded bg-grey-lighter border">
                                                <div>
                                                    {project_type === "FIXED" ? (
                                                        <div className="mb-4">
                                                            <label className="text-xs text-grey-dark block mb-1">
                                                                <Trans>Project Fees</Trans>
                                                            </label>
                                                            <div className="flex">
                                                                <div className="flex items-center">
                                                                    <input
                                                                        value={project_fees}
                                                                        onChange={e =>
                                                                            this.setState({
                                                                                project_fees:
                                                                                    e.target.value
                                                                            })
                                                                        }
                                                                        disabled={
                                                                            budget_type ===
                                                                            "FEES_PER_TASK"
                                                                        }
                                                                        type="text"
                                                                        className="form-input mr-2"
                                                                    />
                                                                    <span>{currency_code}</span>
                                                                    {budget_type ===
                                                                    "FEES_PER_TASK" ? (
                                                                        <span className="p-1 rounded bg-orange text-white ml-2 text-xs whitespace-no-wrap">
                                                                            <Trans>
                                                                                Total budget will be
                                                                                used as fee.
                                                                            </Trans>
                                                                        </span>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null}

                                                    {project_type !== "NON_BILLABLE" ? (
                                                        <div className="mb-4">
                                                            <label className="text-xs text-grey-dark block mb-1">
                                                                <Trans>Hourly Rates</Trans>
                                                            </label>
                                                            <div className="flex items-center">
                                                                <select
                                                                    value={hourly_rate_type}
                                                                    className="w-full form-input"
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            hourly_rate_type:
                                                                                e.target.value
                                                                        })
                                                                    }>
                                                                    <option value="NONE">
                                                                        No Hourly Rate
                                                                    </option>
                                                                    <option value="" disabled>
                                                                        ---------
                                                                    </option>
                                                                    <option value="PROJECT_HOURLY">
                                                                        Project Hourly Rate
                                                                    </option>
                                                                    <option
                                                                        disabled
                                                                        value="PERSON_HOURLY">
                                                                        Person Hourly Rate
                                                                    </option>
                                                                    <option value="TASK_HOURLY">
                                                                        Task Hourly Rate
                                                                    </option>
                                                                </select>
                                                                {hourly_rate_type ===
                                                                "PROJECT_HOURLY" ? (
                                                                    <div className="ml-2 flex items-center">
                                                                        <input
                                                                            value={
                                                                                project_hourly_rate
                                                                            }
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    project_hourly_rate:
                                                                                        e.target
                                                                                            .value
                                                                                })
                                                                            }
                                                                            type="text"
                                                                            className="form-input mr-2"
                                                                        />
                                                                        <span>{currency_code}</span>
                                                                    </div>
                                                                ) : null}
                                                                {hourly_rate_type ===
                                                                "TASK_HOURLY" ? (
                                                                    <span className="p-1 rounded bg-orange text-white ml-2 text-xs whitespace-no-wrap">
                                                                        <Trans>
                                                                            Set the task rates
                                                                            below.
                                                                        </Trans>
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    ) : null}

                                                    <div className="mb-4">
                                                        <label className="text-xs text-grey-dark block mb-1">
                                                            <Trans>Budget</Trans>
                                                        </label>
                                                        <div className="flex items-center">
                                                            <select
                                                                value={budget_type}
                                                                className="w-full form-input"
                                                                onChange={e =>
                                                                    this.setState({
                                                                        budget_type: e.target.value
                                                                    })
                                                                }>
                                                                <option value="NONE">
                                                                    No Budget
                                                                </option>
                                                                {project_type !== "NON_BILLABLE" ? (
                                                                    <>
                                                                        <option value="" disabled>
                                                                            ---------
                                                                        </option>
                                                                        <option value="TOTAL_FEES">
                                                                            Total Project Fees
                                                                        </option>
                                                                        <option value="FEES_PER_TASK">
                                                                            Fees per Task
                                                                        </option>
                                                                    </>
                                                                ) : null}
                                                                <option value="" disabled>
                                                                    ---------
                                                                </option>
                                                                <option value="TOTAL_HOURS">
                                                                    Total Project Hours
                                                                </option>
                                                                <option
                                                                    disabled
                                                                    value="HOURS_PER_TASK">
                                                                    Hours per Task
                                                                </option>
                                                                <option
                                                                    value="HOURS_PER_PERSON"
                                                                    disabled>
                                                                    Hours per Person
                                                                </option>
                                                            </select>

                                                            {budget_type === "TOTAL_HOURS" ? (
                                                                <div className="ml-2 flex items-center">
                                                                    <input
                                                                        value={total_hours}
                                                                        onChange={e =>
                                                                            this.setState({
                                                                                total_hours:
                                                                                    e.target.value
                                                                            })
                                                                        }
                                                                        type="text"
                                                                        className="form-input mr-2"
                                                                    />
                                                                    <span>hours</span>
                                                                </div>
                                                            ) : null}
                                                            {budget_type === "TOTAL_FEES" ? (
                                                                <div className="ml-2 flex items-center">
                                                                    {project_type === "FIXED" ? (
                                                                        <span className="p-1 rounded bg-orange text-white text-xs whitespace-no-wrap">
                                                                            <Trans>
                                                                                Project fees will be
                                                                                used.
                                                                            </Trans>
                                                                        </span>
                                                                    ) : (
                                                                        <>
                                                                            <input
                                                                                value={total_fees}
                                                                                onChange={e =>
                                                                                    this.setState({
                                                                                        total_fees:
                                                                                            e.target
                                                                                                .value
                                                                                    })
                                                                                }
                                                                                type="text"
                                                                                className="form-input mr-2"
                                                                            />
                                                                            <span>
                                                                                {currency_code}
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            ) : null}
                                                            {budget_type === "FEES_PER_TASK" ? (
                                                                <span className="p-1 rounded bg-orange text-white ml-2 text-xs whitespace-no-wrap">
                                                                    <Trans>
                                                                        Set the task rates below.
                                                                    </Trans>
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-section-header bg-grey-lighter">
                                        <Trans>Tasks</Trans>
                                    </div>

                                    <div className="m-2 rounded border">
                                        <ProjectTaskList
                                            setState={this.setParentState}
                                            tasks={tasks}
                                            project_type={project_type}
                                            removeTask={this.removeTask}
                                            budget_type={budget_type}
                                            updateTask={this.updateTask}
                                            currency_code={currency_code}
                                            hourly_rate_type={hourly_rate_type}
                                            tableColCount={tableColCount}
                                            addNewTask={this.addNewTask}
                                            new_task_name={new_task_name}
                                            new_task_estimate={new_task_estimate}
                                        />
                                    </div>

                                    <div className="form-actions">
                                        {id ? (
                                            <DeleteButton
                                                action="archive"
                                                url={`projects/${id}/archive`}
                                                onDelete={this.handleDelete}>
                                                <button type="button" className="btn btn-solid-red">
                                                    <Trans>Archive</Trans>
                                                </button>
                                            </DeleteButton>
                                        ) : (
                                            <Link className="btn btn-solid-red" to={`/projects`}>
                                                <Trans>Cancel</Trans>
                                            </Link>
                                        )}
                                        {process.env.NODE_ENV === "development" && (
                                            <button
                                                type="button"
                                                className="btn"
                                                onClick={this.generateProject}>
                                                Generate
                                            </button>
                                        )}
                                        <button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="btn btn-solid-blue">
                                            {isSubmitting ? (
                                                <Trans>Saving...</Trans>
                                            ) : (
                                                <Trans>Save</Trans>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </LoadingWrapper>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectCreate;
