import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import moment from "moment";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources, createResource, updateResource } from "../api";
import LoadingWrapper from "../components/LoadingWrapper";
import Icon from "../components/Icon";
import StintDetail from "../components/StintDetail";

class MiniTimer extends Component {
    static contextType = ApiContext;
    state = {
        name: "",
        start: null,
        startFormatted: null,
        id: null,
        recentStints: [],
        showRecents: false,
        stintId: null,
        isLoading: false,
        isSubmitting: false,
        isFetchingRecentStints: false
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchCurrentStint();
        document.addEventListener("keydown", this.checkKeyPress);
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.removeEventListener("keydown", this.checkKeyPress);
        this.interval = null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showRecents !== this.state.showRecents && this.state.showRecents) {
            this.fetchRecentStints();
        }
    }

    checkKeyPress = e => {
        if (e.keyCode === 13 && e.target.id === "mini-timer") {
            this.handleSubmit();
        }
    };

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    startTimer = () => {
        this.interval = setInterval(() => {
            this.safeSetState({ startFormatted: this.timer() });
            document.title = `${this.timer()}`;
        }, 1000);
    };

    handleSubmit = () => {
        const { name } = this.state;

        if (!name) {
            return toast.error(<Trans>A name is required.</Trans>);
        }

        this.safeSetState({ isSubmitting: true });
        this.context
            .callApi(() => createResource("stints", { name }))
            .then(({ data }) => {
                this.safeSetState(
                    {
                        id: data.id,
                        start: data.start,
                        startFormatted: this.timer(),
                        name: data.name,
                        showRecents: true,
                        isSubmitting: false,
                        stintId: data.id
                    },
                    () => {
                        if (data.start) {
                            this.startTimer();
                        }
                    }
                );
            })
            .catch(error => {
                this.safeSetState({ isSubmitting: false, error });
                if (typeof error === "object") {
                    return toast.error(<Trans>Failed to create timer.</Trans>);
                }
                toast.error(error);
            });
    };

    handleContinue = id => {
        this.safeSetState({ isSubmitting: true });
        this.context
            .callApi(() => createResource(`stints/${id}/continue`))
            .then(({ data }) => {
                this.safeSetState(
                    {
                        id: data.id,
                        start: data.start,
                        startFormatted: this.timer(),
                        name: data.name,
                        isSubmitting: false,
                        stintId: data.id
                    },
                    () => {
                        if (data.start) {
                            this.startTimer();
                        }
                    }
                );
            })
            .catch(error => {
                this.safeSetState({ isSubmitting: false, error });
                if (typeof error === "object") {
                    return toast.error(<Trans>Failed to continue timer.</Trans>);
                }
                toast.error(error);
            });
    };

    handleStop = () => {
        const { id } = this.state;
        this.safeSetState({ isSubmitting: true });
        this.context
            .callApi(() => updateResource(`stints/${id}/stop`))
            .then(() => {
                this.interval = null;
                this.safeSetState({
                    id: null,
                    start: "",
                    startFormatted: "",
                    name: "",
                    isSubmitting: false
                });
                document.title = "Thyme Tracker";
            })
            .catch(error => {
                this.safeSetState({ isSubmitting: false, error });
                if (typeof error === "object") {
                    return toast.error(<Trans>Failed to stop timer.</Trans>);
                }
                toast.error(error);
            });
    };

    fetchCurrentStint = () => {
        this.safeSetState({ isLoading: true });
        this.context
            .callApi(() => fetchResources("current-stint"))
            .then(({ data }) => {
                this.safeSetState(
                    {
                        id: data.id,
                        start: data.start,
                        startFormatted: this.timer(),
                        name: data.name,
                        isLoading: false
                    },
                    () => {
                        if (data.start) {
                            this.startTimer();
                        }
                    }
                );
            })
            .catch(error => {
                this.safeSetState({ isLoading: false, error });
                if (typeof error === "object") {
                    return toast.error(<Trans>Failed to fetch timer.</Trans>);
                }
                toast.error(error);
            });
    };

    fetchRecentStints = () => {
        this.safeSetState({ isFetchingRecentStints: true });
        this.context
            .callApi(() => fetchResources("recent-stints"))
            .then(({ data }) => {
                this.safeSetState(
                    {
                        recentStints: data,
                        isFetchingRecentStints: false
                    },
                    () => {
                        if (data.start) {
                            this.startTimer();
                        }
                    }
                );
            })
            .catch(error => {
                this.safeSetState({ isFetchingRecentStints: false, error });
                if (typeof error === "object") {
                    return toast.error(<Trans>Failed to fetch recent stints.</Trans>);
                }
                toast.error(error);
            });
    };

    timer = () => {
        const { start } = this.state;

        if (!start) {
            return "";
        }
        const diffTime = moment().diff(moment.unix(start));
        const duration = moment.duration(diffTime);
        const hrs = duration.hours(),
            mins = duration.minutes(),
            secs = duration.seconds();

        return `${hrs < 10 ? "0" + hrs : hrs}:${mins < 10 ? "0" + mins : mins}:${
            secs < 10 ? "0" + secs : secs
        }`;
    };

    convertSecondsToTimeString = seconds => {
        if (!seconds) {
            return "";
        }

        const duration = moment.duration(seconds * 1000);
        const hrs = duration.hours(),
            mins = duration.minutes(),
            secs = duration.seconds();

        return `${hrs < 10 ? "0" + hrs : hrs}:${mins < 10 ? "0" + mins : mins}:${
            secs < 10 ? "0" + secs : secs
        }`;
    };

    setParentState = state => {
        this.safeSetState(state);
    };

    render() {
        const {
            start,
            name,
            isLoading,
            isSubmitting,
            startFormatted,
            showRecents,
            stintId,
            isFetchingRecentStints,
            recentStints
        } = this.state;
        return (
            <div className="flex items-start justify-center flex-col ml-auto">
                <LoadingWrapper isLoading={isLoading}>
                    <div className="flex items-center">
                        <input
                            type="text"
                            id="mini-timer"
                            className="p-2 block text-sm flex-1 mr-2 text-grey-dark rounded"
                            value={name}
                            disabled={isSubmitting || start}
                            placeholder="What's up?"
                            onChange={e => this.setState({ name: e.target.value })}
                        />
                        {start ? (
                            <button
                                onClick={this.handleStop}
                                disabled={isSubmitting}
                                style={{ minWidth: 80, height: 34 }}
                                className="block p-2 bg-red hover:bg-red-dark rounded text-white whitespace-no-wrap text-xs">
                                {startFormatted}
                            </button>
                        ) : (
                            <button
                                onClick={this.handleSubmit}
                                disabled={isSubmitting}
                                style={{ minWidth: 80, height: 34 }}
                                className="block p-2 bg-teal hover:bg-teal-dark rounded text-white whitespace-no-wrap text-xs">
                                <Trans>Start</Trans>
                            </button>
                        )}
                        <span
                            className="p-2 hover:text-teal cursor-pointer transition-color"
                            onClick={() =>
                                this.setState(prevState => ({
                                    showRecents: !prevState.showRecents
                                }))
                            }>
                            <Icon icon="down" />
                        </span>
                    </div>
                </LoadingWrapper>

                {showRecents ? (
                    <div
                        className="flex fixed pin-x pin-b md:pin-none md:pin-r md:absolute items-start justify-start flex-col rounded-b shadow p-2 bg-grey-lightest w-full md:max-w-xs bg-white"
                        style={{ top: 50, minWidth: 140 }}>
                        {stintId ? (
                            <StintDetail
                                fetch={this.fetchRecentStints}
                                fetchCurrent={this.fetchCurrentStint}
                                setState={this.setParentState}
                                id={stintId}
                            />
                        ) : (
                            <LoadingWrapper isLoading={isFetchingRecentStints}>
                                <div
                                    className="overflow-y-auto w-full custom-scroll"
                                    style={{ maxHeight: 500 }}>
                                    {!recentStints.length ? <div>No recent stints</div> : null}

                                    {recentStints.map((date, index) => (
                                        <div
                                            key={index}
                                            className={`${
                                                index === recentStints.length - 1 ? "" : "mb-2"
                                            } rounded border`}>
                                            <div className="p-4 border-b text-xs text-grey-darkest bg-grey-lighter flex justify-between justify-center">
                                                <span>
                                                    {moment.unix(date.date).calendar(null, {
                                                        sameDay: "[Today]",
                                                        nextDay: "[Tomorrow]",
                                                        nextWeek: "dddd",
                                                        lastDay: "[Yesterday]",
                                                        lastWeek: "[Last] dddd",
                                                        sameElse: "ddd, Do MMM"
                                                    })}
                                                </span>
                                                <span>
                                                    {this.convertSecondsToTimeString(
                                                        date.daily_total
                                                    )}
                                                </span>
                                            </div>
                                            {date.data.map((stint, index) => (
                                                <div
                                                    key={stint.id}
                                                    className={`${
                                                        index === date.data.length - 1
                                                            ? ""
                                                            : "border-b"
                                                    } text-sm bg-white text-grey-darkest w-full flex justify-between items-center`}>
                                                    <div
                                                        onClick={() =>
                                                            this.setState({ stintId: stint.id })
                                                        }
                                                        className="flex flex-1 items-center justify-center  cursor-pointer hover:text-teal truncate transition-color">
                                                        <span className="flex-1 truncate p-4">
                                                            <div className="mb-1 truncate">
                                                                {stint.name}
                                                            </div>
                                                            {stint.project ? (
                                                                <div
                                                                    className={`text-xs mb-1 text-${
                                                                        stint.color
                                                                    }`}>
                                                                    {stint.project}
                                                                </div>
                                                            ) : null}
                                                            {stint.task ? (
                                                                <div className="text-xs text-grey-dark">
                                                                    {stint.task}
                                                                </div>
                                                            ) : null}
                                                        </span>
                                                        <span className="p-4">
                                                            {this.convertSecondsToTimeString(
                                                                stint.duration
                                                            )}
                                                        </span>
                                                    </div>
                                                    <span
                                                        onClick={() =>
                                                            this.handleContinue(stint.id)
                                                        }
                                                        className="p-4 flex items-center justify-center cursor-pointer text-grey hover:text-teal transition-color">
                                                        <Icon size={20} icon="play" />
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </LoadingWrapper>
                        )}
                    </div>
                ) : null}
            </div>
        );
    }
}

export default MiniTimer;
