import React, { Component } from "react";
import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
// import { i18n } from "../App";
import { Trans } from "@lingui/macro";
// import moment from "moment";
import history from "../components/history";
// import DeleteButton from "../components/DeleteButton";
import LoadingWrapper from "../components/LoadingWrapper";
import { ApiContext } from "../contexts/ApiContext";
import { createResource, fetchResources, updateResource } from "../api";

class ClientCreate extends Component {
    static contextType = ApiContext;
    state = {
        name: "",
        address_line_1: "",
        address_line_2: "",
        address_line_3: "",
        currency_id: "",
        currencies: [],
        isLoading: false,
        isSubmitting: false,
        error: null
    };

    componentDidMount() {
        this._isMounted = true;
        const {
            match: {
                params: { id }
            }
        } = this.props;
        this.fetchCurrencies();
        if (id) {
            this.fetchClient();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    fetchClient = () => {
        const {
            match: {
                params: { id }
            }
        } = this.props;
        this.safeSetState({ isLoading: true });
        this.context
            .callApi(() => fetchResources("clients/" + id))
            .then(({ data }) => {
                this.safeSetState({
                    name: data.name,
                    address_line_1: data.address_line_1 || "",
                    address_line_2: data.address_line_2 || "",
                    address_line_3: data.address_line_3 || "",
                    currency_id: data.currency_id || "",
                    isLoading: false
                });
            })
            .catch(error => {
                this.safeSetState({
                    isLoading: false,
                    error,
                    message:
                        error.status === 403 ? (
                            <Trans>You are not allowed to view this item.</Trans>
                        ) : (
                            ""
                        )
                });
                toast.error(<Trans>Failed to fetch item.</Trans>);
            });
    };

    handleDelete = () => {
        history.push("/clients");
        toast.success(<Trans>Client archived!</Trans>);
    };

    handleSubmit = e => {
        e.preventDefault();
        const {
            match: {
                params: { id }
            }
        } = this.props;
        if (!id) {
            return this.handleCreate();
        }
        const { callApi } = this.context;
        const { name, address_line_1, address_line_2, address_line_3, currency_id } = this.state;
        this.safeSetState({ isSubmitting: true, error: null });
        callApi(() =>
            updateResource(`/clients/${id}`, {
                name,
                address_line_1,
                address_line_2,
                address_line_3,
                currency_id
            })
        )
            .then(({ data }) => {
                this.safeSetState({
                    name: data.name,
                    address_line_1: data.address_line_1 || "",
                    address_line_2: data.address_line_2 || "",
                    address_line_3: data.address_line_3 || "",
                    currency_id: data.currency_id || "",
                    isLoading: false
                });
                toast.success(<Trans>Saved!</Trans>);
                this.safeSetState({ isSubmitting: false });
            })
            .catch(error => {
                this.safeSetState({ isSubmitting: false, error: error.errors });
                toast.error(<Trans>Failed to update.</Trans>);
            });
    };

    handleCreate = _ => {
        const { callApi } = this.context;
        const { name, address_line_1, address_line_2, address_line_3, currency_id } = this.state;
        this.safeSetState({ isSubmitting: true, error: null });
        callApi(() =>
            createResource(`/clients`, {
                name,
                address_line_1,
                address_line_2,
                address_line_3,
                currency_id
            })
        )
            .then(res => {
                toast.success(<Trans>Saved!</Trans>);
                this.safeSetState({ isSubmitting: false });
                history.push(`/clients/${res.data.id}/edit`);
            })
            .catch(error => {
                console.warn("%cerror", "color: #f6993f; font-weight: bold", error);
                this.safeSetState({ isSubmitting: false, error: error.errors });
                let msg =
                    typeof error.message === "object" ? "Failed to create client" : error.message;
                toast.error(msg);
            });
    };

    generateClient = () => {
        this.safeSetState({
            name: "Halcrow",
            address_line_1: "Manor Park",
            address_line_2: "Swindon",
            address_line_3: "Wiltshire",
            currency_id: 32
        });
    };

    fetchCurrencies = () => {
        this.safeSetState({ isFetchingCurrencies: true });
        this.context
            .callApi(() => fetchResources("currencies"))
            .then(({ data }) => {
                this.safeSetState({
                    currencies: data,
                    isFetchingCurrencies: false
                });
            })
            .catch(error => {
                this.safeSetState({
                    isFetchingCurrencies: false,
                    error,
                    message: error.status === 403 ? <Trans>Not allowed.</Trans> : ""
                });
                toast.error(<Trans>Failed to fetch currencies.</Trans>);
            });
    };

    render() {
        const {
            // name,
            // address_line_1,
            // address_line_2,
            // address_line_3,
            // currency_id,
            // currencies,
            // error,
            // isSubmitting,
            isLoading
        } = this.state;
        // const {
        //     match: {
        //         params: { id }
        //     }
        // } = this.props;
        // const {
        //     user: { locale },
        //     user
        // } = this.context;

        return (
            <div className="main-page-container">
                <div className="p-2 md:p-8">
                    <div>
                        <h1 className="page-header">
                            <Trans>Client Details</Trans>
                        </h1>
                        <LoadingWrapper isLoading={isLoading}>
                            <div className="form-container">
                                <p>TODO</p>
                                {/*                                 <form method="POST" onSubmit={this.handleSubmit}> */}
                                {/*                                     <div className="form-section-header bg-grey-lighter"> */}
                                {/*                                         <Trans>Basic Info</Trans> */}
                                {/*                                     </div> */}
                                {/*  */}
                                {/*                                     <div className="form-input-group"> */}
                                {/*                                         <label className="form-label"> */}
                                {/*                                             <Trans>Company Name</Trans> */}
                                {/*                                         </label> */}
                                {/*                                         <div className="w-full"> */}
                                {/*                                             <input */}
                                {/*                                                 className="form-input w-full" */}
                                {/*                                                 type="text" */}
                                {/*                                                 value={name} */}
                                {/*                                                 disabled={isSubmitting} */}
                                {/*                                                 onChange={e => */}
                                {/*                                                     this.safeSetState({ */}
                                {/*                                                         name: e.target.value */}
                                {/*                                                     }) */}
                                {/*                                                 } */}
                                {/*                                             /> */}
                                {/*                                             {error && error.name && ( */}
                                {/*                                                 <span className="text-red text-xs"> */}
                                {/*                                                     {error.name} */}
                                {/*                                                 </span> */}
                                {/*                                             )} */}
                                {/*                                         </div> */}
                                {/*                                     </div> */}
                                {/*  */}
                                {/*                                     <div className="form-input-group"> */}
                                {/*                                         <label className="form-label leading-normal"> */}
                                {/*                                             <Trans>Billing Currency</Trans> */}
                                {/*                                         </label> */}
                                {/*                                         <div className="w-full"> */}
                                {/*                                             <select */}
                                {/*                                                 value={currency_id} */}
                                {/*                                                 disabled={isSubmitting} */}
                                {/*                                                 className="form-input w-full" */}
                                {/*                                                 onChange={e => */}
                                {/*                                                     this.setState({ currency_id: e.target.value }) */}
                                {/*                                                 }> */}
                                {/*                                                 <option>-- {i18n._(t`Select Currency`)} --</option> */}
                                {/*                                                 {currencies.map(currency => ( */}
                                {/*                                                     <option key={currency.id} value={currency.id}> */}
                                {/*                                                         {currency.name} */}
                                {/*                                                     </option> */}
                                {/*                                                 ))} */}
                                {/*                                             </select> */}
                                {/*                                             {error && error.currency_id && ( */}
                                {/*                                                 <span className="text-red text-xs"> */}
                                {/*                                                     {error.currency_id} */}
                                {/*                                                 </span> */}
                                {/*                                             )} */}
                                {/*                                         </div> */}
                                {/*                                     </div> */}
                                {/*  */}
                                {/*                                     <div className="form-input-group"> */}
                                {/*                                         <label className="form-label"> */}
                                {/*                                             <Trans>Address Line 1</Trans> */}
                                {/*                                         </label> */}
                                {/*                                         <div className="w-full"> */}
                                {/*                                             <input */}
                                {/*                                                 className="form-input w-full" */}
                                {/*                                                 type="text" */}
                                {/*                                                 value={address_line_1} */}
                                {/*                                                 disabled={isSubmitting} */}
                                {/*                                                 onChange={e => */}
                                {/*                                                     this.safeSetState({ */}
                                {/*                                                         address_line_1: e.target.value */}
                                {/*                                                     }) */}
                                {/*                                                 } */}
                                {/*                                             /> */}
                                {/*                                             {error && error.address_line_1 && ( */}
                                {/*                                                 <span className="text-red text-xs"> */}
                                {/*                                                     {error.address_line_1} */}
                                {/*                                                 </span> */}
                                {/*                                             )} */}
                                {/*                                         </div> */}
                                {/*                                     </div> */}
                                {/*  */}
                                {/*                                     <div className="form-input-group"> */}
                                {/*                                         <label className="form-label"> */}
                                {/*                                             <Trans>Address Line 2</Trans> */}
                                {/*                                         </label> */}
                                {/*                                         <div className="w-full"> */}
                                {/*                                             <input */}
                                {/*                                                 className="form-input w-full" */}
                                {/*                                                 type="text" */}
                                {/*                                                 value={address_line_2} */}
                                {/*                                                 disabled={isSubmitting} */}
                                {/*                                                 onChange={e => */}
                                {/*                                                     this.safeSetState({ */}
                                {/*                                                         address_line_2: e.target.value */}
                                {/*                                                     }) */}
                                {/*                                                 } */}
                                {/*                                             /> */}
                                {/*                                             {error && error.address_line_2 && ( */}
                                {/*                                                 <span className="text-red text-xs"> */}
                                {/*                                                     {error.address_line_2} */}
                                {/*                                                 </span> */}
                                {/*                                             )} */}
                                {/*                                         </div> */}
                                {/*                                     </div> */}
                                {/*  */}
                                {/*                                     <div className="form-input-group"> */}
                                {/*                                         <label className="form-label"> */}
                                {/*                                             <Trans>Address Line 3</Trans> */}
                                {/*                                         </label> */}
                                {/*                                         <div className="w-full"> */}
                                {/*                                             <input */}
                                {/*                                                 className="form-input w-full" */}
                                {/*                                                 type="text" */}
                                {/*                                                 value={address_line_3} */}
                                {/*                                                 disabled={isSubmitting} */}
                                {/*                                                 onChange={e => */}
                                {/*                                                     this.safeSetState({ */}
                                {/*                                                         address_line_3: e.target.value */}
                                {/*                                                     }) */}
                                {/*                                                 } */}
                                {/*                                             /> */}
                                {/*                                             {error && error.address_line_3 && ( */}
                                {/*                                                 <span className="text-red text-xs"> */}
                                {/*                                                     {error.address_line_3} */}
                                {/*                                                 </span> */}
                                {/*                                             )} */}
                                {/*                                         </div> */}
                                {/*                                     </div> */}
                                {/*  */}
                                {/*                                     <div className="form-actions"> */}
                                {/*                                         {id ? ( */}
                                {/*                                             <DeleteButton */}
                                {/*                                                 action="archive" */}
                                {/*                                                 url={`clients/${id}/archive`} */}
                                {/*                                                 onDelete={this.handleDelete}> */}
                                {/*                                                 <button type="button" className="btn btn-solid-red"> */}
                                {/*                                                     <Trans>Archive</Trans> */}
                                {/*                                                 </button> */}
                                {/*                                             </DeleteButton> */}
                                {/*                                         ) : ( */}
                                {/*                                             <Link className="btn btn-solid-red" to={`/clients`}> */}
                                {/*                                                 <Trans>Cancel</Trans> */}
                                {/*                                             </Link> */}
                                {/*                                         )} */}
                                {/*                                         {process.env.NODE_ENV === "development" && ( */}
                                {/*                                             <button */}
                                {/*                                                 type="button" */}
                                {/*                                                 className="btn" */}
                                {/*                                                 onClick={this.generateClient}> */}
                                {/*                                                 Generate */}
                                {/*                                             </button> */}
                                {/*                                         )} */}
                                {/*                                         <button */}
                                {/*                                             type="submit" */}
                                {/*                                             disabled={isSubmitting} */}
                                {/*                                             className="btn btn-solid-blue"> */}
                                {/*                                             {isSubmitting ? ( */}
                                {/*                                                 <Trans>Saving...</Trans> */}
                                {/*                                             ) : ( */}
                                {/*                                                 <Trans>Save</Trans> */}
                                {/*                                             )} */}
                                {/*                                         </button> */}
                                {/*                                     </div> */}
                                {/*                                 </form> */}
                            </div>
                        </LoadingWrapper>
                    </div>
                </div>
            </div>
        );
    }
}

export default ClientCreate;
