import React, { Component } from "react";
// import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
// import ResourceIndex from "./ResourceIndex";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources, createResource, updateResource, deleteResource } from "../api";
import LoadingWrapper from "../components/LoadingWrapper";
// import DeleteButton from "../components/DeleteButton";
// import history from "../components/history";
// import Icon from "../components/Icon";

class ExpenseCategoryIndex extends Component {
    static contextType = ApiContext;
    state = {
        categories: [],
        name: "",
        isLoading: false,
        updatingCategoryId: null,
        deletingCategoryId: null,
        isSubmitting: false,
        error: null
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchCategories();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    fetchCategories = () => {
        this.safeSetState({ isLoading: true });
        this.context
            .callApi(() => fetchResources("expense-categories"))
            .then(({ data }) => {
                this.safeSetState({
                    categories: data,
                    isLoading: false,
                    error: null
                });
            })
            .catch(error => {
                this.safeSetState({ isLoading: false, error });
                if (typeof error === "object") {
                    return toast.error(<Trans>Failed to fetch categories.</Trans>);
                }
                toast.error(error);
            });
    };

    handleCreate = e => {
        e.preventDefault();
        const { name } = this.state;
        this.safeSetState({ isSubmitting: true });
        this.context
            .callApi(() => createResource("expense-categories", { name }))
            .then(({ data }) => {
                this.safeSetState({
                    categories: this.state.categories.concat(data),
                    name: "",
                    isSubmitting: false,
                    error: null
                });
            })
            .catch(error => {
                this.safeSetState({ isSubmitting: false, error });
                if (typeof error === "object") {
                    return toast.error(<Trans>Failed to create category.</Trans>);
                }
                toast.error(error);
            });
    };

    handleDelete = cat => {
        const confirm = window.confirm("Are you sure you want to delete this category?");

        if (!confirm) {
            return;
        }

        this.safeSetState({ deletingCategoryId: cat.id });
        this.context
            .callApi(() => deleteResource(`expense-categories/${cat.id}`))
            .then(({ data }) => {
                this.safeSetState({
                    categories: this.state.categories.filter(c => cat.id !== c.id),
                    deletingCategoryId: null
                });
                toast.success(<Trans>Category deleted!</Trans>);
            })
            .catch(error => {
                this.safeSetState({ deletingCategoryId: null, error });
                if (typeof error === "object") {
                    return toast.error(<Trans>Failed to update category.</Trans>);
                }
                toast.error(error);
            });
    };

    handleUpdate = (e, cat) => {
        e.preventDefault();
        this.safeSetState({ updatingCategoryId: cat.id });
        this.context
            .callApi(() => updateResource(`expense-categories/${cat.id}`, { name: cat.name }))
            .then(({ data }) => {
                this.safeSetState({
                    updatingCategoryId: null
                });
                toast.success(<Trans>Category updated!</Trans>);
            })
            .catch(error => {
                this.safeSetState({ updatingCategoryId: null, error });
                if (typeof error === "object") {
                    return toast.error(<Trans>Failed to update category.</Trans>);
                }
                toast.error(error);
            });
    };

    handleCategoryChange = (e, category) => {
        e.persist();
        this.setState(prevState => ({
            categories: prevState.categories.map((cat, i) => {
                if (cat.id === category.id) {
                    return { ...cat, name: e.target.value };
                }
                return cat;
            })
        }));
    };

    render() {
        const {
            categories,
            isLoading,
            name,
            isSubmitting,
            updatingCategoryId,
            deletingCategoryId
        } = this.state;

        return (
            <div className="main-page-container">
                <div className="p-2 md:p-8">
                    <h1 className="page-header">
                        <Trans>Manage Expense Categories</Trans>
                    </h1>
                    <div className="header-actions">
                        <form methos="POST" className="w-full" onSubmit={this.handleCreate}>
                            <div className="flex w-full">
                                <input
                                    value={name}
                                    onChange={e => this.setState({ name: e.target.value })}
                                    type="text"
                                    className="form-input flex-1 mr-2"
                                    placeholder="Type a name..."
                                />
                                <button className="btn" disabled={isSubmitting}>
                                    {isSubmitting ? (
                                        <Trans>Adding New...</Trans>
                                    ) : (
                                        <Trans>Add New</Trans>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="">
                        <LoadingWrapper isLoading={isLoading}>
                            {categories.length ? (
                                <>
                                    {categories.map((category, index) => (
                                        <div
                                            key={category.id}
                                            className="shadow rounded p-4 overflow-hidden mb-4 bg-white rounded">
                                            <form
                                                method="POST"
                                                onSubmit={e => this.handleUpdate(e, category)}>
                                                <div className="flex items-center">
                                                    <input
                                                        type="text"
                                                        onChange={e =>
                                                            this.handleCategoryChange(e, category)
                                                        }
                                                        value={category.name}
                                                        className="form-input flex-1 mr-2"
                                                    />
                                                    <button
                                                        className="btn mr-2"
                                                        disabled={
                                                            category.id === updatingCategoryId ||
                                                            category.id === deletingCategoryId
                                                        }>
                                                        {category.id === updatingCategoryId ? (
                                                            <Trans>Updating...</Trans>
                                                        ) : (
                                                            <Trans>Update</Trans>
                                                        )}
                                                    </button>
                                                    <button
                                                        className="btn btn-red"
                                                        disabled={
                                                            category.id === updatingCategoryId ||
                                                            category.id === deletingCategoryId
                                                        }
                                                        onClick={() => this.handleDelete(category)}>
                                                        <Trans>Delete</Trans>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <div className="shadow rounded overflow-hidden text-sm p-4 bg-white rounded">
                                    <Trans>No categories.</Trans>
                                </div>
                            )}
                        </LoadingWrapper>
                    </div>
                </div>
            </div>
        );
    }
}
export default ExpenseCategoryIndex;
