import React, { Component } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import moment from "moment";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources } from "../api";
import LoadingWrapper from "../components/LoadingWrapper";
import history from "../components/history";

class ReportDetail extends Component {
    static propTypes = {
        tab: PropTypes.string.isRequired,
        period: PropTypes.string.isRequired,
        from: PropTypes.number.isRequired
    };
    static contextType = ApiContext;

    state = {
        data: [],
        total_hours: null,
        total_billable_hours: null,
        total_billable_amount: null,
        isLoading: false
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.tab !== this.props.tab ||
            prevProps.period !== this.props.period ||
            prevProps.from !== this.props.from
        ) {
            this.fetchData();
        }
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    fetchData = () => {
        const { tab, period, from } = this.props;
        this.safeSetState({ isLoading: true });
        this.context
            .callApi(() =>
                fetchResources(`/reports/detail?type=${tab}&period=${period}&from=${from}`)
            )
            .then(({ data }) => {
                this.safeSetState({
                    data: data.data,
                    total_hours: data.total_hours.toFixed(2),
                    total_billable_hours: data.total_billable_hours.toFixed(2),
                    total_billable_amount: data.total_billable_amount.toFixed(2),
                    isLoading: false
                });
            })
            .catch(error => {
                this.safeSetState({ isLoading: false, error });
                if (typeof error === "object") {
                    return toast.error(<Trans>Failed to fetch data.</Trans>);
                }
                toast.error(error);
            });
    };

    render() {
        const {
            isLoading,
            data,
            total_hours,
            total_billable_amount,
            total_billable_hours
        } = this.state;
        const { tab, period, from } = this.props;

        return (
            <div className="">
                <div className="flex justify-center">
                    <Link
                        to="/reports"
                        className={`${tab === "clients" ? "tab-active" : ""} tab no-underline`}>
                        <Trans>Clients</Trans>
                    </Link>
                    <Link
                        to="/reports/projects"
                        className={`${tab === "projects" ? "tab-active" : ""} tab no-underline`}>
                        <Trans>Projects</Trans>
                    </Link>
                    {/* <Link */}
                    {/*     to="/reports/tasks" */}
                    {/*     className={`${tab === "tasks" ? "tab-active" : ""} tab no-underline`}> */}
                    {/*     <Trans>Tasks</Trans> */}
                    {/* </Link> */}
                    <Link
                        to="/reports/team"
                        className={`${tab === "team" ? "tab-active" : ""} tab no-underline`}>
                        <Trans>Team</Trans>
                    </Link>
                </div>
                <div className="rounded overflow-hidden shadow p-4 bg-white">
                    <LoadingWrapper isLoading={isLoading}>
                        <table className="w-full rounded overflow-hidden">
                            <thead>
                                <tr className="bg-grey-lighter border-b">
                                    <th className="text-xs p-2 font-medium text-left">
                                        <Trans>Name</Trans>
                                    </th>
                                    {tab === "projects" ? (
                                        <th className="text-xs p-2 font-medium text-left">
                                            <Trans>Client</Trans>
                                        </th>
                                    ) : null}
                                    <th
                                        style={{ width: 50 }}
                                        className="text-xs p-2 font-medium text-right">
                                        <Trans>Hours</Trans>
                                    </th>
                                    <th
                                        style={{ minWidth: 100 }}
                                        className="hidden sm:block text-xs p-2 font-medium text-right">
                                        &nbsp;
                                    </th>
                                    <th
                                        style={{ width: 100 }}
                                        className="text-xs p-2 font-medium text-right">
                                        <Trans>Billable Hours</Trans>
                                    </th>
                                    {/* <th className="text-xs p-2 font-medium text-right"> */}
                                    {/*     <Trans>Billable Amount</Trans> */}
                                    {/* </th> */}
                                </tr>
                            </thead>
                            <tfoot>
                                <tr className="">
                                    <td className="text-xs p-2 font-medium text-left">
                                        <Trans>Total</Trans>
                                    </td>
                                    {tab === "projects" ? (
                                        <td className="text-xs p-2 font-medium text-left">
                                            &nbsp;
                                        </td>
                                    ) : null}
                                    <td className="text-xs p-2 font-medium text-right">
                                        {total_hours}
                                    </td>
                                    <td className="hidden sm:block text-xs p-2 font-medium text-right">
                                        &nbsp;
                                    </td>
                                    <td className="text-xs p-2 font-medium text-right">
                                        {total_billable_hours}
                                    </td>
                                    {/* <td className="text-xs p-2 font-medium text-right"> */}
                                    {/*     {total_billable_amount} */}
                                    {/* </td> */}
                                </tr>
                            </tfoot>
                            <tbody>
                                {!data.length ? (
                                    <tr className="border-b">
                                        <td
                                            colSpan={tab === "projects" ? 5 : 4}
                                            className="text-xs p-2 text-left">
                                            <Trans>No data.</Trans>
                                        </td>
                                    </tr>
                                ) : (
                                    data.map((row, index) => (
                                        <tr key={index} className="border-b">
                                            <td className="text-xs p-2 text-left whitespace-no-wrap">
                                                <Link to={`/${tab}/${row.id}`}>{row.name}</Link>
                                            </td>
                                            {tab === "projects" ? (
                                                <td className="text-xs p-2 text-left whitespace-no-wrap">
                                                    <Link to={`/clients/${row.client_id}`}>
                                                        {row.client_name}
                                                    </Link>
                                                </td>
                                            ) : null}
                                            <td className="text-xs p-2 text-right">
                                                {row.total_hours.toFixed(2)}
                                            </td>
                                            <td className="hidden sm:block text-xs p-2 text-left">
                                                <div
                                                    className="bg-green-light w-full"
                                                    style={{
                                                        background: `linear-gradient(to right, #38c172 0%,#38c172 ${(row.total_billable_hours /
                                                            row.total_hours) *
                                                            100}%,#51d88a ${(row.total_billable_hours /
                                                            row.total_hours) *
                                                            100}%, #51d88a 100%)`,
                                                        height: 14,
                                                        width: `${(row.total_hours /
                                                            data.reduce((max, row) => {
                                                                return Math.max(
                                                                    max,
                                                                    row.total_hours
                                                                );
                                                            }, 0)) *
                                                            100}%`
                                                    }}
                                                />
                                            </td>
                                            <td className="text-xs p-2 text-right">
                                                {row.total_billable_hours.toFixed(2)}
                                            </td>
                                            {/* <td className="text-xs p-2 text-right"> */}
                                            {/*     {row.total_billable_amount.toFixed(2)} */}
                                            {/* </td> */}
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </LoadingWrapper>
                </div>
            </div>
        );
    }
}

export default ReportDetail;
