import React, { Component } from "react";
import Icon from "./Icon";

class PasswordInput extends Component {
    static defaultProps = {
        autoComplete: "current-password"
    };
    state = { showPassword: false };
    render() {
        const { showPassword } = this.state;
        const { className, value, autoComplete, disabled, onChange } = this.props;
        return (
            <div className="relative">
                <input
                    type={showPassword ? "text" : "password"}
                    className={className}
                    value={value}
                    autoComplete={autoComplete}
                    disabled={disabled}
                    onChange={onChange}
                />
                <span
                    className="flex justify-center items-center bg-white transition rounded-r border hover:text-teal absolute pin-r pin-y block p-2 cursor-pointer"
                    onClick={() =>
                        this.setState(prevState => ({ showPassword: !prevState.showPassword }))
                    }>
                    <Icon size={16} icon={showPassword ? "eye-open" : "eye-closed"} />
                </span>
            </div>
        );
    }
}
export default PasswordInput;
