import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "./ResourceIndex";

const Index = props => (
    <div>
        <ResourceIndex
            endpoint="contacts"
            model={<Trans>Contacts</Trans>}
            headers={["name"]}
            translations={[<Trans>Name</Trans>]}
            canSearch={false}
            actions={["show"]}
            {...props}
        />
    </div>
);

export default Index;
