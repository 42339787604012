import React, { Component } from "react";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
import LoadingWrapper from "../components/LoadingWrapper";
import Graph from "../components/Graph";
import Icon from "../components/Icon";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources } from "../api";

class InvoiceStats extends Component {
    static contextType = ApiContext;

    state = {
        data: null,
        total_open: null,
        currency_code: null,
        currency_precision: null,
        total_paid: null,
        year: new Date().getFullYear(),
        isLoading: false
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchStats();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.year !== this.state.year) {
            this.fetchStats();
        }
    }

    fetchStats = () => {
        this.safeSetState({ isLoading: true, error: null });
        this.context
            .callApi(() => fetchResources(`invoices/stats?year=${this.state.year}`))
            .then(({ data }) => {
                this.safeSetState({
                    data: data.data,
                    currency_code: data.currency_code,
                    currency_precision: data.currency_precision,
                    total_paid: data.total_paid,
                    total_open: data.total_open,
                    isLoading: false
                });
            })
            .catch(error => {
                this.safeSetState({ isLoading: false, error });
                if (typeof error === "object") {
                    return toast.error(<Trans>Failed to fetch stats.</Trans>);
                }
                toast.error(error);
            });
    };

    render() {
        const { isLoading, year, total_open, total_paid, data, currency_code } = this.state;
        return (
            <div className="flex flex-col md:flex-row mb-4">
                <div
                    className="mb-4 md:mb-0 md:mr-4 rounded bg-white shadow"
                    style={{ minWidth: 240 }}>
                    <LoadingWrapper isLoading={isLoading}>
                        {data !== null ? (
                            <>
                                <div className="border-b p-4">
                                    <h5 className="mb-2">
                                        <Trans>Total Open</Trans>
                                    </h5>
                                    <h3>
                                        {total_open} {currency_code}
                                    </h3>
                                </div>

                                <div className="p-4">
                                    <h5 className="mb-2">
                                        <Trans>Total Paid Amount</Trans>
                                    </h5>
                                    <h3>
                                        {total_paid} {currency_code}
                                    </h3>
                                </div>
                            </>
                        ) : null}
                    </LoadingWrapper>
                </div>

                <div className="flex-1 shadow rounded bg-white p-2">
                    <LoadingWrapper isLoading={isLoading}>
                        <div className="text-sm flex items-center justify-center">
                            <span
                                className="cursor-pointer hover:text-teal"
                                onClick={() =>
                                    this.setState(prevState => ({ year: prevState.year - 1 }))
                                }>
                                <Icon icon="left" />
                            </span>
                            <span className="mx-2 font-bold">{year}</span>
                            <span
                                className="cursor-pointer hover:text-teal"
                                onClick={() =>
                                    this.setState(prevState => ({ year: prevState.year + 1 }))
                                }>
                                <Icon icon="right" />
                            </span>
                        </div>
                        {data !== null ? (
                            <Graph
                                data={data}
                                title={`Invoice Totals (${currency_code})`}
                                type="stackedBar"
                            />
                        ) : null}
                    </LoadingWrapper>
                </div>
            </div>
        );
    }
}

export default InvoiceStats;
