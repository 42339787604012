import React from "react";
import { Trans } from "@lingui/macro";
import PropTypes from "prop-types";
import Icon from "./Icon";

const ProjectTaskList = ({
    setState,
    tasks,
    new_task_name,
    new_task_estimate,
    budget_type,
    hourly_rate_type,
    currency_code,
    tableColCount,
    project_type,
    updateTask,
    removeTask,
    addNewTask
}) => (
    <table className="w-full">
        <thead>
            <tr className="border-b bg-grey-lighter">
                <th className="font-light text-xs p-2 text-left text-grey-dark">
                    <Trans>Task Name</Trans>
                </th>
                <th className="font-light text-xs p-2 text-right text-grey-dark">
                    <Trans>Task Estimate (hrs)</Trans>
                </th>
                {project_type !== "NON_BILLABLE" ? (
                    <th className="font-light text-xs p-2 text-grey-dark">
                        <Trans>Billable</Trans>
                    </th>
                ) : null}
                {budget_type === "FEES_PER_TASK" && project_type !== "NON_BILLABLE" ? (
                    <th className="font-light text-xs p-2 text-grey-dark text-right">
                        <Trans>Budget</Trans>
                    </th>
                ) : null}
                {hourly_rate_type === "TASK_HOURLY" && project_type !== "NON_BILLABLE" ? (
                    <th className="font-light text-xs p-2 text-grey-dark text-right">
                        <Trans>Hourly Rate</Trans>
                    </th>
                ) : null}
            </tr>
        </thead>
        <tfoot>
            <tr>
                <td className="p-2" colSpan={tableColCount}>
                    <div className="flex items-center">
                        <input
                            className="form-input flex-1 mr-2"
                            placeholder="Add a task..."
                            type="text"
                            value={new_task_name}
                            onChange={e =>
                                setState({
                                    new_task_name: e.target.value
                                })
                            }
                        />
                        <input
                            className="form-input flex-1 mr-2"
                            placeholder="Estimate (hrs)..."
                            type="number"
                            value={new_task_estimate}
                            onChange={e =>
                                setState({
                                    new_task_estimate: e.target.value
                                })
                            }
                        />
                        <span className="btn" type="button" onClick={addNewTask}>
                            <Trans>Add New</Trans>
                        </span>
                    </div>
                </td>
                {budget_type === "FEES_PER_TASK" && project_type !== "NON_BILLABLE" ? (
                    <td className="p-2">
                        <div className="flex items-center justify-end">
                            <span className="mr-2">
                                {tasks.reduce((acc, t) => {
                                    return (parseFloat(t.budget) || 0) + acc;
                                }, 0)}
                            </span>
                            <span>{currency_code}</span>
                        </div>
                    </td>
                ) : null}
            </tr>
        </tfoot>
        <tbody>
            {!tasks.length ? (
                <tr className="border-b">
                    <td colSpan={tableColCount} className="p-2 text-sm">
                        <Trans>There are currently no tasks in the project.</Trans>
                    </td>
                </tr>
            ) : (
                tasks.map((task, index) => (
                    <tr key={`${index}_${tasks.length}`} className="border-b">
                        <td className="p-2 text-sm" style={{ height: 51 }}>
                            <div className="flex items-center">
                                <span
                                    className="mr-2 cursor-pointer hover:text-red"
                                    onClick={() => removeTask(index)}>
                                    <Icon icon="delete" />
                                </span>
                                <input
                                    type="text"
                                    className="form-input w-full"
                                    value={task.name}
                                    onChange={e =>
                                        updateTask({ ...task, name: e.target.value }, index)
                                    }
                                />
                            </div>
                        </td>
                        <td className="p-2 text-sm text-right">
                            <input
                                className="form-input w-full"
                                placeholder="Estimate (hrs)..."
                                type="number"
                                value={task.estimate}
                                onChange={e =>
                                    updateTask({ ...task, estimate: e.target.value }, index)
                                }
                            />
                        </td>
                        {project_type !== "NON_BILLABLE" ? (
                            <td className="p-2 text-center text-sm">
                                <input
                                    type="checkbox"
                                    checked={task.billable}
                                    onChange={e =>
                                        updateTask({ ...task, billable: e.target.checked }, index)
                                    }
                                />
                            </td>
                        ) : null}
                        {budget_type === "FEES_PER_TASK" ? (
                            <td className="p-2 text-sm">
                                <div className="flex items-center justify-end">
                                    <input
                                        style={{ width: 60 }}
                                        value={task.budget}
                                        onChange={e =>
                                            updateTask({ ...task, budget: e.target.value }, index)
                                        }
                                        type="text"
                                        className="form-input mr-2"
                                    />
                                    <span>{currency_code}</span>
                                </div>
                            </td>
                        ) : null}
                        {hourly_rate_type === "TASK_HOURLY" ? (
                            <td className="p-2 text-sm text-right">
                                <div className="flex items-center justify-end">
                                    <input
                                        style={{ width: 60 }}
                                        value={task.hourly_rate}
                                        onChange={e =>
                                            updateTask(
                                                { ...task, hourly_rate: e.target.value },
                                                index
                                            )
                                        }
                                        type="text"
                                        className="form-input mr-2"
                                    />
                                    <span>{currency_code}</span>
                                </div>
                            </td>
                        ) : null}
                    </tr>
                ))
            )}
        </tbody>
    </table>
);
ProjectTaskList.propType = {
    setState: PropTypes.func.isRequired,
    tasks: PropTypes.array.isRequired,
    new_task_name: PropTypes.string.isRequired,
    budget_type: PropTypes.string.isRequired,
    hourly_rate_type: PropTypes.string.isRequired,
    currency_code: PropTypes.string.isRequired,
    tableColCount: PropTypes.number.isRequired,
    updateTask: PropTypes.func.isRequired,
    removeTask: PropTypes.func.isRequired,
    addNewTask: PropTypes.func.isRequired
};

export default ProjectTaskList;
