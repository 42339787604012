import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import release from "../RELEASE_NOTES.md";
import LoadingWrapper from "../components/LoadingWrapper";
import Markdown from "react-markdown";

class ReleaseNotes extends Component {
    state = { content: null };

    getContent = () => {
        fetch(release)
            .then(r => r.text())
            .then(t =>
                this.safeSetState({
                    content: t
                })
            );
    };

    componentDidMount() {
        this._isMounted = true;
        this.getContent();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    render() {
        const { content } = this.state;
        return (
            <div className="main-page-container">
                <div className="p-2 md:p-8">
                    <h1 className="page-header">
                        <Trans>Release Notes</Trans>
                    </h1>
                    <LoadingWrapper isLoading={!content}>
                        <div className="static-content p-4 bg-white rounded">
                            <div style={{ maxWidth: 600 }}>
                                <Markdown source={content} />
                            </div>
                        </div>
                    </LoadingWrapper>
                </div>
            </div>
        );
    }
}

export default ReleaseNotes;
