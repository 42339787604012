import React, { Component } from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
// import ResourceIndex from "./ResourceIndex";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources } from "../api";
import LoadingWrapper from "../components/LoadingWrapper";
import NewExpenseButton from "../components/NewExpenseButton";
// import DeleteButton from "../components/DeleteButton";
// import history from "../components/history";
// import Icon from "../components/Icon";

class ExpenseIndex extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired
    };
    static contextType = ApiContext;
    state = {
        expenses: [],
        pagination: null,
        name: "",
        isLoading: false,
        isSubmitting: false,
        error: null
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchExpenses();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.path !== this.props.match.path) {
            this.fetchExpenses();
        }
    }

    fetchExpenses = (url = null) => {
        this.safeSetState({ isLoading: true });
        this.context
            .callApi(() => fetchResources(url ? url : "expenses"))
            .then(({ data }) => {
                this.safeSetState({
                    expenses: data.data,
                    isLoading: false,
                    error: null,
                    links: data.links || null,
                    meta: data.meta || null
                });
            })
            .catch(error => {
                this.safeSetState({ isLoading: false, error });
                if (typeof error === "object") {
                    return toast.error(<Trans>Failed to fetch expenses.</Trans>);
                }
                toast.error(error);
            });
    };

    render() {
        const { expenses, isLoading } = this.state;

        return (
            <div className="main-page-container">
                <div className="p-2 md:p-8">
                    <h1 className="page-header">
                        <Trans>Expenses</Trans>
                    </h1>
                    <NewExpenseButton />
                    <div className="">
                        <LoadingWrapper isLoading={isLoading}>
                            {expenses.length ? (
                                <>
                                    {expenses.map(expense => (
                                        <div
                                            key={expense.id}
                                            className="shadow rounded overflow-hidden mb-4 bg-white rounded">
                                            <div className="flex p-2 items-center border-b justify-between bg-grey-lighter">
                                                <div className="flex items-center">
                                                    <h2 className="text-base font-light">
                                                        {expense.name}
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <div className="shadow rounded overflow-hidden text-sm p-4 bg-white rounded">
                                    <Trans>No recent expenses.</Trans>
                                </div>
                            )}
                        </LoadingWrapper>
                    </div>
                </div>
            </div>
        );
    }
}
export default ExpenseIndex;
