import React, { Component } from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
import moment from "moment";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources } from "../api";
import Graph from "../components/Graph";
import LoadingWrapper from "../components/LoadingWrapper";
// import NewExpenseButton from "../components/NewExpenseButton";
// import DeleteButton from "../components/DeleteButton";
import ReportDetail from "../components/ReportDetail";
// import history from "../components/history";
import Icon from "../components/Icon";

class ReportsIndex extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired
    };
    static contextType = ApiContext;
    state = {
        overview: null,
        isLoading: false,
        isSubmitting: false,
        from: moment()
            .startOf("isoWeek")
            .unix(),
        till: moment()
            .endOf("isoWeek")
            .unix(),
        period: "week",
        tab: "clients",
        error: null
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchOverview();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.from !== this.state.from || prevState.period !== this.state.period) {
            this.fetchOverview();
        }
    }

    subPeriod = () => {
        this.setState(prevState => ({
            from: moment
                .unix(prevState.from)
                .subtract(1, prevState.period + "s")
                .unix(),
            till: moment
                .unix(prevState.till)
                .subtract(1, prevState.period + "s")
                .unix()
        }));
    };

    addPeriod = () => {
        this.setState(prevState => ({
            from: moment
                .unix(prevState.from)
                .add(1, prevState.period + "s")
                .unix(),
            till: moment
                .unix(prevState.till)
                .add(1, prevState.period + "s")
                .unix()
        }));
    };

    fetchOverview = () => {
        const { from, period } = this.state;
        this.safeSetState({ isLoading: true });
        this.context
            .callApi(() => fetchResources(`reports?period=${period}&from=${from}`))
            .then(({ data }) => {
                this.safeSetState({
                    graphData: this.parseData(data),
                    overview: data,
                    isLoading: false,
                    error: null
                });
            })
            .catch(error => {
                this.safeSetState({ isLoading: false, error });
                if (typeof error === "object") {
                    return toast.error(<Trans>Failed to fetch data.</Trans>);
                }
                toast.error(error);
            });
    };

    parseData = data => {
        return {
            datasets: [
                {
                    data: [data.billable_hours.toFixed(2), data.non_billable_hours.toFixed(2)],
                    backgroundColor: ["#38c172", "#51d88a"],
                    hoverBackgroundColor: ["#039966", "#039966"]
                }
            ],
            labels: ["", ""]
        };
    };

    render() {
        const { overview, isLoading, from, till, period, graphData } = this.state;
        const { tab } = this.props.match.params;

        let periodDates = `${moment.unix(from).format("DD MMM")} - ${moment
            .unix(till)
            .format("DD MMM")}`;
        if (period === "month") {
            periodDates = moment.unix(from).format("MMMM");
        }
        if (period === "quarter") {
            periodDates = `${moment.unix(from).format("MMM")} - ${moment.unix(till).format("MMM")}`;
        }
        if (period === "year") {
            periodDates = moment.unix(from).format("YYYY");
        }
        if (period === "all") {
            periodDates = null;
        }

        return (
            <div className="main-page-container">
                <div className="p-2 md:p-8">
                    <h1 className="page-header">
                        <Trans>Reports</Trans>
                    </h1>
                    <div className="header-actions">
                        <div className="flex items-center mb-2 md:mb-0">
                            <button
                                onClick={this.subPeriod}
                                disabled={period === "all"}
                                className="text-center cursor-pointer no-underline whitespace-no-wrap text-sm border bg-grey-lightest hover:bg-grey-light text-grey-dark py-2 px-4 rounded-l">
                                <Icon size={14} icon="left" />
                            </button>
                            <button
                                onClick={this.addPeriod}
                                disabled={period === "all"}
                                className="text-center cursor-pointer no-underline whitespace-no-wrap text-sm border-t border-b border-r bg-grey-lightest hover:bg-grey-light text-grey-dark py-2 px-4 rounded-r mr-2">
                                <Icon size={14} icon="right" />
                            </button>
                            <span className="mr-1 text-xl uppercase">{period}</span>
                            {periodDates ? <span className="text-xl">: {periodDates}</span> : null}
                        </div>
                        <div className="flex items-center justify-between">
                            {period === "all" ? null : (
                                <span
                                    className="text-sm underline cursor-pointer hover:text-grey-dark mr-2"
                                    onClick={() =>
                                        this.setState(prevState => ({
                                            from: moment()
                                                .startOf(period)
                                                .unix(),
                                            till: moment()
                                                .endOf(period)
                                                .unix()
                                        }))
                                    }>
                                    Return to this {period}
                                </span>
                            )}
                            <select
                                value={period}
                                onChange={e => {
                                    e.persist();
                                    this.setState(prevState => ({
                                        period: e.target.value,
                                        from: moment
                                            .unix(prevState.from)
                                            .startOf(e.target.value)
                                            .unix(),
                                        till: moment
                                            .unix(prevState.from)
                                            .endOf(e.target.value)
                                            .unix()
                                    }));
                                }}
                                className="text-sm text-grey-dark bg-white border rounded p-1">
                                <option value="week">Week</option>
                                <option value="month">Month</option>
                                <option value="quarter">Quarter</option>
                                <option value="year">Year</option>
                                <option value="all">All Time</option>
                            </select>
                        </div>
                    </div>
                    <div className="">
                        <div className="p-4 flex shadow rounded overflow-hidden mb-4 bg-white rounded">
                            <LoadingWrapper isLoading={isLoading}>
                                {overview ? (
                                    <div className="flex flex-col items-center w-full md:flex-row">
                                        <div className="flex flex-col mr-4 mb-2 md:mb-0">
                                            <span className="mb-1 text-grey-dark text-xs">
                                                <Trans>Hours Tracked</Trans>
                                            </span>
                                            <span className="text-4xl text-grey-darker">
                                                {overview.total_hours.toFixed(2)}
                                            </span>
                                        </div>
                                        <div className="flex">
                                            <div className="mr-2 relative">
                                                <span className="absolute flex pin items-center justify-center text-xs">
                                                    {Math.floor(
                                                        (overview.billable_hours /
                                                            overview.total_hours) *
                                                            100
                                                    )}
                                                    %
                                                </span>
                                                <Graph data={graphData} type="doughnut" />
                                            </div>
                                            <div className="flex flex-col">
                                                <span className="mb-1 text-grey-dark text-xs">
                                                    <Trans>Billable Hours</Trans>
                                                </span>
                                                <span className="text-sm flex items-center text-grey-darker mb-1">
                                                    <span
                                                        style={{ height: 12, width: 12 }}
                                                        className="bg-green rounded mr-1"
                                                    />
                                                    <span className="mr-1">
                                                        {overview.billable_hours.toFixed(2)}
                                                    </span>
                                                    <span>
                                                        <Trans>Billable</Trans>
                                                    </span>
                                                </span>
                                                <span className="text-sm flex items-center text-grey-darker">
                                                    <span
                                                        style={{ height: 12, width: 12 }}
                                                        className="bg-green-light rounded mr-1"
                                                    />
                                                    <span className="mr-1">
                                                        {overview.non_billable_hours.toFixed(2)}
                                                    </span>
                                                    <span>
                                                        <Trans>Non-Billable</Trans>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </LoadingWrapper>
                        </div>

                        <ReportDetail period={period} from={from} tab={tab ? tab : "clients"} />
                    </div>
                </div>
            </div>
        );
    }
}
export default ReportsIndex;
