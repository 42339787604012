import React, { Component } from "react";
import { toast } from "react-toastify";
import GlobalSearch from "./GlobalSearch";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../App";
import history from "../components/history";
import { fetchResources } from "../api";
import { ApiContext } from "../contexts/ApiContext";

class NewInvoiceButton extends Component {
    static contextType = ApiContext;
    state = {
        show_form: false,
        new_client: false,
        client_name: "",
        client_id: "",
        clients: [],
        isFetchingClients: false
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    componentDidUpdate (prevProps, prevState) {
        if (prevState.show_form !== this.state.show_form && this.state.show_form) {
            this.fetchClients();
        }
    }

    fetchClients = () => {
        this.safeSetState({ isFetchingClients: true });
        this.context
            .callApi(() => fetchResources("clients?simple"))
            .then(({ data }) => {
                this.safeSetState({
                    clients: data,
                    isFetchingClients: false
                });
            })
            .catch(error => {
                this.safeSetState({
                    isFetchingClients: false,
                    error,
                    message: error.status === 403 ? <Trans>Not allowed.</Trans> : ""
                });
                toast.error(<Trans>Failed to fetch clients.</Trans>);
            });
    };

    render() {
        const { show_form, new_client, client_name, client_id, clients } = this.state;

        return (
            <div>
                <div className="header-actions">
                    <button
                        onClick={() => this.setState({ show_form: true })}
                        disabled={show_form}
                        className="btn md:mb-0 mb-2 md:mr-2 btn-primary mr-auto">
                        <Trans>New Invoice</Trans>
                    </button>
                    <GlobalSearch url="invoices" placeholder={i18n._(t`Search by ID`)} />
                </div>
                {show_form ? (
                    <div className="p-4 shadow bg-white rounded mb-4">
                        <div className="flex items-center mb-4">
                            <label className="form-label">Client</label>
                            {new_client ? (
                                <input
                                    type="text"
                                    style={{ minWidth: 175 }}
                                    value={client_name}
                                    onChange={e => this.setState({ client_name: e.target.value })}
                                    className="form-input"
                                />
                            ) : (
                                <select
                                    value={client_id}
                                    className="form-input"
                                    style={{ minWidth: 175 }}
                                    disabled={!clients.length}
                                    onChange={e => this.setState({ client_id: e.target.value })}>
                                    <option>-- {i18n._(t`Select Client`)} --</option>
                                    {clients.map(client => (
                                        <option key={client.id} value={client.id}>
                                            {client.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                            <span className="mx-2 text-sm">or</span>
                            {new_client ? (
                                <button
                                    onClick={() => this.setState({ new_client: false })}
                                    className="btn">
                                    <Trans>Choose Client</Trans>
                                </button>
                            ) : (
                                <button
                                    onClick={() => this.setState({ new_client: true })}
                                    className="btn">
                                    <Trans>New Client</Trans>
                                </button>
                            )}
                        </div>
                        <div>
                            <button
                                className="btn btn-primary mr-2"
                                disabled={!client_id}
                                onClick={() => history.push(`/invoices/create/${client_id}`)}>
                                <Trans>Next</Trans>
                            </button>
                            <button
                                className="btn"
                                onClick={() => this.setState({ show_form: false })}>
                                <Trans>Cancel</Trans>
                            </button>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default NewInvoiceButton;
