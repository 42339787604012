import React, { Component } from "react";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import { register as attemptRegister } from "../../api";
import { ApiContext } from "../../contexts/ApiContext";
import PasswordInput from "../../components/PasswordInput";
import logo from "../../assets/logo-sm.png";

class Register extends Component {
    static contextType = ApiContext;

    state =
        process.env.NODE_ENV === "development"
            ? {
                  family_name: "Williams",
                  given_names: "Ifor Waldo",
                  email:
                      Math.random()
                          .toString(36)
                          .replace(/[^a-z]+/g, "")
                          .substr(0, 5) + "@indier.com",
                  password: "123456",
                  password_confirmation: "123456",
                  error: null,
                  isLoading: false
              }
            : {
                  name: "",
                  email: "",
                  password: "",
                  password_confirmation: "",
                  error: null,
                  isLoading: false
              };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    register = e => {
        e.preventDefault();
        const { callApi } = this.context;
        this.safeSetState({ error: null, isLoading: true });
        callApi(() => attemptRegister(this.state))
            .then(res => {
                toast.success(res.data.message, { autoClose: false });

                this.safeSetState({
                    family_name: "",
                    given_names: "",
                    email: "",
                    phone: "",
                    password: "",
                    password_confirmation: "",
                    error: null,
                    isLoading: false
                });
            })
            .catch(e => {
                this.safeSetState({ error: e, isLoading: false });
                let msg = e;
                if (typeof e === "object") {
                    msg = e[Object.keys(e)[0]];
                }
                if (typeof msg === "object") {
                    msg = "Registration failed, please try again.";
                }
                toast.error(msg);
            });
    };

    render() {
        const {
            family_name,
            given_names,
            email,
            password,
            password_confirmation,
            phone,
            error,
            isLoading
        } = this.state;

        return (
            <div className="full-screen-form-container">
                <div className="full-screen-form">
                    <div className="text-center w-full">
                        <img className="mb-8" src={logo} alt="" style={{ maxWidth: 200 }} />
                    </div>
                    <div className="form-no-flex">
                        <form method="POST" onSubmit={this.register} className="">
                            <div className="form-input-group">
                                <label className="form-label">
                                    <Trans>Family Name</Trans>
                                </label>
                                <input
                                    value={family_name}
                                    onChange={e => this.setState({ family_name: e.target.value })}
                                    type="text"
                                    disabled={isLoading}
                                    className="form-input w-full"
                                />
                            </div>
                            <div className="form-input-group">
                                <label className="form-label">
                                    <Trans>Given Names</Trans>
                                </label>
                                <input
                                    value={given_names}
                                    onChange={e => this.setState({ given_names: e.target.value })}
                                    type="text"
                                    disabled={isLoading}
                                    className="form-input w-full"
                                />
                            </div>
                            <div className="form-input-group">
                                <label className="form-label">
                                    <Trans>Email</Trans>
                                </label>
                                <input
                                    value={email}
                                    onChange={e => this.setState({ email: e.target.value })}
                                    type="email"
                                    autoComplete="email"
                                    disabled={isLoading}
                                    className="form-input w-full"
                                />
                                {error && error.errors && error.errors.email && (
                                    <p className="text-red text-sm mt-2">{error.errors.email[0]}</p>
                                )}
                            </div>
                            <div className="form-input-group">
                                <label className="form-label">
                                    <Trans>Contact Number</Trans>
                                </label>
                                <input
                                    value={phone}
                                    onChange={e => this.setState({ phone: e.target.value })}
                                    type="text"
                                    disabled={isLoading}
                                    className="form-input w-full"
                                />
                                {error && error.errors && error.errors.phone && (
                                    <p className="text-red text-sm mt-2">{error.errors.phone[0]}</p>
                                )}
                            </div>
                            <div className="form-input-group">
                                <label className="form-label">
                                    <Trans>Password</Trans>
                                </label>
                                <PasswordInput
                                    value={password}
                                    onChange={e => this.setState({ password: e.target.value })}
                                    autoComplete="new-password"
                                    disabled={isLoading}
                                    className="form-input w-full"
                                />
                                {error && error.errors && error.errors.password && (
                                    <p className="text-red text-sm mt-2">
                                        {error.errors.password[0]}
                                    </p>
                                )}
                            </div>
                            <div className="form-input-group">
                                <label className="form-label">
                                    <Trans>Password Confirmation</Trans>
                                </label>
                                <PasswordInput
                                    value={password_confirmation}
                                    onChange={e =>
                                        this.setState({ password_confirmation: e.target.value })
                                    }
                                    disabled={isLoading}
                                    autoComplete="new-password"
                                    className="form-input w-full"
                                />
                            </div>
                            <button type="submit" disabled={isLoading} className="btn w-full mb-2">
                                {isLoading ? (
                                    <Trans>Registering...</Trans>
                                ) : (
                                    <Trans>Register</Trans>
                                )}
                            </button>
                            <div className="text-right text-grey text-sm">
                                <Trans>Already registered?</Trans>{" "}
                                <Link className="text-grey" to="/login">
                                    <Trans>Login</Trans>
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Register;
