import React, { Component } from "react";
import { toast } from "react-toastify";
import { Trans, t } from "@lingui/macro";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { i18n } from "../App";
import moment from "moment";
import LoadingWrapper from "../components/LoadingWrapper";
import history from "../components/history";
import Icon from "../components/Icon";
import { createResource, fetchResources, deleteResource } from "../api";
import { ApiContext } from "../contexts/ApiContext";

const customModalStyles = {
    overlay: {
        zIndex: 50
    },
    content: {
        maxWidth: 600,
        width: "100%",
        maxHeight: 430,
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        margin: "auto",
        backgroundColor: "transparent",
        padding: 10,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    }
};

class InvoiceShow extends Component {
    static contextType = ApiContext;
    state = {
        invoice: null,
        isLoading: false,
        isSendingInvoice: false,
        deletingPaymentIds: [],
        isDeletingInvoice: false,
        showPaymentForm: false,
        showSendInvoiceForm: false,
        isDuplicatingInvoice: false,
        payment_amount: 0,
        email_to: [],
        email_cc: [],
        email_message: "Hi, please find attached invoice for work done.",
        email_subject: "New Invoice",
        isSubmitting: false,
        payment_date: moment().format("YYYY-MM-DD")
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchInvoice();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    fetchInvoice = () => {
        const { id } = this.props.match.params;
        this.safeSetState({ isLoading: true });
        this.context
            .callApi(() => fetchResources(`invoices/${id}`))
            .then(({ data }) => {
                this.safeSetState({
                    invoice: data,
                    payment_amount: data.amount_due,
                    isLoading: false
                });
            })
            .catch(error => {
                this.safeSetState({
                    isLoading: false,
                    error,
                    message: error.status === 403 ? <Trans>Not allowed.</Trans> : ""
                });
                toast.error(<Trans>Failed to fetch invoice.</Trans>);
            });
    };

    duplicateInvoice = () => {
        const confirm = window.confirm("Are you sure you want to duplicate this invoice?");

        if (!confirm) {
            return;
        }

        const { id } = this.props.match.params;
        this.safeSetState({ isDuplicatingInvoice: true });
        this.context
            .callApi(() => createResource(`invoices/${id}/duplicate`))
            .then(({ data }) => {
                history.push(`/invoices/${data.id}/edit`);
                toast.success(<Trans>Invoice duplicated!</Trans>);
            })
            .catch(error => {
                this.safeSetState({ isDuplicatingInvoice: false });
                toast.error(<Trans>Failed to duplicate invoice.</Trans>);
            });
    };

    handleDelete = () => {
        const confirm = window.confirm("Are you sure you want to delete this invoice?");

        if (!confirm) {
            return;
        }

        const { id } = this.props.match.params;
        this.safeSetState({ isDeletingInvoice: true });
        this.context
            .callApi(() => deleteResource(`invoices/${id}`))
            .then(({ data }) => {
                history.push(`/invoices`);
                toast.success(<Trans>Invoice deleted!</Trans>);
            })
            .catch(error => {
                toast.error(<Trans>Failed to duplicate invoice.</Trans>);
            });
    };

    submitPayment = e => {
        e.preventDefault();
        const { id } = this.props.match.params;
        const { payment_date, payment_amount } = this.state;
        this.safeSetState({ isSubmitting: true });
        this.context
            .callApi(() =>
                createResource(`invoices/${id}/payment`, {
                    paid_at: payment_date,
                    amount: payment_amount
                })
            )
            .then(({ data }) => {
                this.safeSetState({
                    invoice: data,
                    isSubmitting: false,
                    payment_amount: data.amount_due,
                    showPaymentForm: false
                });
                toast.success(<Trans>Payment saved!</Trans>);
            })
            .catch(error => {
                let msg = <Trans>Failed to fetch invoice.</Trans>;
                this.safeSetState({
                    isSubmitting: false,
                    error
                });
                if (typeof error.message === "string") {
                    msg = error.message;
                }
                toast.error(msg);
            });
    };

    sendInvoice = () => {
        const {
            email_to,
            email_cc,
            email_subject,
            email_message,
            invoice: { id }
        } = this.state;
        if (!email_to.length) {
            return toast.error(<Trans>At least one recipient required.</Trans>);
        }

        const payload = {
            email_to,
            email_cc,
            email_subject,
            email_message
        };

        this.safeSetState({ isSendingInvoice: true });
        this.context
            .callApi(() => createResource(`invoices/${id}/send`, payload))
            .then(({ data }) => {
                this.safeSetState({ isSendingInvoice: false, showSendInvoiceForm: false });
                toast.success(<Trans>Invoice sent!</Trans>);
            })
            .catch(error => {
                this.safeSetState({ isSendingInvoice: false, error });
                toast.error(<Trans>Failed to send invoice.</Trans>);
            });
    };

    deletePayment = id => {
        const confirm = window.confirm(i18n._(t`Are you sure you want to remove this payment?`));

        if (!confirm) return;

        this.safeSetState({
            deletingPaymentIds: this.state.deletingPaymentIds.concat(id)
        });
        this.context
            .callApi(() => deleteResource(`payments/${id}`))
            .then(({ data }) => {
                this.safeSetState({
                    invoice: data,
                    payment_amount: data.amount_due,
                    deletingPaymentIds: this.state.deletingPaymentIds.filter(arr => arr !== id)
                });
                toast.success(<Trans>Payment deleted!</Trans>);
            })
            .catch(error => {
                let msg = <Trans>Failed to delete payment.</Trans>;
                this.safeSetState({
                    deletingPaymentIds: this.state.deletingPaymentIds.filter(arr => arr !== id),
                    error
                });
                if (typeof error.message === "string") {
                    msg = error.message;
                }
                toast.error(msg);
            });
    };

    render() {
        const {
            invoice,
            isLoading,
            email_to,
            email_cc,
            email_message,
            email_subject,
            error,
            isSendingInvoice,
            isDuplicatingInvoice,
            isSubmitting,
            showPaymentForm,
            isDeletingInvoice,
            showSendInvoiceForm,
            payment_amount,
            deletingPaymentIds,
            payment_date
        } = this.state;

        return (
            <div className="main-page-container">
                <div className="p-2 md:p-8">
                    <h1 className="page-header">
                        <Trans>Invoice Detail</Trans>
                    </h1>
                    {invoice ? (
                        <div className="header-actions">
                            <div className="flex items-center">
                                {invoice.contacts.length ? (
                                    <button
                                        onClick={() => this.setState({ showSendInvoiceForm: true })}
                                        disabled={isSendingInvoice}
                                        className="btn-icon mr-2">
                                        <Icon size={16} icon="email" />
                                    </button>
                                ) : null}
                                <Link to={`/invoices/${invoice.id}/edit`} className="btn-icon mr-2">
                                    <Icon size={16} icon="edit" />
                                </Link>
                                <button
                                    onClick={this.duplicateInvoice}
                                    disabled={isDuplicatingInvoice}
                                    className="btn-icon mr-2">
                                    <Icon size={16} icon="copy" />
                                </button>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${process.env.REACT_APP_API_BASE_URL}invoices/${
                                        invoice.id
                                    }/pdf?token=${localStorage.getItem("THYME_JWT")}`}
                                    className="btn-icon no-external mr-2">
                                    <Icon size={16} icon="pdf" />
                                </a>
                                {invoice.amount_due ? (
                                    <button
                                        onClick={() => this.setState({ showPaymentForm: true })}
                                        disabled={showPaymentForm}
                                        className="btn-icon">
                                        <Icon size={16} icon="cash" />
                                    </button>
                                ) : null}
                                {!invoice.previous_payments.length ? (
                                    <button
                                        onClick={this.handleDelete}
                                        disabled={isDeletingInvoice}
                                        className="btn-icon ml-auto btn-icon-red">
                                        <Icon size={16} icon="delete" />
                                    </button>
                                ) : null}
                            </div>
                        </div>
                    ) : null}

                    {showPaymentForm ? (
                        <div className="p-4 shadow bg-white rounded mb-4">
                            <div className="flex items-center">
                                <form
                                    method="POST"
                                    className="w-full"
                                    onSubmit={this.submitPayment}>
                                    <div className="form-section-header bg-grey-lighter rounded-t">
                                        <Trans>Record Payment</Trans>
                                    </div>

                                    <div className="form-input-group">
                                        <label className="form-label">
                                            <Trans>Amount</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="text"
                                                autoFocus
                                                value={payment_amount}
                                                disabled={isSubmitting}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        payment_amount: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.payment_amount && (
                                                <span className="text-red text-xs">
                                                    {error.payment_amount}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-input-group mb-4">
                                        <label className="form-label">
                                            <Trans>Payment Date</Trans>
                                        </label>
                                        <div className="w-full">
                                            <input
                                                className="form-input w-full"
                                                type="date"
                                                value={payment_date}
                                                disabled={isSubmitting}
                                                onChange={e =>
                                                    this.safeSetState({
                                                        payment_date: e.target.value
                                                    })
                                                }
                                            />
                                            {error && error.payment_date && (
                                                <span className="text-red text-xs">
                                                    {error.payment_date}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            type="submit"
                                            className="btn btn-primary mr-2"
                                            disabled={isSubmitting}>
                                            <Trans>Save Payment</Trans>
                                        </button>
                                        <button
                                            className="btn"
                                            onClick={() =>
                                                this.setState({ showPaymentForm: false })
                                            }>
                                            <Trans>Cancel</Trans>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : null}

                    <LoadingWrapper isLoading={isLoading}>
                        {invoice ? (
                            <div className="form-container">
                                <div className="form-section-header bg-grey-lighter">
                                    <Trans>Basic Info</Trans>
                                </div>

                                <div className="form-input-group">
                                    <label className="form-label">ID</label>
                                    <div className="w-full">{invoice.id}</div>
                                </div>

                                <div className="form-input-group">
                                    <label className="form-label">Status</label>
                                    <div className="w-full">{invoice.status}</div>
                                </div>

                                <div className="form-input-group">
                                    <label className="form-label">Client</label>
                                    <div className="w-full">
                                        <Link to={`/clients/${invoice.client_id}`}>
                                            {invoice.client_name}
                                        </Link>
                                    </div>
                                </div>

                                <div className="form-input-group">
                                    <label className="form-label">
                                        <Trans>Subject</Trans>
                                    </label>
                                    <div className="w-full">{invoice.subject}</div>
                                </div>

                                <div className="form-input-group">
                                    <label className="form-label">
                                        <Trans>Billed At</Trans>
                                    </label>
                                    <div className="w-full">
                                        {moment.unix(invoice.billed_at).format("YYYY-MM-DD")}
                                    </div>
                                </div>

                                <div className="form-input-group">
                                    <label className="form-label leading-normal">
                                        <Trans>Notes</Trans>
                                    </label>
                                    <div className="w-full">{invoice.notes || "-"}</div>
                                </div>

                                {invoice.previous_payments && invoice.previous_payments.length ? (
                                    <>
                                        <div className="form-section-header bg-grey-lighter">
                                            <Trans>Payments</Trans>
                                        </div>

                                        {invoice.previous_payments.map((payment, index) => (
                                            <div
                                                className={`form-input-group ${
                                                    deletingPaymentIds.includes(payment.id)
                                                        ? "bg-grey-light cursor-not-allowed"
                                                        : ""
                                                }`}
                                                key={index}>
                                                <label className="form-label">
                                                    {moment
                                                        .unix(payment.paid_at)
                                                        .format("YYYY-MM-DD")}
                                                </label>
                                                <div className="w-full flex">
                                                    <span>
                                                        {payment.amount.toFixed(
                                                            invoice.currency_precision
                                                        )}{" "}
                                                        {invoice.currency_code} ({payment.user_name}
                                                        )
                                                    </span>
                                                    <button
                                                        type="button"
                                                        disabled={deletingPaymentIds.includes(
                                                            payment.id
                                                        )}
                                                        onClick={() =>
                                                            this.deletePayment(payment.id)
                                                        }
                                                        className="ml-auto text-red hover:text-red-dark">
                                                        <Icon icon="delete" />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ) : null}

                                <div className="form-section-header bg-grey-lighter">
                                    <Trans>Items</Trans>
                                </div>
                                <div className="form-input-group overflow-y-auto">
                                    <table className="w-full rounded overflow-hidden">
                                        <thead>
                                            <tr className="bg-grey-lighter border-b">
                                                <th className="font-medium whitespace-no-wrap text-xs p-2 text-left">
                                                    <Trans>Description</Trans>
                                                </th>
                                                <th className="font-medium whitespace-no-wrap text-xs p-2 text-right">
                                                    <Trans>Qty/Hrs</Trans>
                                                </th>
                                                <th className="font-medium whitespace-no-wrap text-xs p-2 text-right">
                                                    <Trans>Unit Price</Trans>
                                                </th>
                                                <th className="font-medium whitespace-no-wrap text-xs p-2 text-right">
                                                    <Trans>Amount</Trans>
                                                </th>
                                                {invoice.currency_code !==
                                                invoice.preferred_currency_code ? (
                                                    <th className="font-medium whitespace-no-wrap text-xs p-2 text-right">
                                                        &nbsp;
                                                    </th>
                                                ) : null}
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr className="bg-white">
                                                <td colSpan={3} className="p-2 text-sm text-right">
                                                    <Trans>Subtotal</Trans>
                                                </td>
                                                <td className="p-2 whitespace-no-wrap text-sm font-medium text-right">
                                                    {invoice.sub_total.toFixed(
                                                        invoice.currency_precision
                                                    )}{" "}
                                                    {invoice.currency_code}
                                                </td>
                                                {invoice.currency_code !==
                                                invoice.preferred_currency_code ? (
                                                    <td className="p-2 whitespace-no-wrap text-sm font-medium text-right">
                                                        <span className="text-grey-dark text-xs inline-block mr-2">
                                                            {invoice.preferred_currency_sub_total.toFixed(
                                                                invoice.preferred_currency_precision
                                                            )}{" "}
                                                            {invoice.preferred_currency_code}
                                                        </span>
                                                    </td>
                                                ) : null}
                                            </tr>
                                            {invoice.payments > 0 ? (
                                                <tr>
                                                    <td
                                                        colSpan={3}
                                                        className="p-2 text-sm text-right">
                                                        <Trans>Payments</Trans>
                                                    </td>
                                                    <td className="p-2 whitespace-no-wrap text-sm font-medium text-right">
                                                        {invoice.payments.toFixed(
                                                            invoice.currency_precision
                                                        )}{" "}
                                                        {invoice.currency_code}
                                                    </td>
                                                    {invoice.currency_code !==
                                                    invoice.preferred_currency_code ? (
                                                        <td className="p-2 whitespace-no-wrap text-sm font-medium text-right">
                                                            <span className="text-grey-dark text-xs inline-block mr-2">
                                                                {invoice.preferred_currency_payments.toFixed(
                                                                    invoice.preferred_currency_precision
                                                                )}{" "}
                                                                {invoice.preferred_currency_code}
                                                            </span>
                                                        </td>
                                                    ) : null}
                                                </tr>
                                            ) : null}
                                            <tr className="bg-white border-t">
                                                <td
                                                    colSpan={3}
                                                    className="p-2 text-base font-medium text-right">
                                                    <Trans>Amount Due</Trans>
                                                </td>
                                                <td className="p-2 whitespace-no-wrap text-sm font-medium text-right">
                                                    {invoice.amount_due.toFixed(
                                                        invoice.currency_precision
                                                    )}{" "}
                                                    {invoice.currency_code}
                                                </td>
                                                {invoice.currency_code !==
                                                invoice.preferred_currency_code ? (
                                                    <td className="p-2 whitespace-no-wrap text-sm font-medium text-right">
                                                        <span className="text-grey-dark text-xs inline-block mr-2">
                                                            {invoice.preferred_currency_amount_due.toFixed(
                                                                invoice.preferred_currency_precision
                                                            )}{" "}
                                                            {invoice.preferred_currency_code}
                                                        </span>
                                                    </td>
                                                ) : null}
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            {invoice.items.map((item, index) => (
                                                <LineItem
                                                    key={index}
                                                    currency_code={invoice.currency_code}
                                                    currency_precision={invoice.currency_precision}
                                                    item={item}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : null}
                    </LoadingWrapper>
                    <Modal
                        isOpen={showSendInvoiceForm}
                        ariaHideApp={false}
                        style={customModalStyles}>
                        {invoice ? (
                            <div className="form-container w-full">
                                <div className="form-section-header bg-grey-lighter">
                                    <Trans>Recipients</Trans>
                                </div>
                                <div className="form-input-group">
                                    <label className="form-label">
                                        <Trans>To</Trans>
                                    </label>
                                    <div className="w-full">
                                        {invoice.contacts.map((contact, index) => (
                                            <label
                                                key={index}
                                                className="text-sm mr-4 cursor-pointer">
                                                {contact.name}
                                                <input
                                                    className="ml-1"
                                                    type="checkbox"
                                                    onChange={e => {
                                                        e.persist();
                                                        this.setState(prevState => ({
                                                            email_to: e.target.checked
                                                                ? prevState.email_to.concat(
                                                                      contact.email
                                                                  )
                                                                : prevState.email_to.filter(
                                                                      email =>
                                                                          email !== contact.email
                                                                  )
                                                        }));
                                                    }}
                                                    checked={email_to.includes(contact.email)}
                                                />
                                            </label>
                                        ))}
                                    </div>
                                </div>
                                <div className="form-input-group">
                                    <label className="form-label">
                                        <Trans>Cc</Trans>
                                    </label>
                                    <div className="w-full">
                                        {invoice.contacts.map((contact, index) => (
                                            <label
                                                key={index}
                                                className="text-sm mr-4 cursor-pointer">
                                                {contact.name}
                                                <input
                                                    className="ml-1"
                                                    type="checkbox"
                                                    onChange={e => {
                                                        e.persist();
                                                        this.setState(prevState => ({
                                                            email_cc: e.target.checked
                                                                ? prevState.email_cc.concat(
                                                                      contact.email
                                                                  )
                                                                : prevState.email_cc.filter(
                                                                      email =>
                                                                          email !== contact.email
                                                                  )
                                                        }));
                                                    }}
                                                    checked={email_cc.includes(contact.email)}
                                                />
                                            </label>
                                        ))}
                                    </div>
                                </div>

                                <div className="form-input-group">
                                    <label className="form-label">
                                        <Trans>Subject</Trans>
                                    </label>
                                    <div className="w-full">
                                        <input
                                            type="text"
                                            onChange={e =>
                                                this.setState({ email_subject: e.target.value })
                                            }
                                            className="form-input w-full"
                                            value={email_subject}
                                        />
                                    </div>
                                </div>

                                <div className="form-input-group">
                                    <label className="form-label">
                                        <Trans>Message</Trans>
                                    </label>
                                    <div className="w-full">
                                        <textarea
                                            rows={5}
                                            onChange={e =>
                                                this.setState({ email_message: e.target.value })
                                            }
                                            className="form-input w-full"
                                            value={email_message}
                                        />
                                    </div>
                                </div>

                                <div className="flex justify-between bg-grey-lightest items-center p-4">
                                    <button
                                        type="submit"
                                        onClick={this.sendInvoice}
                                        disabled={isSendingInvoice}
                                        className="btn btn-primary">
                                        {isSendingInvoice ? (
                                            <Trans>Sending...</Trans>
                                        ) : (
                                            <Trans>Send</Trans>
                                        )}
                                    </button>

                                    <button
                                        className="btn btn-red"
                                        onClick={() =>
                                            this.setState({ showSendInvoiceForm: false })
                                        }>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        ) : null}
                    </Modal>
                </div>
            </div>
        );
    }
}

class LineItem extends Component {
    render() {
        const {
            currency_code,
            currency_precision,
            item: {
                description,
                qty,
                unit_price,
                total,
                preferred_currency_code,
                preferred_currency_precision,
                preferred_currency_total
            }
        } = this.props;

        return (
            <tr className="bg-white border-b">
                <td className="p-2 text-sm text-left">{description}</td>
                <td className="p-2 text-sm text-right" style={{ maxWidth: 75 }}>
                    {qty}
                </td>
                <td className="p-2 text-sm text-right" style={{ maxWidth: 75 }}>
                    {unit_price}
                </td>
                <td className="p-2 text-sm text-right">
                    {total.toFixed(currency_precision)} {currency_code}
                </td>
                {currency_code !== preferred_currency_code ? (
                    <td className="p-2 text-sm text-right">
                        <span className="text-grey-dark text-xs inline-block mr-2">
                            {preferred_currency_total.toFixed(preferred_currency_precision)}{" "}
                            {preferred_currency_code}
                        </span>
                    </td>
                ) : null}
            </tr>
        );
    }
}

export default InvoiceShow;
