import React, { Component } from "react";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
import { sendPasswordResetEmail as attemptSendPasswordResetEmail } from "../../api";
import { ApiContext } from "../../contexts/ApiContext";
import logo from "../../assets/logo-sm.png";

class RequestPasswordReset extends Component {
    static contextType = ApiContext;

    state =
        process.env.NODE_ENV === "development"
            ? { email: "ifor@designedbywaldo.com", isLoading: false }
            : { email: "", isLoading: false };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    sendPasswordResetEmail = e => {
        e.preventDefault();
        const { callApi } = this.context;
        this.safeSetState({ isLoading: true });
        callApi(() => attemptSendPasswordResetEmail(this.state.email))
            .then(res => {
                toast.success(
                    <Trans>
                        Email sent! Please follow the link in the password reset email to change
                        your password.
                    </Trans>,
                    { autoClose: false }
                );

                this.safeSetState({ email: "", isLoading: false });
            })
            .catch(e => {
                let msg = e;
                if (typeof e === "object") {
                    msg = e[Object.keys(e)[0]];
                }
                toast.error(msg);
                this.safeSetState({ isLoading: false });
            });
    };

    render() {
        const { email, isLoading } = this.state;

        return (
            <div className="full-screen-form-container">
                <div className="full-screen-form">
                    <div className="text-center w-full">
                        <img className="mb-8" src={logo} alt="" style={{ maxWidth: 200 }} />
                    </div>
                    <div className="form-no-flex">
                        <form
                            method="POST"
                            onSubmit={this.sendPasswordResetEmail}
                            className="container-narrow">
                            <div className="form-input-group">
                                <label className="form-label">
                                    <Trans>Email</Trans>
                                </label>
                                <input
                                    value={email}
                                    onChange={e => this.setState({ email: e.target.value })}
                                    type="email"
                                    required
                                    disabled={isLoading}
                                    className="form-input w-full"
                                />
                            </div>
                            <button
                                type="submit"
                                disabled={isLoading}
                                className="btn btn-orange w-full mb-2">
                                {isLoading ? (
                                    <Trans>Sending Password Reset Email...</Trans>
                                ) : (
                                    <Trans>Send Password Reset Email</Trans>
                                )}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default RequestPasswordReset;
