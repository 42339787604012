import React from "react";
// import { NavLink } from "react-router-dom";
import { t } from "@lingui/macro";
import { i18n } from "../App";
// import Icon from "./Icon";
import GlobalSearch from "./GlobalSearch";
import MiniTimer from "./MiniTimer";
// import { ApiContext } from "../contexts/ApiContext";

const TopNav = () => (
    <div
        className="top-nav main-container fixed z-5 pin-x p-2 flex shadow justify-between items-center"
        style={
            process.env.NODE_ENV === "development"
                ? {
                      backgroundColor: "rgba(255,0,0,.8)",
                      color: "white"
                  }
                : { backgroundColor: "rgba(255,255,255,.8)" }
        }>
        <div className="hidden md:block mr-2 rounded border">
            <GlobalSearch
                goToEdit={false}
                url="search"
                placeholder={i18n._(t`Search everything`)}
            />
        </div>
        <MiniTimer />
    </div>
);

export default TopNav;
